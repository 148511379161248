import React, { useCallback, useEffect, useState } from "react";

import clsx from "clsx";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import { paths } from "@navigation/routes";

import { isAuthSelector } from "@slices/AppSlice/selectors";

import Input from "@components/Input";
import LinkComponent from "@components/LinkComponent";
import Modal from "@components/Modal";

import Button from "@ui/Button";
import Typography from "@ui/Typography";

import { yupResolver } from "@hookform/resolvers/yup";
import { getInitData } from "@saga/accountSaga/actions";
import { checkCodeAC, loginUserAC } from "@saga/authSaga/actions";
import InputEyeSVG from "@svg/input/eye";
import InputPasswordSVG from "@svg/input/password";
import InputPersonSVG from "@svg/input/person";
import { createArrayMask } from "@utils/inputMasks";
import { inputMaxLength } from "@utils/inputMasks/constants";
import { schemaLogin } from "@validation";

import style from "./Login.module.scss";

const sectionLogin = "login";

interface IFormValues {
  login: string;
  password: string;
  code: string;
}

const LoginPage = () => {
  const { t } = useTranslation();

  const dispatch = useDispatch();

  const navigate = useNavigate();

  const { control, handleSubmit, setError, getValues } = useForm<IFormValues>({
    resolver: yupResolver(schemaLogin),
    shouldFocusError: false,
  });

  const isAuth = useSelector(isAuthSelector);

  const [isModalLoginActive, setIsModalLoginActive] = useState<boolean>(false);
  const [isModalVerifyActive, setIsModalVerifyActive] =
    useState<boolean>(false);

  const [isSecretPassword, setIsSecretPassword] = useState<boolean>(true);

  const [_phone, setPhone] = useState("");

  const onClickHandler = useCallback(
    () => setIsSecretPassword(!isSecretPassword),
    [isSecretPassword]
  );

  const sagaLoginError = () => {
    setError("password", {
      type: "manual",
      message: "Логин или пароль неверные",
    });
  };

  const sagaLoginSuccess = (withToken: boolean) => {
    if (withToken) {
      dispatch(getInitData({}));
      navigate(paths.lk);
      return;
    }

    setIsModalLoginActive(false);
    setIsModalVerifyActive(true);
  };

  const onSubmitHandler = (data: IFormValues) => {
    const { login, password } = data;

    loginUserHandler(login, password);
  };

  const loginUserHandler = (login: string, password: string, code?: string) => {
    dispatch(
      loginUserAC({
        login,
        password,
        setLoginError: sagaLoginError,
        setLoginSuccess: sagaLoginSuccess,
        setInvalidCodeError: sagaLoginCheckCodeError,
        setPhone,
        ...(code && { code }),
      })
    );
  };

  const sagaLoginCheckCodeError = () => {
    setError("code", {
      type: "manual",
      message: "Неверный код",
    });
  };

  const sagaCheckCodeSuccess = () => navigate(paths.lk);

  const sendCodeHandler = useCallback(() => {
    const { code, login, password } = getValues();
    const rightCode = code.replace(/_/g, "").trim();
    if (rightCode.length === 6) {
      loginUserHandler(login, password, code);
    }
  }, [
    getValues,
    dispatch,
    checkCodeAC,
    sagaCheckCodeSuccess,
    loginUserHandler,
  ]);

  useEffect(() => {
    isAuth && navigate(paths.profile);
    setIsModalLoginActive(true);
  }, []);

  return (
    <>
      <Modal
        className={style.modal}
        isActive={isModalLoginActive}
        setIsActive={setIsModalLoginActive}
        withLogo
        title={t(`${sectionLogin}.title`)}
        type="medium"
      >
        <form onSubmit={handleSubmit(onSubmitHandler)}>
          <Input
            className={clsx(style.modal__input, style.modal__login)}
            icon={<InputPersonSVG />}
            name="login"
            control={control}
            mask={createArrayMask("login", inputMaxLength.login)}
            placeholder={t(`${sectionLogin}.loginPlaceholder`)}
          />
          <Input
            className={clsx(style.modal__input, style.modal__login)}
            icon={<InputPersonSVG />}
            name="password"
            control={control}
            mask={createArrayMask("password", inputMaxLength.password)}
            placeholder={t(`${sectionLogin}.passwordPlaceholder`)}
            type={isSecretPassword ? "password" : "text"}
            rightElem={
              <Button
                className={style.modal__eye}
                onClick={onClickHandler}
                type="button"
              >
                <InputEyeSVG />
              </Button>
            }
          />
          <Button className={style.modal__btn}>
            <Typography type="sf-text-17">
              {t(`${sectionLogin}.btn`)}
            </Typography>
          </Button>
        </form>
        <Typography className={style.modal__remember} type="sf-text-15">
          <LinkComponent
            to={paths.forgot}
            className={style.modal__remember}
            type="sf-text-15"
          >
            {t(`${sectionLogin}.remember`)}
          </LinkComponent>
        </Typography>
        <div className={style.modal__bottom}>
          <Typography component="span" type="sf-text-15">
            {t(`${sectionLogin}.quest`)}
          </Typography>
          <LinkComponent to={paths.verify} className={style.modal__link}>
            {t(`${sectionLogin}.link`)}
          </LinkComponent>
        </div>
      </Modal>
      <Modal
        isActive={isModalVerifyActive}
        setIsActive={setIsModalVerifyActive}
        title={t(`${sectionLogin}.verifyTitle`)}
        className={style.verify}
        type="medium"
      >
        <Typography type="sf-text-15" className={style.verify__subtitle}>
          {t(`${sectionLogin}.verifySubtitle`)}
        </Typography>
        <Input
          placeholder={t(`${sectionLogin}.verifyPlaceholder`)}
          className={style.verify__input}
          control={control}
          name="code"
          mask="999999"
          icon={<InputPasswordSVG />}
        />
        <Button className={style.verify__btn} onClick={sendCodeHandler}>
          <Typography type="sf-text-17">
            {t(`${sectionLogin}.verifyBtn`)}
          </Typography>
        </Button>
      </Modal>
    </>
  );
};

export default LoginPage;
