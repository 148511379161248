import React, { FC } from "react";
import { ISvgProps } from "./types";

const GtechLogoSVG: FC<ISvgProps> = ({ className }) => {
  return (
    <svg
      width="60"
      height="60"
      viewBox="0 0 60 60"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <circle
        opacity="0.3"
        cx="30"
        cy="30"
        r="29"
        stroke="white"
        strokeWidth="2"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M40.3511 22.2285H48.5382V28.1211H40.3511C40.5337 27.1564 40.638 26.1917 40.638 25.1748C40.638 24.184 40.5337 23.1932 40.3511 22.2285ZM38.6825 32.6839V48.4844H32.3206V38.9155C34.98 37.5075 37.2223 35.3434 38.6825 32.6839Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M25.1241 11.5117C30.1823 11.5117 34.5887 14.2494 36.9614 18.3429L31.7728 21.3414C30.443 19.0469 27.9661 17.5086 25.1501 17.5086C20.9262 17.5086 17.4846 20.9503 17.4846 25.1742C17.4846 29.398 20.9262 32.8397 25.1501 32.8397C28.3311 32.8397 31.0688 30.8842 32.216 28.1204H22.4646V22.2279H32.1899H36.9353H38.4475C38.6561 23.1665 38.7604 24.1573 38.7604 25.1742C38.7604 32.7094 32.6332 38.8366 25.098 38.8366C17.5628 38.8366 11.4355 32.7094 11.4355 25.1742C11.4616 17.6129 17.5628 11.5117 25.1241 11.5117Z"
        fill="white"
      />
    </svg>
  );
};

export default GtechLogoSVG;
