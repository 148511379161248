import { AxiosResponse } from "axios";
import { t } from "i18next";
import { call, put } from "redux-saga/effects";

import authAPI from "@api/AuthAPI";
import AuthAPI from "@api/AuthAPI";
import {
  IChange2FAResponse,
  IChangePasswordResponse,
  ICheckCodeResponse,
  ICreateAccountResponse,
  ICreateUserResponse,
  IGetCodeResponse,
} from "@api/AuthAPI/types";
import { instance } from "@api/settings";

import {
  clearIsLoading,
  logout,
  setAccessToken,
  setAppMessage,
  setError,
  setIsAuth,
  setIsLoading,
} from "@slices/AppSlice";
import { clearCoinInfo } from "@slices/CoinSlice";
import { clearOtherInfo } from "@slices/OtherSlice";
import { clearUserInfo } from "@slices/UserSlice";

import {
  ISagaChange2FAAction,
  ISagaChangePasswordAction,
  ISagaCheckCodeAction,
  ISagaCreateAccountAction,
  ISagaCreateUserAction,
  ISagaForgotPasswordAction,
  ISagaLoginUserAction,
  ISagaLogoutAction,
  ISagaSendCodeAction,
} from "./types";

function* sagaGetCode({
  payload,
}: {
  payload: ISagaSendCodeAction;
  type: string;
}) {
  const { setSendCodeError, setSendCodeSuccess, setPhoneError, ...data } =
    payload;
  try {
    yield put(setIsLoading());
    const response: AxiosResponse<IGetCodeResponse> = yield call(
      authAPI.getCode,
      data
    );
    const { is_send, register_phone } = response.data;
    yield is_send === true
      ? setSendCodeSuccess?.()
      : is_send === false
      ? setSendCodeError?.()
      : null;
    yield register_phone ? setPhoneError?.() : null;
  } catch (e: any) {
    yield put(setError("Error"));
    const response = JSON.parse(e.request.response);
    yield response.register_phone ? setPhoneError?.() : null;
  } finally {
    yield put(clearIsLoading());
  }
}

function* sagaCreateUser({
  payload,
}: {
  payload: ISagaCreateUserAction;
  type: string;
}) {
  const { setCreateUserSuccess, ...data } = payload;
  try {
    yield put(setIsLoading());
    const response: AxiosResponse<ICreateUserResponse> = yield call(
      authAPI.createUser,
      data
    );

    const { access_token } = response.data;

    if (access_token) {
      yield put(setAccessToken(access_token));
      yield setCreateUserSuccess?.();
    }
  } catch (e: any) {
    e?.response?.data?.login &&
      setAppMessage({ message: e?.response?.data?.login, type: "error" });

    yield put(setError("Error"));
  } finally {
    yield put(clearIsLoading());
  }
}

function* sagaLoginUser({
  payload,
}: {
  payload: ISagaLoginUserAction;
  type: string;
}) {
  const {
    setLoginSuccess,
    setLoginError,
    setInvalidCodeError,
    setPhone,
    ...data
  } = payload;
  try {
    yield put(setIsLoading());
    const response: AxiosResponse<ICreateUserResponse> = yield call(
      authAPI.loginUser,
      data
    );
    const { access_token, phone, error } = response.data;

    if (access_token) {
      yield put(setAccessToken(access_token));
      yield put(setIsAuth(true));
    }

    yield phone && setPhone(phone);
    yield error && error === "invalid_code" && setInvalidCodeError?.();
    yield setLoginSuccess(!!access_token);
  } catch (e) {
    yield setLoginError();
    yield put(setError("Error"));
  } finally {
    yield put(clearIsLoading());
  }
}

function* sagaCreateAccount({
  payload,
}: {
  payload: ISagaCreateAccountAction;
  type: string;
}) {
  const { setCreateAccountSuccess, setCreateAccountError, ...data } = payload;
  try {
    yield put(setIsLoading());
    const response: AxiosResponse<ICreateAccountResponse> = yield call(
      authAPI.createAccount,
      { ...data, index: "1", city: "example" } //todo remove index and city
    );
    const resTest: AxiosResponse<any> = yield call(AuthAPI.confirm); //todo remove this method
    yield resTest.data.is_kyc_confirmed && setCreateAccountSuccess();
  } catch (e: any) {
    e?.response?.status === 400 && setCreateAccountError?.(e.response.data);
    yield put(setError("Error"));
  } finally {
    yield put(clearIsLoading());
  }
}

function* sagaCheckCode({
  payload,
}: {
  payload: ISagaCheckCodeAction;
  type: string;
}) {
  const { setCheckError, setCheckSuccess, ...data } = payload;
  try {
    yield put(setIsLoading());
    const response: AxiosResponse<ICheckCodeResponse> = yield call(
      authAPI.checkCode,
      data
    );
    const { is_valid } = response.data;
    yield is_valid ? setCheckSuccess?.(data.code) : setCheckError?.();
  } catch (e) {
    yield put(setError("Error"));
  } finally {
    yield put(clearIsLoading());
  }
}

function* sagaChange2FA({
  payload,
}: {
  payload: ISagaChange2FAAction;
  type: string;
}) {
  const { setChange2FASuccess, setChange2FAError, ...data } = payload;
  try {
    yield put(setIsLoading());
    const response: AxiosResponse<IChange2FAResponse> = yield call(
      authAPI.change2FA,
      data
    );
    yield data.is_2fa_activate === response.data.is_2fa_activate
      ? setChange2FASuccess?.()
      : setChange2FAError?.();
  } catch (e: any) {
    e?.response?.status === 400 && setChange2FAError?.();
    yield put(setError("Error"));
  } finally {
    yield put(clearIsLoading());
  }
}

function* sagaChangePassword({
  payload,
}: {
  payload: ISagaChangePasswordAction;
  type: string;
}) {
  try {
    yield put(setIsLoading());
    const { setChangePasswordSuccess, ...data } = payload;
    const response: AxiosResponse<IChangePasswordResponse> = yield call(
      authAPI.changePassword,
      data
    );
    yield setChangePasswordSuccess?.();
  } catch (e) {
    yield put(setError("Error"));
  } finally {
    yield put(clearIsLoading());
  }
}

function* sagaLogout({
  payload,
}: {
  payload: ISagaLogoutAction;
  type: string;
}) {
  const { setLogoutSuccess } = payload;
  try {
    yield put(setIsLoading());
    yield put(logout());
    yield put(clearCoinInfo());
    yield put(clearOtherInfo());
    yield put(clearUserInfo());
    yield setLogoutSuccess?.();
    yield (instance.defaults.headers.common["Authorization"] = "");
  } catch (e) {
    yield put(setError("Error"));
  } finally {
    yield put(clearIsLoading());
  }
}

function* sagaForgotPassword({
  payload,
}: {
  payload: ISagaForgotPasswordAction;
  type: string;
}) {
  try {
    const { showSuccessModal, ...data } = payload;
    const response: AxiosResponse<IChangePasswordResponse> = yield call(
      authAPI.forgotPassword,
      data
    );
    yield showSuccessModal?.();
  } catch (e) {
    yield put(setError("Error"));
  } finally {
    yield put(clearIsLoading());
  }
}

export {
  sagaGetCode,
  sagaCreateUser,
  sagaLoginUser,
  sagaCreateAccount,
  sagaCheckCode,
  sagaChange2FA,
  sagaChangePassword,
  sagaLogout,
  sagaForgotPassword,
};
