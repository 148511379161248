import React, { FC, memo } from "react";

import {
  Area,
  CartesianGrid,
  ComposedChart,
  Line,
  Scatter,
  ScatterChart,
  YAxis,
} from "recharts";

import { IGTECHPerSecond } from "@slices/CoinSlice/types";

import style from "./ChartDot.module.scss";

interface IProps {
  data: IGTECHPerSecond;
}

const ChartDot: FC<IProps> = ({ data }) => {
  return (
    <ComposedChart
      width={320}
      height={150}
      data={data.results}
      margin={{ top: 10, right: 50, bottom: 20, left: 0 }}
    >
      <CartesianGrid strokeDasharray="5 5" vertical={false} />
      <YAxis
        width={100}
        dataKey="value"
        name="weight"
        unit=" GH/s"
        tick={{
          fontSize: "12px",
          fill: "rgba(235, 235, 245, 0.6)",
          fontFamily: "SFText, sans-serif",
        }}
        allowDataOverflow
        axisLine={false}
      />
      <Area
        type="linear"
        dataKey={"value"}
        stroke="#82ca9d"
        fillOpacity={1}
        fill="url(#colorPv)"
        baseLine={8}
        dot={false}
        activeDot={false}
      />
      <Line
        type="linear"
        dataKey={"value"}
        stroke="#33cc61"
        strokeWidth={2}
        width={500}
        height={3000}
        style={{ margin: "30px" }}
        dot={false}
      />
      {/* <Scatter data={data.results} fill="#33CC66" shape={"circle"} line /> */}
    </ComposedChart>
  );
};

export default memo(ChartDot);
