import React, { FC } from "react";

import clsx from "clsx";

import Typography from "@ui/Typography";

import style from "./Value.module.scss";

interface IProps {
  className?: string;
  classNameNumb?: string;
  classNameItem?: string;
  numb?: string | number | null;
  item?: string | null;
}

const Value: FC<IProps> = ({
  className,
  classNameItem,
  classNameNumb,
  item,
  numb,
}) => {
  return (
    <div className={clsx(style.account, className)}>
      <Typography
        type="montserrat-36"
        className={clsx(style.balance, classNameNumb)}
      >
        {numb}
      </Typography>
      <Typography type="roboto-24" className={clsx(style.coin, classNameItem)}>
        {item}
      </Typography>
    </div>
  );
};

export default Value;
