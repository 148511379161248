import React, { FC } from "react";
import { ISvgProps } from "./types";

const PredictionSVG: FC<ISvgProps> = ({ className }) => {
  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        opacity="0.4"
        d="M11.5146 16.7031V29.28"
        stroke="#33CC66"
        strokeWidth="2.75"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        opacity="0.4"
        d="M20.0703 10.6836V29.2786"
        stroke="#33CC66"
        strokeWidth="2.75"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        opacity="0.4"
        d="M28.4863 23.3477V29.2785"
        stroke="#33CC66"
        strokeWidth="2.75"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M28.5908 1.66602H11.4098C5.42096 1.66602 1.66699 5.90484 1.66699 11.9055V28.0932C1.66699 34.0939 5.4035 38.3327 11.4098 38.3327H28.5908C34.5972 38.3327 38.3337 34.0939 38.3337 28.0932V11.9055C38.3337 5.90484 34.5972 1.66602 28.5908 1.66602Z"
        stroke="#33CC66"
        strokeWidth="2.75"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default PredictionSVG;
