export const getNormalPhone = (phone: string): string =>
  phone.replace(/\s|-/g, "");

export const getBirthdayNeed = (date: string): string =>
  date.split(".").reverse().join("-");

export const getQueryParamsString = (
  params: {
    key: string;
    value?: string | number;
  }[]
) => {
  let queryParams = "";
  params.forEach(
    ({ key, value }) =>
      (queryParams += value
        ? `${queryParams.length ? "&" : ""}${key}=${value}`
        : "")
  );
  return queryParams;
};

export const getOnlyNumbersAndDots = (value: string) =>
  value.replace(/[^0-9\.]|^\./g, "");
