import React, { FC } from "react";
import { ISvgProps } from "./types";

const LangApexSVG: FC<ISvgProps> = ({ className }) => {
  return (
    <svg
      width="20"
      height="9"
      viewBox="0 0 20 9"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M0 9L7.17158 1.82842C8.73367 0.266327 11.2663 0.26633 12.8284 1.82843L20 9L0 9Z"
        fill="#404159"
      />
    </svg>
  );
};

export default LangApexSVG;
