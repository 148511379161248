import React, { FC } from "react";
import { ISvgProps } from "../types";

const TitleProfitSVG: FC<ISvgProps> = ({ className }) => {
  return (
    <svg
      width="36"
      height="36"
      viewBox="0 0 36 36"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        opacity="0.4"
        d="M10.8672 22.1723L15.3569 16.3371L20.4782 20.3599L24.8718 14.6895"
        stroke="#33CC66"
        strokeWidth="2.25"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M29.9927 9.18456C31.5851 9.18456 32.876 7.89366 32.876 6.30126C32.876 4.70886 31.5851 3.41797 29.9927 3.41797C28.4003 3.41797 27.1094 4.70886 27.1094 6.30126C27.1094 7.89366 28.4003 9.18456 29.9927 9.18456Z"
        stroke="#33CC66"
        strokeWidth="2.25"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M22.3867 4.68164H11.4851C6.96791 4.68164 4.16699 7.88072 4.16699 12.3979V24.5215C4.16699 29.0386 6.91299 32.224 11.4851 32.224H24.3912C28.9084 32.224 31.7093 29.0386 31.7093 24.5215V13.9631"
        stroke="#33CC66"
        strokeWidth="2.25"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default TitleProfitSVG;
