import { PayloadAction, createSlice } from "@reduxjs/toolkit";

import { IConstant, INews } from "./types";

const initialState = {
  news: null as INews[] | null,
  constants: null as IConstant | null,
};

const otherSlice = createSlice({
  name: "other",
  initialState,
  reducers: {
    setNews: (state, action: PayloadAction<INews[]>) => {
      state.news = action.payload;
    },
    setConstants: (state, action: PayloadAction<IConstant>) => {
      state.constants = action.payload;
    },
    clearOtherInfo: (state) => {
      state.constants = null;
      state.news = null;
    },
  },
});

export const { clearOtherInfo, setConstants, setNews } = otherSlice.actions;

export default otherSlice.reducer;
