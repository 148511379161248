import React, { FC, ReactElement } from "react";

import clsx from "clsx";

import Typography from "@ui/Typography";

import CloseSVG from "@svg/close";
import LogoGraySVG from "@svg/logoGray";

import style from "./Modal.module.scss";

interface IProps {
  isActive: boolean;
  setIsActive: (status: boolean) => void;
  className?: string;
  classNameTitle?: string;
  withClose?: boolean;
  withLogo?: boolean;
  withDarkBg?: boolean;
  title?: string;
  type?: "big" | "medium";
  isCenterByVertical?: boolean;
  beforeTitle?: ReactElement;
  relativeMode?: boolean;

}

const Modal: FC<IProps> = ({
  withClose,
  title,
  children,
  withLogo,
  isActive,
  isCenterByVertical,
  withDarkBg,
  setIsActive,
  className,
  classNameTitle,
  type,
  beforeTitle,
  relativeMode,
}) => {
  const onCloseHandler = () => {
    setIsActive?.(false);
  };

  return (
    <div
      className={clsx(style.modal, {
        [style.modal_active]: isActive,
        [style.modal_dark]: withDarkBg,
        [style.modal_center]: isCenterByVertical,
        [style.modal_relative]: relativeMode,
      })}
    >
      <div
        className={clsx(style.modal__window, {
          [className || ""]: className,
          [style.modal__window_medium]: type === "medium",
          [style.modal__window_big]: type === "big",
          [style.modal__window_active]: isActive,
        })}
      >
        {withClose && (
          <button className={style.modal__close} onClick={onCloseHandler}>
            <CloseSVG />
          </button>
        )}
        {withLogo && <LogoGraySVG className={style.modal__logo} />}
        {beforeTitle}
        {title && (
          <Typography
            type="montserrat-28"
            className={clsx(style.modal__title, classNameTitle || "")}
          >
            {title}
          </Typography>
        )}
        {children}
      </div>
    </div>
  );
};

export default Modal;
