import React, { FC } from "react";

import clsx from "clsx";

import style from "./BlockWithIcon.module.scss";

interface IProps {
  className?: string;
}

const BlockWithIcon: FC<IProps> = ({ className, children }) => {
  return (
    <div
      className={clsx(style.block, {
        [className || ""]: className,
      })}
    >
      {children}
    </div>
  );
};

export default BlockWithIcon;
