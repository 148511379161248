import React, { FC } from "react";
import { ISvgProps } from "./types";

const CloseSVG: FC<ISvgProps> = ({ className }) => {
  return (
    <svg
      width="22"
      height="22"
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M11 22C12.5079 22 13.9238 21.7131 15.2477 21.1394C16.5791 20.5657 17.7486 19.775 18.7563 18.7673C19.7713 17.7596 20.5657 16.5938 21.1394 15.2698C21.7131 13.9385 22 12.5152 22 11C22 9.49214 21.7131 8.07623 21.1394 6.75226C20.5657 5.42093 19.7713 4.25142 18.7563 3.24373C17.7486 2.22869 16.5791 1.4343 15.2477 0.860582C13.9238 0.286861 12.5042 0 10.989 0C9.48847 0 8.07255 0.286861 6.74122 0.860582C5.41725 1.4343 4.24774 2.22869 3.2327 3.24373C2.22501 4.25142 1.4343 5.42093 0.860582 6.75226C0.286861 8.07623 0 9.49214 0 11C0 12.5152 0.286861 13.9385 0.860582 15.2698C1.4343 16.5938 2.22501 17.7596 3.2327 18.7673C4.24774 19.775 5.41725 20.5657 6.74122 21.1394C8.07255 21.7131 9.49214 22 11 22ZM7.61284 15.5567C7.2892 15.5567 7.01337 15.4427 6.78536 15.2146C6.55734 14.9866 6.44333 14.7071 6.44333 14.3761C6.44333 14.0598 6.56102 13.7914 6.79639 13.5707L9.334 11.011L6.79639 8.47342C6.56102 8.23805 6.44333 7.9659 6.44333 7.65697C6.44333 7.31862 6.55734 7.03912 6.78536 6.81846C7.01337 6.59779 7.2892 6.48746 7.61284 6.48746C7.95854 6.48746 8.24172 6.60147 8.46239 6.82949L11.011 9.35607L13.5486 6.81846C13.784 6.59044 14.0709 6.47643 14.4092 6.47643C14.7402 6.47643 15.016 6.59044 15.2367 6.81846C15.4647 7.03912 15.5787 7.31494 15.5787 7.64594C15.5787 7.95486 15.4611 8.22701 15.2257 8.46239L12.6881 11.011L15.2146 13.5597C15.45 13.7951 15.5677 14.0672 15.5677 14.3761C15.5677 14.7071 15.4537 14.9866 15.2257 15.2146C15.005 15.4427 14.7255 15.5567 14.3872 15.5567C14.0562 15.5567 13.7693 15.4427 13.5266 15.2146L11.011 12.6881L8.48445 15.2146C8.25644 15.4427 7.9659 15.5567 7.61284 15.5567Z"
        fill="#EBEBF5"
        fillOpacity="0.18"
      />
    </svg>
  );
};

export default CloseSVG;
