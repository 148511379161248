import React, { FC } from "react";
import { ISvgProps } from "../types";

const TitleWalletSVG: FC<ISvgProps> = ({ className }) => {
  return (
    <svg
      width="36"
      height="36"
      viewBox="0 0 36 36"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M32.4584 21.5934H26.3859C24.1563 21.592 22.349 19.7861 22.3477 17.5564C22.3477 15.3268 24.1563 13.5209 26.3859 13.5195H32.4584"
        stroke="#33CC66"
        strokeWidth="2.25"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M27.0726 17.4648H26.605"
        stroke="#33CC66"
        strokeWidth="2.25"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.6215 4.5H24.5866C28.9339 4.5 32.4581 8.02426 32.4581 12.3715V23.137C32.4581 27.4843 28.9339 31.0085 24.5866 31.0085H11.6215C7.27426 31.0085 3.75 27.4843 3.75 23.137V12.3715C3.75 8.02426 7.27426 4.5 11.6215 4.5Z"
        stroke="#33CC66"
        strokeWidth="2.25"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        opacity="0.4"
        d="M10.5537 11.3066H18.6521"
        stroke="#33CC66"
        strokeWidth="2.25"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default TitleWalletSVG;
