import React, { ChangeEvent, FC, memo } from "react";

import clsx from "clsx";
import { v4 as uuidv4 } from "uuid";

import CheckboxSVG from "@svg/checkbox";

import style from "./Checkbox.module.scss";

interface IProps {
  className?: string;
  value: boolean;
  setValue: (value: boolean) => void;
}

const Checkbox: FC<IProps> = ({ className, value, setValue }) => {
  const onChangeHandler = (e: ChangeEvent<HTMLInputElement>) => {
    setValue(e.target.checked);
  };

  const id = uuidv4();

  return (
    <label
      className={clsx(style.checkbox, {
        [className || ""]: className,
        [style.checkbox_active]: value,
      })}
      htmlFor={id}
    >
      <input
        type="checkbox"
        id={id}
        checked={value}
        onChange={onChangeHandler}
        className={style.checkbox__input}
      />
      <CheckboxSVG
        className={clsx(style.checkbox__icon, {
          [style.checkbox__icon_show]: value,
        })}
      />
    </label>
  );
};

export default memo(Checkbox);
