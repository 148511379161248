import { createAction } from "@reduxjs/toolkit";

import {
  ISagaChange2FAAction,
  ISagaChangePasswordAction,
  ISagaCheckCodeAction,
  ISagaCreateAccountAction,
  ISagaCreateUserAction,
  ISagaLoginUserAction,
  ISagaLogoutAction,
  ISagaSendCodeAction,
  ISagaForgotPasswordAction,
} from "./types";

export const getCodeAC = createAction<ISagaSendCodeAction>("GET_CODE");
export const createUserAC = createAction<ISagaCreateUserAction>("CREATE_USER");
export const loginUserAC = createAction<ISagaLoginUserAction>("LOGIN_USER");
export const createAccountAC =
  createAction<ISagaCreateAccountAction>("CREATE_ACCOUNT");
export const checkCodeAC = createAction<ISagaCheckCodeAction>("CHECK_CODE");
export const change2FAAC = createAction<ISagaChange2FAAction>("CHANGE_2FA");
export const changePasswordAC =
  createAction<ISagaChangePasswordAction>("CHANGE_PASSWORD");
export const logoutAC = createAction<ISagaLogoutAction>("LOGOUT");
export const forgotPasswordAC = createAction<ISagaForgotPasswordAction>("FORGOT_PASSWORD");