import React, { FC, useMemo } from "react";

import clsx from "clsx";

import Typography from "@ui/Typography";

import ArrowLeftSVG from "@svg/arrowLeft";
import ArrowRightSVG from "@svg/arrowRight";

import style from "./Pagintaion.module.scss";

interface IProps {
  className?: string;
  count: number;
  currentPage: number;
  setCurrentPage(value: number): void;
  countShowingPages?: number;
}

const Pagination: FC<IProps> = ({
  className,
  count,
  countShowingPages = 5,
  currentPage,
  setCurrentPage,
}) => {
  const getNumbers = useMemo(() => {
    const pages = [] as Array<number | "...">;

    const countAdditionalPages = countShowingPages - 1;
    const countFirstAdditionalPages = Math.ceil(countAdditionalPages / 2);
    const countLastAdditionalPages = Math.floor(countAdditionalPages / 2);

    const isFirstPages = currentPage <= countShowingPages;
    const isLastPages = currentPage > count - countShowingPages;

    const firstPage = isFirstPages
      ? 1
      : isLastPages
      ? count - (countShowingPages - 1)
      : currentPage - countFirstAdditionalPages;

    const lastPage = isFirstPages
      ? countShowingPages
      : isLastPages
      ? count
      : currentPage + countLastAdditionalPages;

    !isFirstPages && pages.push(1, "...");

    for (let i = firstPage; i <= lastPage && i <= count; i++) pages.push(i);

    pages[pages.length - 1] !== count && pages.push("...", count);

    return pages;
  }, [currentPage, count]);

  const onClickHandler = (
    page: number | "...",
    index?: number,
    firstPage?: number,
    lastPage?: number
  ) => {
    let seelctedPage = page;

    if (seelctedPage === "...") {
      seelctedPage =
        index === 1 ? (firstPage as number) - 1 : (lastPage as number) + 1;
    }

    if (seelctedPage === 0 || seelctedPage > count) return;

    setCurrentPage(seelctedPage);
  };

  return (
    <div
      className={clsx(style.wrapper, {
        [className || ""]: className,
      })}
    >
      <div
        className={style.left}
        onClick={() => onClickHandler(currentPage - 1)}
      >
        <ArrowLeftSVG />
      </div>
      <div className={style.pages}>
        {getNumbers.map((page, index, arr) => (
          <Typography
            className={clsx(style.page, {
              [style.page_active]: page === currentPage,
            })}
            component="button"
            type="sf-text-16"
            key={index}
            onClick={() =>
              onClickHandler(
                page,
                index,
                arr[2] as number,
                arr[arr.length - 3] as number
              )
            }
          >
            {page}
          </Typography>
        ))}
      </div>
      <div
        className={style.right}
        onClick={() => onClickHandler(currentPage + 1)}
      >
        <ArrowRightSVG />
      </div>
    </div>
  );
};

export default Pagination;
