import React, { FC, ReactElement } from "react";

import clsx from "clsx";

import BlockWithIcon from "@ui/BlockWithIcon";
import Typography from "@ui/Typography";

import style from "./Title.module.scss";

interface IProps {
  className?: string;
  icon: ReactElement;
}

const Title: FC<IProps> = ({ className, icon, children }) => {
  return (
    <div
      className={clsx(style.wrapper, {
        [className || ""]: className,
      })}
    >
      <BlockWithIcon className={style.wrapper__icon}>{icon}</BlockWithIcon>
      <Typography
        className={style.wrapper__text}
        type="montserrat-28"
        component="h2"
      >
        {children}
      </Typography>
    </div>
  );
};

export default Title;
