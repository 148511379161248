import React, { FC } from "react";
import { ISvgProps } from "../types";

const InputGTECHSVG: FC<ISvgProps> = ({ className }) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.6771 13.4603V21.9999H13.2441V16.8162C14.6906 16.0618 15.8864 14.8916 16.6771 13.4603ZM17.5835 7.79297H22.0001V10.9748H17.5835C17.68 10.4622 17.7378 9.93029 17.7378 9.37904C17.7282 8.8568 17.68 8.31521 17.5835 7.79297Z"
        fill="#EBEBF5"
        fillOpacity="0.18"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.36734 2C12.0963 2 14.4782 3.48936 15.7511 5.69438L12.9449 7.31914C12.2313 6.08123 10.891 5.24951 9.36734 5.24951C7.08192 5.24951 5.24009 7.10637 5.24009 9.38876C5.24009 11.6808 7.09156 13.528 9.36734 13.528C11.0838 13.528 12.5592 12.4739 13.186 10.9748H7.93052V7.79302H13.186H15.7511H16.5611C16.6768 8.30559 16.7347 8.83751 16.7347 9.38876C16.7347 13.47 13.4367 16.7775 9.36734 16.7775C5.29795 16.7775 2 13.47 2 9.38876C2 5.30754 5.29795 2 9.36734 2Z"
        fill="#EBEBF5"
        fillOpacity="0.18"
      />
    </svg>
  );
};

export default InputGTECHSVG;
