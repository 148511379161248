import React, { useEffect } from "react";

import clsx from "clsx";
import { TailSpin } from "react-loader-spinner";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import Navigation from "@navigation";
import { paths, routes } from "@navigation/routes";

import { instance } from "@api/settings";

import { clearAppMessage, setIsAuth } from "@slices/AppSlice";
import {
  accessTokenSelector,
  appMessageSelector,
  isAuthSelector,
  isLoadingSelector,
} from "@slices/AppSlice/selectors";
import { profileUserSelector } from "@slices/UserSlice/selectors";

import Footer from "@components/Footer/Footer";
import Header from "@components/Header/Header";

import BgTop from "@images/bg-top.jpg";
import { getInitData } from "@saga/accountSaga/actions";

import style from "./MainLayout.module.scss";

const MainLayout = () => {
  const { pathname } = useLocation();

  const dispatch = useDispatch();

  const navigate = useNavigate();

  const isAuth = useSelector(isAuthSelector);
  const isLoading = useSelector(isLoadingSelector);
  const appMessage = useSelector(appMessageSelector);
  const accessToken = useSelector(accessTokenSelector);
  const { login } = useSelector(profileUserSelector);

  const activeRouteData = routes.find(({ path }) => path === pathname);

  useEffect(() => {
    if (appMessage) {
      toast(appMessage.message, { type: appMessage.type });
      dispatch(clearAppMessage());
    }
  }, [appMessage]);

  useEffect(() => {
    if (accessToken) {
      instance.defaults.headers.common[
        "Authorization"
      ] = `Bearer ${accessToken}`;
      isAuth !== false && dispatch(getInitData({}));
    } else {
      navigate(paths.login);
      dispatch(setIsAuth(false));
    }
  }, [accessToken]);

  return (
    <div className={style.app}>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      {isLoading && (
        <div className={style.app__loader}>
          <TailSpin arialLabel="loading-indicator" width={40} height={40} />
        </div>
      )}
      {activeRouteData?.withBG && (
        <img src={BgTop} className={style.app__bg} alt="" />
      )}
      <div
        className={clsx(style.app__content, {
          [style.app__content_longer]: !activeRouteData?.withHeader,
        })}
      >
        <>
          {activeRouteData?.withHeader && <Header login={login} />}
          <div className={style.app__main}>
            <Navigation />
          </div>
          <Footer isHighContext={!activeRouteData?.withHeader} />
        </>
      </div>
    </div>
  );
};

export default MainLayout;
