import React, { FC } from "react";
import { ISvgProps } from "./types";

const CheckboxSVG: FC<ISvgProps> = ({ className }) => {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <rect width="18" height="18" rx="3" fill="#33CC66" />
      <path
        d="M7.98629 13.7262C7.59602 13.7262 7.2819 13.5549 6.99634 13.2027L4.71183 10.4517C4.51194 10.2042 4.42627 9.9758 4.42627 9.71879C4.42627 9.17622 4.86413 8.74787 5.39718 8.74787C5.72082 8.74787 5.96831 8.87162 6.2158 9.17622L7.95773 11.356L11.7843 5.264C12.0127 4.89277 12.2983 4.71191 12.641 4.71191C13.1645 4.71191 13.6404 5.09267 13.6404 5.63524C13.6404 5.86369 13.5357 6.11117 13.3834 6.33962L8.94769 13.1836C8.70972 13.5358 8.37656 13.7262 7.98629 13.7262Z"
        fill="white"
      />
    </svg>
  );
};

export default CheckboxSVG;
