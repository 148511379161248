import React, { FC } from "react";
import { ISvgProps } from "../types";

const InputAddressSVG: FC<ISvgProps> = ({ className }) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className || ""}
    >
      <path
        d="M8.76465 6.12939C8.76465 7.59033 9.75244 8.81055 11.1055 9.15088V14.4053C11.1055 16.854 11.5454 18.1904 11.8774 18.1904C12.2178 18.1904 12.6494 16.8623 12.6494 14.4053V9.15088C14.0024 8.81885 14.9985 7.59033 14.9985 6.12939C14.9985 4.41113 13.6123 3 11.8774 3C10.1509 3 8.76465 4.41113 8.76465 6.12939ZM10.9893 6.30371C10.4248 6.30371 9.92676 5.80566 9.92676 5.22461C9.92676 4.65186 10.4248 4.16211 10.9893 4.16211C11.5786 4.16211 12.0601 4.65186 12.0601 5.22461C12.0601 5.80566 11.5786 6.30371 10.9893 6.30371ZM11.8857 21.2285C16.8911 21.2285 19.7632 19.502 19.7632 17.6758C19.7632 15.4844 16.2852 14.1479 14.0024 14.123V15.335C15.6045 15.3599 18.0864 16.2314 18.0864 17.4766C18.0864 18.9043 15.4551 19.917 11.8857 19.917C8.2998 19.917 5.68506 18.9209 5.68506 17.4766C5.68506 16.2314 8.15869 15.3599 9.76074 15.335V14.123C7.47803 14.1479 4 15.4844 4 17.6758C4 19.502 6.88037 21.2285 11.8857 21.2285Z"
        fill="#EBEBF5"
        fillOpacity="0.18"
      />
    </svg>
  );
};

export default InputAddressSVG;
