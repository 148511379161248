import React, { useState } from "react";

import clsx from "clsx";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

import { paths } from "@navigation/routes";

import Input from "@components/Input";
import LinkComponent from "@components/LinkComponent";
import Modal from "@components/Modal";

import Button from "@ui/Button";
import Typography from "@ui/Typography";

import { yupResolver } from "@hookform/resolvers/yup";
import { forgotPasswordAC } from "@saga/authSaga/actions";
import InputPhoneSVG from "@svg/input/phone";
import { schemaForgotPassword } from "@validation";
import { getNormalPhone } from "@utils/utils";

import style from "./Forgot.module.scss";

const sectionForgot = "forgot";

export interface IFormValues {
  phone: string;
}

const ForgotPage = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [isModalActive, setIsModalActive] = useState(true);
  const [isSuccessModalActive, setIsSuccessModalActive] = useState(false);

  const { control, handleSubmit } = useForm({
    resolver: yupResolver(schemaForgotPassword),
    shouldFocusError: false,
  });

  const hideIsSuccessModalActive = () => {
    setIsSuccessModalActive(false);
    navigate(paths.login);
  };

  const onSubmitHandler = (data: IFormValues) => {
    const { phone } = data;

    if (phone) {
      dispatch(
        forgotPasswordAC({
          phone: getNormalPhone(phone),
          showSuccessModal: () => setIsSuccessModalActive(true),
        })
      );
    }
  };

  return (
    <>
      <Modal
        className={style.modal}
        isActive={isModalActive}
        setIsActive={setIsModalActive}
        withLogo
        title={t(`${sectionForgot}.title`)}
        type="medium"
      >
        <form onSubmit={handleSubmit(onSubmitHandler)}>
          <Input
            className={clsx(style.modal__input, style.modal__login)}
            control={control}
            placeholder={t(`${sectionForgot}.phonePlaceholder`)}
            icon={<InputPhoneSVG />}
            mask="+7 999 999-99-99"
            name="phone"
          />

          <Button className={style.modal__btn}>
            <Typography type="sf-text-17">
              {t(`${sectionForgot}.btn`)}
            </Typography>
          </Button>
        </form>

        <Typography className={style.modal__remember} type="sf-text-15">
          <LinkComponent
            to={paths.login}
            className={clsx(style.modal__remember, style.modal__remember_hover)}
            type="sf-text-15"
          >
            {t(`${sectionForgot}.toLogin`)}
          </LinkComponent>
          <Typography
            component="span"
            className={style.modal__splash}
            type="sf-text-15"
          >
            |
          </Typography>
          <LinkComponent
            to={paths.create}
            className={clsx(style.modal__remember, style.modal__remember_hover)}
            type="sf-text-15"
          >
            {t(`${sectionForgot}.toRegister`)}
          </LinkComponent>
        </Typography>
      </Modal>

      <Modal
        isActive={isSuccessModalActive}
        setIsActive={hideIsSuccessModalActive}
        className={style.off}
        classNameTitle={style.off__title}
        type="medium"
        withDarkBg
      >
        <Typography type="sf-text-15" className={style.off__text}>
          {t(`${sectionForgot}.success.title`)}
        </Typography>

        <Button className={style.off__btn} onClick={hideIsSuccessModalActive}>
          <Typography type="sf-text-17">
            {t(`${sectionForgot}.success.btn`)}
          </Typography>
        </Button>
      </Modal>
    </>
  );
};

export default ForgotPage;
