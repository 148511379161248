import React, { FC } from "react";
import { ISvgProps } from "./types";

const MiniArrowSVG: FC<ISvgProps> = ({ className }) => {
  return (
    <svg
      width="12"
      height="7"
      viewBox="0 0 12 7"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M6 7C6.15349 7 6.29535 6.97236 6.42558 6.91708C6.55581 6.85719 6.6814 6.76505 6.80233 6.64067L11.7209 1.66535C11.907 1.47647 12 1.25074 12 0.988154C12 0.803883 11.9558 0.638039 11.8674 0.490622C11.7791 0.338598 11.6605 0.218822 11.5116 0.131293C11.3628 0.0437644 11.1977 0 11.0163 0C10.7419 0 10.5 0.103653 10.2907 0.310958L5.72791 4.97532H6.29302L1.7093 0.310958C1.5 0.103653 1.26047 0 0.990698 0C0.804651 0 0.637209 0.0437644 0.488372 0.131293C0.339535 0.218822 0.22093 0.338598 0.132558 0.490622C0.0441861 0.638039 0 0.803883 0 0.988154C0 1.11714 0.0232558 1.23922 0.0697674 1.35439C0.12093 1.46496 0.193023 1.56861 0.286047 1.66535L5.19767 6.64067C5.43954 6.88022 5.70698 7 6 7Z"
        fill="#EBEBF5"
        fillOpacity="0.6"
      />
    </svg>
  );
};

export default MiniArrowSVG;
