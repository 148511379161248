import React, { FC } from "react";
import { ISvgProps } from "./types";

const ArrowRightSVG: FC<ISvgProps> = ({ className }) => {
  return (
    <svg
      width="7"
      height="11"
      viewBox="0 0 7 11"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M6.76855 5.22656C6.76855 5.02148 6.69238 4.8457 6.52832 4.69336L1.96973 0.228516C1.84082 0.0996094 1.67676 0.0292969 1.4834 0.0292969C1.09668 0.0292969 0.786133 0.333984 0.786133 0.726562C0.786133 0.919922 0.868164 1.08984 0.99707 1.22461L5.10449 5.22656L0.99707 9.22852C0.868164 9.36328 0.786133 9.53906 0.786133 9.72656C0.786133 10.1191 1.09668 10.4238 1.4834 10.4238C1.67676 10.4238 1.84082 10.3535 1.96973 10.2246L6.52832 5.76562C6.69238 5.60742 6.76855 5.43164 6.76855 5.22656Z"
        fill="#EBEBF5"
        fillOpacity="0.3"
      />
    </svg>
  );
};

export default ArrowRightSVG;
