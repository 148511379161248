import * as yup from "yup";

import i18n from "@i18";
import { inputMaxLength } from "@utils/inputMasks/constants";

const requiredGeneralError = i18n.t("errors.requireGeneral");
const phoneError = i18n.t("errors.phone");
const minLengthError = (count: number) => i18n.t("errors.min", { count });
const maxLengthError = (count: number) => i18n.t("errors.max", { count });
const loginError = i18n.t("errors.login");
const nameError = i18n.t("errors.name");
const birthdayError = i18n.t("errors.birthday");
const emailError = i18n.t("errors.email");
const countryError = i18n.t("errors.country");
const walletError = i18n.t("errors.wallet");
const addressError = i18n.t("errors.address");
const amountError = i18n.t("errors.amount");

const loginYup = yup
  .string()
  .required(requiredGeneralError)
  .min(4, minLengthError(4))
  .max(inputMaxLength.login, maxLengthError(inputMaxLength.login))
  .matches(/^[a-zA-Z0-9]+[-_]*[a-zA-Z0-9]*$/, loginError);

const phoneYup = yup
  .string()
  .required(requiredGeneralError)
  .matches(/^\+7 \d{3} \d{3}-\d{2}-\d{2}$|^\+7\d{10}$/, phoneError);

const passwordYup = yup
  .string()
  .required(requiredGeneralError)
  .max(inputMaxLength.password, maxLengthError(inputMaxLength.password))
  .min(6, minLengthError(6));

export const schemaProfileETHWalletYup = yup.object().shape({
  wallet: yup
    .string()
    .required(requiredGeneralError)
    .max(inputMaxLength.wallet, maxLengthError(inputMaxLength.wallet))
    .matches(/^(0x)?[0-9a-fA-F]{40}$|^$/, walletError),
});

export const schemaVerifyPhone = yup.object().shape({
  phone: phoneYup,
});

export const schemaCreateAccount = yup.object().shape({
  login: loginYup,
  password: passwordYup,
  repeat: yup
    .string()
    .required()
    .max(inputMaxLength.password, maxLengthError(inputMaxLength.password)),
});

export const schemaForgotPassword = yup.object().shape({
  phone: phoneYup,
});

export const schemaLogin = yup.object().shape({
  login: loginYup,
  password: passwordYup,
});

export const schemaRegistr = yup.object().shape({
  name: yup
    .string()
    .required(requiredGeneralError)
    .max(inputMaxLength.name, maxLengthError(inputMaxLength.name))
    .matches(
      /^[a-zA-Zа-яА-ЯёЁ-]+ [a-zA-Zа-яА-ЯёЁ-]+( [a-zA-Zа-яА-ЯёЁ-]+)?$/,
      nameError
    ),
  date_birth_day: yup
    .string()
    .required(requiredGeneralError)
    .matches(/\d{2}\.\d{2}\.\d{4}/, birthdayError),
  email: yup
    .string()
    .max(inputMaxLength.email, maxLengthError(inputMaxLength.email))
    .required(requiredGeneralError)
    .email(emailError),
  phone: phoneYup,
  country: yup
    .string()
    .required()
    .max(inputMaxLength.country, maxLengthError(inputMaxLength.country)),
  address: yup
    .string()
    .required(requiredGeneralError)
    .max(inputMaxLength.address, maxLengthError(inputMaxLength.address))
    .matches(/^[a-zA-Zа-яА-ЯёЁ,-\s\d]+$/, countryError),
  wallet: yup
    .string()
    .notRequired()
    .max(inputMaxLength.wallet, maxLengthError(inputMaxLength.wallet))
    .matches(/^(0x)?[0-9a-fA-F]{40}$|^$/, walletError),
});
