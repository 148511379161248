import React, { FC } from "react";
import { ISvgProps } from "./types";

const MouseSVG: FC<ISvgProps> = ({ className }) => {
  return (
    <svg
      width="32"
      height="30"
      viewBox="0 0 32 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <g opacity="0.5">
        <path
          d="M16 9C15.7348 9 15.4804 9.10536 15.2929 9.29289C15.1054 9.48043 15 9.73478 15 10V12C15 12.2652 15.1054 12.5196 15.2929 12.7071C15.4804 12.8946 15.7348 13 16 13C16.2652 13 16.5196 12.8946 16.7071 12.7071C16.8946 12.5196 17 12.2652 17 12V10C17 9.73478 16.8946 9.48043 16.7071 9.29289C16.5196 9.10536 16.2652 9 16 9ZM16 5C14.1435 5 12.363 5.7375 11.0503 7.05025C9.7375 8.36301 9 10.1435 9 12V18C9 19.8565 9.7375 21.637 11.0503 22.9497C12.363 24.2625 14.1435 25 16 25C17.8565 25 19.637 24.2625 20.9497 22.9497C22.2625 21.637 23 19.8565 23 18V12C23 10.1435 22.2625 8.36301 20.9497 7.05025C19.637 5.7375 17.8565 5 16 5ZM21 18C21 19.3261 20.4732 20.5979 19.5355 21.5355C18.5979 22.4732 17.3261 23 16 23C14.6739 23 13.4021 22.4732 12.4645 21.5355C11.5268 20.5979 11 19.3261 11 18V12C11 10.6739 11.5268 9.40215 12.4645 8.46447C13.4021 7.52678 14.6739 7 16 7C17.3261 7 18.5979 7.52678 19.5355 8.46447C20.4732 9.40215 21 10.6739 21 12V18Z"
          fill="white"
        />
        <path
          d="M4.48193 17.206C4.95801 17.5283 5.51465 17.3525 5.51465 16.7592L5.51465 13.6245C5.51465 13.0165 4.97998 12.8261 4.48193 13.1704L2.29932 14.6645C1.91113 14.9208 1.88916 15.4775 2.29932 15.7485L4.48193 17.206Z"
          fill="white"
        />
        <path
          d="M29.6858 15.7488L27.5031 17.2063C27.0197 17.5285 26.4704 17.3528 26.4704 16.7595L26.4704 13.6174C26.4704 13.0168 26.9978 12.8264 27.5031 13.1706L29.6858 14.6648C30.0739 14.9284 30.0886 15.4778 29.6858 15.7488Z"
          fill="white"
        />
      </g>
    </svg>
  );
};

export default MouseSVG;
