import React, { FC } from "react";
import { ISvgProps } from "./types";

const LogoGraySVG: FC<ISvgProps> = ({ className }) => {
  return (
    <svg
      width="112"
      height="37"
      viewBox="0 0 112 37"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        opacity="0.1"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M26.8151 20.8988V36.4712H20.543V27.0184C23.1857 25.6428 25.3704 23.5089 26.8151 20.8988ZM28.4712 10.5642H36.5404V16.3664H28.4712C28.6474 15.4317 28.7531 14.4617 28.7531 13.4565C28.7355 12.5041 28.6474 11.5165 28.4712 10.5642ZM44.6273 16.2958V20.5284H58.5458V26.1014H44.6273V30.7396H59.55V36.4712H38.3551V10.5642H59.5148V16.2958H44.6273ZM74.4728 10.0527C79.4588 10.0527 83.8105 12.7687 86.1362 16.7896L81.0092 19.7524C79.7055 17.4951 77.2565 15.9784 74.4728 15.9784C70.2973 15.9784 66.9321 19.3645 66.9321 23.5265C66.9321 27.7062 70.3149 31.0747 74.4728 31.0747C77.2741 31.0747 79.7055 29.558 81.0092 27.3006L86.1362 30.2634C83.8105 34.2844 79.4588 37.0003 74.4728 37.0003C67.0379 37.0003 61.0124 30.9688 61.0124 23.5265C61.0124 16.0842 67.0379 10.0527 74.4728 10.0527ZM105.728 26.5599H94.2406V36.4889H87.9685V10.5642H94.2406V20.7401H105.728V10.5642H112V36.4712H105.728V26.5599Z"
        fill="white"
      />
      <path
        opacity="0.1"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.4604 0C18.4464 0 22.7982 2.71592 25.1238 6.73689L19.9969 9.69971C18.6931 7.44233 16.2441 5.92564 13.4604 5.92564C9.28488 5.92564 5.91977 9.31173 5.91977 13.4738C5.91977 17.6535 9.3025 21.0219 13.4604 21.0219C16.5965 21.0219 19.2921 19.0996 20.4373 16.3661H10.8353V10.5639H20.4373H25.1238H26.6037C26.8152 11.4986 26.9209 12.4685 26.9209 13.4738C26.9209 20.9161 20.8954 26.9476 13.4604 26.9476C6.02548 26.9476 0 20.9161 0 13.4738C0 6.03146 6.02548 0 13.4604 0Z"
        fill="white"
      />
    </svg>
  );
};

export default LogoGraySVG;
