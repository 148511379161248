import React, { FC } from "react";

import { useTranslation } from "react-i18next";

import Modal from "@components/Modal";

import Typography from "@ui/Typography";

import style from "./ModalInfo.module.scss";

interface IProps {
  isActive: boolean;
  setIsActive: (value: boolean) => void;
  date: string;
  title: string;
  text: string;
}

const ModalInfo: FC<IProps> = ({
  isActive,
  setIsActive,
  date,
  text,
  title,
}) => {
  const { t } = useTranslation();

  const section = "modals.info";

  return (
    <Modal
      isActive={isActive}
      setIsActive={setIsActive}
      withDarkBg
      isCenterByVertical
      withClose
      className={style.modal}
    >
      <Typography type="roboto-15" className={style.modal__date}>
        {date}
      </Typography>
      <Typography type="montserrat-28" className={style.modal__title}>
        {title}
      </Typography>
      <div
        className={style.modal__text}
        dangerouslySetInnerHTML={{ __html: text }}
      />
    </Modal>
  );
};

export default ModalInfo;
