import React, { ChangeEvent, useCallback, useEffect, useState } from "react";

import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import { paths } from "@navigation/routes";

import { setAppMessage } from "@slices/AppSlice";
import { isAuthSelector } from "@slices/AppSlice/selectors";
import { setCode, setPhone } from "@slices/UserSlice";

import Checkbox from "@components/Checkbox";
import Input from "@components/Input";
import LinkComponent from "@components/LinkComponent";
import Modal from "@components/Modal/Modal";

import Button from "@ui/Button/Button";
import Typography from "@ui/Typography";

import { yupResolver } from "@hookform/resolvers/yup";
import useTimer from "@hooks/useTimer";
import { checkCodeAC, getCodeAC } from "@saga/authSaga/actions";
import InputEditSVG from "@svg/input/edit";
import InputPasswordSVG from "@svg/input/password";
import InputPhoneSVG from "@svg/input/phone";
import { getNormalPhone } from "@utils/utils";
import { schemaVerifyPhone } from "@validation";

import style from "./Verify.module.scss";

const sectionVerify = "verify";

const VerifyPage = () => {
  const { t } = useTranslation();
  const {
    control,
    handleSubmit,
    formState: { errors },
    getValues,
    setError,
  } = useForm({
    mode: "onChange",
    resolver: yupResolver(schemaVerifyPhone),
    shouldFocusError: false,
  });

  const { seconds, start, isStopped, isStarted, stop } = useTimer({
    initialSeconds: 60,
  });

  const dispatch = useDispatch();

  const navigate = useNavigate();

  const isAuth = useSelector(isAuthSelector);

  const [isModalActive, setIsModalActive] = useState<boolean>(false);
  const [checkedPolitic, setCheckedPolitic] = useState<boolean>(false);
  const [isSentSMS, setIsSentSMS] = useState<boolean>(false);

  const sagaSendCodeSuccess = () => {
    setIsSentSMS(true);
    start();
  };

  const sagaSendCodeError = () => {
    setIsSentSMS(true);
    stop();
    start();
    dispatch(
      setAppMessage({
        message: t("errors.codeIsSent"),
        type: "warning",
      })
    );
  };

  const setPhoneError = () => {
    setError("phone", {
      type: "manual",
      message: "Аккаунт уже зарегистрирован",
    });
  }

  const onSubmitHandler = useCallback(() => {
    const phone = getNormalPhone(getValues("phone"));
    dispatch(
      getCodeAC({
        phone,
        check_is_register_phone: true,
        setSendCodeSuccess: sagaSendCodeSuccess,
        setSendCodeError: sagaSendCodeError,
        setPhoneError: setPhoneError,
      })
    );
  }, [getCodeAC, getNormalPhone, getValues, sagaSendCodeSuccess, dispatch]);

  const onAgainClickHandler = () => onSubmitHandler();

  const editHandler = useCallback(() => {
    setIsSentSMS(false);
    setCheckedPolitic(false);
    stop();
  }, [setIsSentSMS, setCheckedPolitic, stop]);

  const sagaCheckCodeError = () => {
    setError("code", {
      type: "manual",
      message: "Неверный код",
    });
  };

  const sagaCheckCodeSuccess = (code?: string) => {
    code && dispatch(setCode(code));
    navigate(paths.create);
  };

  const onChangeCodeHandler = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      const code = e.currentTarget.value.replace(/_/g, "").trim();
      if (code.length === 6) {
        dispatch(setCode(code));
        dispatch(setPhone(getNormalPhone(getValues("phone"))));
        dispatch(
          checkCodeAC({
            code,
            phone: getNormalPhone(getValues("phone")),
            setCheckError: sagaCheckCodeError,
            setCheckSuccess: sagaCheckCodeSuccess,
          })
        );
      }
    },
    []
  );

  useEffect(() => {
    isAuth && navigate(paths.profile);
    setIsModalActive(true);
  }, []);

  return (
    <Modal
      isActive={isModalActive}
      setIsActive={setIsModalActive}
      className={style.modal}
      withLogo
      type="medium"
      title={t(`${sectionVerify}.title`)}
    >
      <form onSubmit={handleSubmit(onSubmitHandler)}>
        <Input
          className={style.modal__phone}
          icon={<InputPhoneSVG />}
          placeholder={t(`${sectionVerify}.placeholderPhone`)}
          control={control}
          name="phone"
          mask="+7 999 999-99-99"
          isHold={isSentSMS}
          rightElem={
            isSentSMS ? (
              <Button className={style.modal__edit} onClick={editHandler}>
                <InputEditSVG />
              </Button>
            ) : undefined
          }
        />
        {!isSentSMS && (
          <Button className={style.modal__btn} disabled={!!errors.phone}>
            <Typography component="span" type="sf-text-17">
              {t(`${sectionVerify}.btn`)}
            </Typography>
          </Button>
        )}
      </form>
      {isSentSMS && (
        <>
          <div className={style.modal__politic}>
            <Checkbox value={checkedPolitic} setValue={setCheckedPolitic} />
            <div className={style["modal__politic-right"]}>
              {t(`${sectionVerify}.politic.agree`)}
              <LinkComponent to={paths.lk} className={style.modal__link}>
                {t(`${sectionVerify}.politic.condition`)}
                <br />
              </LinkComponent>
              {t(`${sectionVerify}.politic.and`)}
              <LinkComponent to={paths.lk} className={style.modal__link}>
                {t(`${sectionVerify}.politic.text`)}
              </LinkComponent>
            </div>
          </div>
          {checkedPolitic && (
            <>
              <Input
                className={style.modal__code}
                icon={<InputPasswordSVG />}
                placeholder={t(`${sectionVerify}.placeholderCode`)}
                control={control}
                name="code"
                mask="999999"
                subscription={
                  isStarted
                    ? `${t(`${sectionVerify}.promptText`)} ${seconds} ${t(
                        `${sectionVerify}.promptSec`
                      )}`
                    : undefined
                }
                onChange={onChangeCodeHandler}
              />
              {isStopped && (
                <Typography
                  className={style.modal__again}
                  type="sf-text-15"
                  component="button"
                  onClick={onAgainClickHandler}
                >
                  {t(`${sectionVerify}.sendAgain`)}
                </Typography>
              )}
            </>
          )}
        </>
      )}
      <div className={style.modal__bottom}>
        <Typography type="sf-text-15">{t(`${sectionVerify}.quest`)}</Typography>
        <LinkComponent to={paths.login} className={style.modal__link}>
          {t(`${sectionVerify}.link`)}
        </LinkComponent>
      </div>
    </Modal>
  );
};

export default VerifyPage;
