import React, { FC, useState } from "react";

import clsx from "clsx";
import { format } from "date-fns";
import DatePicker from "react-date-picker";

import Input, { IInputProps } from "@components/Input";

import style from "./DatePickerInput.module.scss";

interface IProps {
  onChange(value: string): void;
  input: IInputProps;
}

const DatePickerInput: FC<IProps> = ({ onChange, input }) => {
  const [date, setDate] = useState(new Date());
  const [isOpen, setIsOpen] = useState(false);

  const onChangeHandler = (value: Date) => {
    setDate(value);
    onChange(format(value, "dd.MM.yyy"));
    setIsOpen(false);
  };

  const onFocusHandler = () => {
    setIsOpen(true);
  };

  const onBlurHandler = () => {
    setIsOpen(false);
  };

  return (
    <DatePicker
      className={clsx(style.block, {
        [style.block_open]: isOpen,
      })}
      onChange={onChangeHandler}
      value={date}
      format="dd.MM.yyyy"
      openCalendarOnFocus={false}
      calendarIcon={
        <Input {...input} onFocus={onFocusHandler} onBlur={onBlurHandler} />
      }
      clearIcon={null}
    />
  );
};

export default DatePickerInput;
