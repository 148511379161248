import React, { memo, useCallback, useEffect, useRef, useState } from "react";

import clsx from "clsx";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { Range, getTrackBackground } from "react-range";
import { useDispatch, useSelector } from "react-redux";
import { Swiper as SwiperType } from "swiper";
import "swiper/css";
import "swiper/css/bundle";
import { Swiper, SwiperSlide } from "swiper/react";

import { coinInfoSelector } from "@slices/CoinSlice/selectors";
import { otherSelector } from "@slices/OtherSlice/selectors";
import { profileUserSelector } from "@slices/UserSlice/selectors";

import Chart from "@components/Chart";
import ChartDot from "@components/ChartDot";
import CoinCard from "@components/CoinCard";
import Input from "@components/Input";
import ModalInfo from "@components/Modals/ModalInfo";
import ModalReady from "@components/Modals/ModalReady";
import ModalShop from "@components/Modals/ModalShop";
import Pagination from "@components/Pagination";
import Spinner from "@components/Spinner";
import Tabs from "@components/Tabs";

import Button from "@ui/Button";
import Container from "@ui/Container";
import EmptyContent from "@ui/EmptyContent";
import Title from "@ui/Title";
import Typography from "@ui/Typography";
import Value from "@ui/Value";
import Window from "@ui/Window";

import ETHBg from "@images/ETHBg.svg";
import GTBg from "@images/GTBg.svg";
import NewsImg from "@images/news.png";
import {
  changeWalletAC,
  exchangeGTECHAC,
  getPayoutsAC,
  getProfitPullAC,
} from "@saga/accountSaga/actions";
import {
  ISagaGetPayoutsAction,
  ISagaGetProfitPullAction,
} from "@saga/accountSaga/types";
import ETHLogoSVG from "@svg/ETHLogo";
import ETHLogoDotsSVG from "@svg/ETHLogoDots";
import ArrowLeftSVG from "@svg/arrowLeft";
import ArrowRightSVG from "@svg/arrowRight";
import CalendarBigSVG from "@svg/calendarBig";
import GtechLogoSVG from "@svg/gtechLogo";
import InputEditSVG from "@svg/input/edit";
import InputGTECHSVG from "@svg/input/gtech";
import MouseSVG from "@svg/mouse";
import NewsSVG from "@svg/news";
import PowerSVG from "@svg/power";
import PredictionSVG from "@svg/prediction";
import TitleProfitSVG from "@svg/title/profit";
import TitleWalletSVG from "@svg/title/wallet";
import { createArrayMask } from "@utils/inputMasks";
import { inputMaxLength } from "@utils/inputMasks/constants";
import { schemaProfileETHWalletYup } from "@validation";

import style from "./Lk.module.scss";

const section = "lk";
const sectionMain = `${section}.main`;
const sectionRate = `${section}.rate`;
const sectionInfo = `${section}.info`;
const sectionYield = `${section}.yield`;
const sectionPayout = `${section}.payout`;
const sectionProfit = `${section}.profit`;
const sectionNews = `${section}.news`;
const modals = "modals";

interface IModalsState {
  buy: boolean;
  sell: boolean;
  finish: boolean;
  info: boolean;
  wallet: boolean;
}

type ModalsType = "buy" | "sell" | "finish" | "info" | "wallet";

const LkPage = () => {
  const { t } = useTranslation();

  const dispatch = useDispatch();

  const { GTECHInfo, ETHInfo, ETHWallet, email, payouts } =
    useSelector(profileUserSelector);
  const {
    forecast,
    coefficient,
    dataCenterInfo,
    profit: coinProfit,
    GTECHPerSecond,
  } = useSelector(coinInfoSelector);
  const { hashrate = null, power = null } = dataCenterInfo || {};
  const { constants, news } = useSelector(otherSelector);
  const { current_course = null } = GTECHInfo || {};

  const { control, setError, getValues, clearErrors, setValue, watch } =
    useForm({
      shouldFocusError: false,
    });

  const [modals, setModals] = useState<IModalsState>({
    buy: false,
    finish: false,
    info: false,
    sell: false,
    wallet: false,
  });
  const [yieldActiveTab, setYieldActiveTab] = useState<string>("");
  const [payoutActiveTab, setPayoutActiveTab] = useState<string>("");
  const [activePage, setActivePage] = useState<number>(1);
  const [profit, setProfit] = useState<number[]>([50]);
  const [trackPadding, setTrackPadding] = useState<number>(50);
  const [swiperState, setSwiperState] = useState<SwiperType | null>(null);
  const [modalBigInfo, setModalBigInfo] = useState<{
    title: string;
    text: string;
    date: string;
  }>({ title: "", text: "", date: "" });

  const thumb = useRef<HTMLDivElement | null>(null);

  const profitMin = 1;
  const profitMax = 100;

  const walletWatch = watch("changeWallet");

  const callBuyModalHandler = useCallback(
    () => setModals((prev) => ({ ...prev, buy: true })),
    [setModals]
  );

  const callSellModalHandler = useCallback(
    () => setModals((prev) => ({ ...prev, sell: true })),
    [setModals]
  );

  const callChangeWalletModalHandler = useCallback(
    () => setModals((prev) => ({ ...prev, wallet: true })),
    [setModals]
  );

  const changeProfit = (values: number[]) => {
    return setProfit(values);
  };

  const onSwiperHandler = useCallback(
    (swiper: SwiperType) => setSwiperState(swiper),
    []
  );

  const onClickNextHandler = useCallback(
    () => swiperState?.slideNext(),
    [swiperState]
  );
  const onClickPrevHandler = useCallback(
    () => swiperState?.slidePrev(),
    [swiperState]
  );

  const changeModal = (modalType: ModalsType, value: boolean) => {
    setModals((prev) => ({ ...prev, [modalType]: value }));
  };

  const sagaExchangeGTECHSuccess = (type: "BUY" | "SELL" | "WALLET") => {
    const inputName =
      type === "BUY"
        ? "buyAmount"
        : type === "SELL"
        ? "sellAmount"
        : "changeWallet";

    setValue(inputName, "");
    changeModal(
      type === "BUY" ? "buy" : type === "SELL" ? "sell" : "wallet",
      false
    );
    changeModal("finish", true);
  };

  const exchangeGTECHHandler = (type: "BUY" | "SELL") => {
    const inputName = type === "BUY" ? "buyAmount" : "sellAmount";
    const { buyAmount, sellAmount } = getValues();

    const currentAmount = type === "BUY" ? buyAmount : sellAmount;
    if (!currentAmount)
      return setError(inputName, {
        type: "manual",
        message: t("errors.requireGeneral"),
      });

    clearErrors();

    dispatch(
      exchangeGTECHAC({
        amount: +currentAmount,
        exchange_type: type,
        setExchangeGTECHSuccess: () => sagaExchangeGTECHSuccess(type),
      })
    );
  };

  const changeWalletGTECHHandler = async (type: "WALLET") => {
    const { changeWallet } = getValues();

    clearErrors();

    const isValid = await validateWallet(changeWallet);

    if (!isValid) return;

    dispatch(
      changeWalletAC({
        new_wallet: changeWallet,
        setExchangeGTECHSuccess: () => sagaExchangeGTECHSuccess(type),
      })
    );
  };

  const validateWallet = async (wallet: string) => {
    try {
      await schemaProfileETHWalletYup.validate({ wallet });
      return true;
    } catch (e: any) {
      setError("changeWallet", {
        type: "manual",
        message: e.errors[0] as string,
      });
      return false;
    }
  };

  const changeProfitPull = async (value: string, index: number) => {
    const dates = ["week", "months", "all"];

    await dispatch(
      getProfitPullAC({
        start: dates[index],
      } as ISagaGetProfitPullAction)
    );

    setYieldActiveTab(value);
  };

  const changePayouts = async (
    value: string,
    index: number,
    page = activePage
  ) => {
    const dates = ["6months", "year", "all"];

    await dispatch(
      getPayoutsAC({
        page,
        start: dates[index],
      } as ISagaGetPayoutsAction)
    );

    setPayoutActiveTab(value);
  };

  const onChangePageHandler = (page: number) => {
    changePayouts(payoutActiveTab, 0, page);
    setActivePage(page);
  };

  const onClickNewsHandler = (title: string, text: string, date: string) => {
    setModalBigInfo({ title, text, date });
    changeModal("info", true);
  };

  const getETHForecast = (): number =>
    GTECHInfo?.balance && coefficient
      ? +(GTECHInfo?.balance * coefficient).toFixed(6)
      : 0;

  useEffect(() => {
    thumb?.current && setTrackPadding(thumb?.current?.offsetWidth / 2);
  }, [thumb?.current?.offsetWidth]);

  useEffect(() => {
    clearErrors();
  }, [walletWatch]);

  return (
    <div className={style.lk}>
      <div className={style.main}>
        <Container>
          <div
            className={clsx(style.main__content, {
              ["empty_relative"]: !ETHWallet,
            })}
          >
            <Typography
              component="h1"
              type="montserrat-44"
              className={style.main__title}
            >
              {t(`${sectionMain}.title`)}
            </Typography>
            <div className={style.main__right}>
              <div className={clsx(style.main__account)}>
                {
                  ETHWallet ? (
                    <>
                      <Typography
                        type="sf-text-12"
                        className={style.main__name}
                      >
                        {t(`${sectionMain}.name`)}
                      </Typography>
                      <Typography
                        type="sf-text-16"
                        className={style.main__numb}
                      >
                        {ETHWallet}
                      </Typography>
                    </>
                  ) : null /*(
                  <Spinner />
                )*/
                }
              </div>
              {ETHWallet ? (
                <>
                  <Button
                    className={style.main__btn}
                    onClick={callChangeWalletModalHandler}
                  >
                    <InputEditSVG />
                  </Button>
                </>
              ) : null}
            </div>
          </div>
        </Container>
      </div>

      <div className={style.rate}>
        <Container>
          <div className={style.rate__content}>
            <div className={clsx(style.rate__left, style.rate__window)}>
              <CoinCard
                className={style.rate__gtech}
                logo={<GtechLogoSVG />}
                titleCoin={t(`${sectionRate}.CoinGTECH`)}
                balanceCoin={GTECHInfo?.balance}
                coin={t(`${sectionRate}.CoinGTECH`)}
                balanceUSD={GTECHInfo?.balance_in_usd}
                course={GTECHInfo?.current_course}
                isLoading={!GTECHInfo}
                imgBg={
                  <img
                    src={GTBg}
                    alt=""
                    className={style["rate__gtech-icon"]}
                  />
                }
                buttons={{
                  buy: (
                    <Button
                      className={clsx(style.rate__buy, style.rate__btn)}
                      onClick={callBuyModalHandler}
                    >
                      <Typography type="roboto-15">
                        {t(`${sectionRate}.btnBuy`)}
                      </Typography>
                    </Button>
                  ),
                  sell: (
                    <Button
                      className={clsx(style.rate__sell, style.rate__btn)}
                      onClick={callSellModalHandler}
                    >
                      <Typography type="roboto-15">
                        {t(`${sectionRate}.btnSell`)}
                      </Typography>
                    </Button>
                  ),
                }}
              />
            </div>
            <div className={clsx(style.rate__right, style.rate__window)}>
              <CoinCard
                className={style.rate__eth}
                logo={<ETHLogoSVG />}
                balanceCoin={ETHInfo?.balance}
                titleCoin={t(`${sectionRate}.titleCoinETH`)}
                coin={t(`${sectionRate}.coinETH`)}
                balanceUSD={ETHInfo?.balance_in_usd}
                course={ETHInfo?.current_course}
                isLoading={!ETHInfo}
                imgBg={
                  <img src={ETHBg} alt="" className={style["rate__eth-icon"]} />
                }
              />
            </div>
          </div>
        </Container>
      </div>

      <div className={style.info}>
        <Container>
          <div className={style.info__content}>
            <Window
              className={clsx(style.info__left, {
                ["empty_relative"]: !(forecast && ETHInfo),
              })}
            >
              <div
                className={clsx(style.info__prediction, {
                  ["empty_relative"]: !(forecast && ETHInfo && coefficient),
                })}
              >
                <Typography
                  type="roboto-17"
                  className={clsx(style["info__prediction-title"])}
                >
                  {t(`${sectionInfo}.promptTitle`)}
                </Typography>
                {forecast && ETHInfo && coefficient ? (
                  <>
                    <Value
                      numb={getETHForecast()}
                      item="ETH"
                      className={style.info__value}
                    />
                    <Typography
                      type="roboto-20"
                      className={clsx(style.info__money)}
                    >
                      {Math.round(
                        getETHForecast() * ETHInfo?.current_course * 100
                      ) / 100}{" "}
                      USD
                    </Typography>
                  </>
                ) : (
                  <Spinner />
                )}
              </div>
              <PredictionSVG className={style["info__prediction-icon"]} />
            </Window>
            <Window className={style.info__right}>
              <div className={style.info__power}>
                <Typography
                  type="roboto-17"
                  className={style["info__power-title"]}
                >
                  <PowerSVG className={style["info__power-icon"]} />
                  {t(`${sectionInfo}.powerTitle`)}
                </Typography>
                <div
                  className={clsx(style["info__power-values"], {
                    ["empty_relative"]: !(
                      power?.last_log && hashrate?.last_log
                    ),
                  })}
                >
                  {power?.last_log && hashrate?.last_log ? (
                    <>
                      <Value
                        className={style["info__power-left"]}
                        numb={Math.round(power?.last_log?.value * 100) / 100}
                        item={power?.rate_currency}
                      />
                      <Value
                        className={style["info__power-left"]}
                        numb={Math.round(hashrate?.last_log?.value * 100) / 100}
                        item={hashrate?.rate_currency}
                      />
                    </>
                  ) : (
                    <Spinner />
                  )}
                </div>
              </div>
              <div className={style.info__freq}>
                {/*<div*/}
                {/*  className={clsx(style["info__freq-left"], {*/}
                {/*    ["empty_relative"]: !(power && hashrate),*/}
                {/*  })}*/}
                {/*>*/}
                {/*  {power && hashrate ? (*/}
                {/*    <>*/}
                {/*      <Typography*/}
                {/*        type="sf-text-12"*/}
                {/*        className={clsx(*/}
                {/*          style["info__freq-text"],*/}
                {/*          style["info__freq-text_top"]*/}
                {/*        )}*/}
                {/*      >*/}
                {/*        {Math.round(power?.last_log?.value * 100) / 100}{" "}*/}
                {/*        {power?.rate_currency}*/}
                {/*      </Typography>*/}

                {/*      <Typography*/}
                {/*        type="sf-text-12"*/}
                {/*        className={style["info__freq-text"]}*/}
                {/*      >*/}
                {/*        {Math.round(hashrate?.last_log?.value * 100) / 100}{" "}*/}
                {/*        {hashrate?.rate_currency}*/}
                {/*      </Typography>*/}
                {/*    </>*/}
                {/*  ) : (*/}
                {/*    <Spinner />*/}
                {/*  )}*/}
                {/*</div>*/}
                <div
                  className={clsx(style["info__freq-right"], {
                    ["empty_relative"]: !(power && hashrate),
                  })}
                >
                  {/*{power && hashrate && (*/}
                  {/*  <>*/}
                  {/*    <img*/}
                  {/*      src={Frequency}*/}
                  {/*      alt=""*/}
                  {/*      className={style.info__icon}*/}
                  {/*    />*/}
                  {/*    <div className={style["info__freq-dates"]}>*/}
                  {/*      <Typography*/}
                  {/*        type="sf-text-12"*/}
                  {/*        className={style["info__freq-text"]}*/}
                  {/*      >*/}
                  {/*        {power?.last_log?.time_add}*/}
                  {/*      </Typography>*/}
                  {/*      <Typography*/}
                  {/*        type="sf-text-12"*/}
                  {/*        className={clsx(*/}
                  {/*          style["info__freq-text"],*/}
                  {/*          style["info__freq-text_right"]*/}
                  {/*        )}*/}
                  {/*      >*/}
                  {/*        {hashrate?.last_log?.time_add}*/}
                  {/*      </Typography>*/}
                  {/*    </div>*/}
                  {/*  </>*/}
                  {/*)}*/}
                  {GTECHPerSecond && <ChartDot data={GTECHPerSecond} />}
                </div>
              </div>
            </Window>
          </div>
        </Container>
      </div>

      <div
        className={clsx(style.yield, {
          ["empty_relative"]: !coinProfit?.length,
        })}
      >
        <Container>
          <Title icon={<TitleProfitSVG />} className={style.yield__title}>
            {t(`${sectionYield}.title`)}
          </Title>
          {!!coinProfit?.length ? (
            <>
              <Tabs
                tabs={[
                  t(`${sectionYield}.tabs.week`),
                  t(`${sectionYield}.tabs.month`),
                  t(`${sectionYield}.tabs.all`),
                ]}
                active={yieldActiveTab}
                setActive={changeProfitPull}
                className={style.yield__tabs}
              />
              <Window
                className={clsx(
                  style.yield__content,
                  style.yield__content_chartik
                )}
              >
                <Chart data={coinProfit} type="profit" />
                {/* <ChartInfoMobile
                date="Декабрь 2021"
                eth="0,243221 ETH"
                usd="268 156,89 USD"
              /> */}
              </Window>
            </>
          ) : (
            <Spinner />
          )}
        </Container>
      </div>

      <div
        className={clsx(style.payout, {
          ["empty_relative"]: !payouts,
        })}
      >
        <Container>
          <Title icon={<TitleWalletSVG />} className={style.payout__title}>
            {t(`${sectionPayout}.title`)}
          </Title>
          {!!payouts?.count ? (
            <>
              <Tabs
                tabs={[
                  t(`${sectionPayout}.tabs.halfYear`),
                  t(`${sectionPayout}.tabs.year`),
                  t(`${sectionPayout}.tabs.all`),
                ]}
                className={style.payout__tabs}
                active={payoutActiveTab}
                setActive={changePayouts}
              />
              <Window
                className={clsx(
                  style.payout__content,
                  style.payout__content_chartik
                )}
              >
                <Chart data={[...payouts.results].reverse()} type="payout" />
                {/* <ChartInfoMobile
                  date="Декабрь 2021"
                  eth="0,243221 ETH"
                  usd="268 156,89 USD"
                /> */}
              </Window>
              <div className={style.payout__table}>
                <div className={style.payout__heads}>
                  <div className={style["payout__row-left"]}>
                    <Typography
                      type="sf-text-12"
                      className={clsx(style.payout__head, style.payout__wallet)}
                    >
                      {t(`${sectionPayout}.table.wallet`)}
                    </Typography>
                    <Typography
                      type="sf-text-12"
                      className={clsx(style.payout__head, style.payout__season)}
                    >
                      {t(`${sectionPayout}.table.season`)}
                    </Typography>
                    <Typography
                      type="sf-text-12"
                      className={clsx(style.payout__head, style.payout__date)}
                    >
                      {t(`${sectionPayout}.table.date`)}
                    </Typography>
                  </div>
                  <div className={style["payout__row-medium"]}>
                    <div className={style.payout__medium}>
                      <Typography
                        type="sf-text-12"
                        className={clsx(
                          style.payout__head,
                          style.payout__payment
                        )}
                      >
                        {t(`${sectionPayout}.table.payment`)}
                      </Typography>
                      <Typography
                        type="sf-text-12"
                        className={clsx(style.payout__head, style.payout__usd)}
                      >
                        {t(`${sectionPayout}.table.usd`)}
                      </Typography>
                    </div>
                  </div>
                  <div className={style["payout__row-right"]}>
                    <Typography
                      type="sf-text-12"
                      className={clsx(style.payout__head, style.payout__volume)}
                    >
                      {t(`${sectionPayout}.table.volume`)}
                    </Typography>
                  </div>
                </div>
                <div className={style.payout__body}>
                  {payouts?.results?.map(
                    ({
                      id,
                      wallet,
                      date_period,
                      time_add,
                      eth_value,
                      usd_value,
                      gtech_value,
                      tx,
                    }) => (
                      <div className={style.payout__row} key={id}>
                        <div className={style["payout__row-left"]}>
                          <Typography
                            type="sf-text-15"
                            className={clsx(
                              style.payout__cell,
                              style.payout__wallet
                            )}
                          >
                            {tx}
                          </Typography>
                          <Typography
                            type="sf-text-15"
                            className={clsx(
                              style.payout__cell,
                              style.payout__season
                            )}
                          >
                            {date_period}
                          </Typography>
                          <Typography
                            type="sf-text-15"
                            className={clsx(
                              style.payout__cell,
                              style.payout__date
                            )}
                          >
                            {time_add}
                          </Typography>
                        </div>
                        <div className={style["payout__row-medium"]}>
                          <Typography
                            type="sf-text-12"
                            className={clsx(
                              style.payout__add,
                              style.payout__add_medium
                            )}
                          >
                            {t(`${sectionPayout}.table.paymentBody`)}
                          </Typography>
                          <div className={style.payout__medium}>
                            <Typography
                              type="sf-text-15"
                              className={clsx(
                                style.payout__cell,
                                style.payout__payment
                              )}
                            >
                              +{Number(eth_value).toFixed(6)} ETH
                            </Typography>
                            <Typography
                              type="sf-text-15"
                              className={clsx(
                                style.payout__cell,
                                style.payout__usd
                              )}
                            >
                              {Number(usd_value).toFixed(2)} USD
                            </Typography>
                          </div>
                        </div>
                        <div className={style["payout__row-right"]}>
                          <Typography
                            type="sf-text-12"
                            className={clsx(
                              style.payout__add,
                              style.payout__add_right
                            )}
                          >
                            {t(`${sectionPayout}.table.volume`)}
                          </Typography>
                          <Typography
                            type="sf-text-15"
                            className={clsx(
                              style.payout__cell,
                              style.payout__volume
                            )}
                          >
                            {Number(gtech_value).toFixed(6)} GTECH
                          </Typography>
                        </div>
                      </div>
                    )
                  )}
                </div>
              </div>
              {Math.ceil(payouts?.count / 5) > 1 && (
                <Pagination
                  className={style.payout__pagination}
                  count={Math.ceil(payouts?.count / 5)}
                  currentPage={activePage}
                  setCurrentPage={onChangePageHandler}
                />
              )}
            </>
          ) : payouts ? (
            <EmptyContent
              className={style.payout__empty}
              icon={<CalendarBigSVG />}
              title={t(`${sectionPayout}.empty.title`)}
              subtitle={t(`${sectionPayout}.empty.subtitle`)}
            />
          ) : (
            <Spinner />
          )}
        </Container>
      </div>

      <div className={style.profit}>
        <Container>
          <Title className={style.profit__title} icon={<TitleProfitSVG />}>
            {t(`${sectionProfit}.title`)}
          </Title>
          <Window
            className={clsx(style.profit__content, {
              ["empty_relative"]: !(coefficient && profit && ETHInfo),
            })}
          >
            {coefficient && profit && ETHInfo ? (
              <>
                <div className={style.profit__info}>
                  <ETHLogoDotsSVG className={style.profit__logo} />
                  <div className={style.profit__text}>
                    <Typography
                      type="roboto-17"
                      className={style.profit__subtitle}
                    >
                      {t(`${sectionProfit}.subtitle`)}
                    </Typography>
                    <Value
                      numb={(profit[0] * (coefficient || 0)).toFixed(6)}
                      item="ETH"
                      className={clsx(style.profit__eth)}
                    />
                    {/* <Value
                      numb={(
                        profit[0] *
                        (coefficient || 0) *
                        ETHInfo?.current_course
                      ).toFixed(6)}
                      item="USD"
                      classNameItem={style.profit__eth}
                    /> */}
                    <Typography
                      type="roboto-20"
                      className={clsx(style.profit__profit_money)}
                    >
                      {(
                        profit[0] *
                        (coefficient || 0) *
                        ETHInfo?.current_course
                      ).toFixed(2)}{" "}
                      USD
                    </Typography>
                  </div>
                </div>
                <div className={style.profit__buttons}>
                  <Button
                    className={clsx(
                      style.profit__button,
                      style.profit__button_buy
                    )}
                    onClick={callBuyModalHandler}
                  >
                    <Typography type="roboto-15">
                      {t(`${sectionProfit}.buyBtn`)}
                    </Typography>
                  </Button>
                  <Button
                    className={clsx(
                      style.profit__button,
                      style.profit__button_sell
                    )}
                    onClick={callSellModalHandler}
                  >
                    <Typography type="roboto-15">
                      {t(`${sectionProfit}.sellBtn`)}
                    </Typography>
                  </Button>
                </div>
                <div
                  className={style.profit__range}
                  style={{
                    paddingLeft: trackPadding,
                    paddingRight: trackPadding,
                  }}
                >
                  <div
                    className={style["profit__track-left"]}
                    style={{ width: trackPadding }}
                  />
                  <Range
                    step={0.01}
                    max={profitMax}
                    min={profitMin}
                    values={profit}
                    onChange={changeProfit}
                    renderTrack={({ props, children }) => (
                      <div
                        {...props}
                        className={style.profit__track}
                        style={{
                          background: getTrackBackground({
                            colors: ["#33CC66", "rgba(84, 84, 88, 0.65)"],
                            max: profitMax,
                            min: profitMin,
                            values: profit,
                          }),
                        }}
                      >
                        {children}
                      </div>
                    )}
                    renderThumb={({ props }) => (
                      <div {...props} className={style.profit__thumb}>
                        <div ref={thumb} className={style.profit__ref} />
                        <Typography
                          type="montserrat-20"
                          className={style["profit__thumb-value"]}
                        >
                          {profit[0]}
                        </Typography>
                        <Typography
                          type="sf-text-12"
                          className={style["profit__thumb-item"]}
                        >
                          GTECH
                        </Typography>
                      </div>
                    )}
                  />
                  <div
                    className={style["profit__track-right"]}
                    style={{ width: trackPadding }}
                  />
                </div>
                <Typography type="sf-text-13" className={style.profit__help}>
                  <MouseSVG className={style.profit__mouse} />
                  {t(`${sectionProfit}.mouseTitle`)}
                </Typography>
                <Typography type="sf-text-13" className={style.profit__money}>
                  {constants?.["annual_profit_text"]}
                </Typography>
              </>
            ) : (
              <Spinner />
            )}
          </Window>
        </Container>
      </div>
      {!news && (
        <div className="empty_relative">
          <Spinner />
        </div>
      )}
      {!!news?.length && (
        <div className={style.news}>
          <Container>
            <Title icon={<NewsSVG />} className={style.news__title}>
              {t(`${sectionNews}.title`)}
            </Title>
            <div className={style.news__content}>
              <div
                className={clsx(style.news__manage, style.news__manage_prev)}
                onClick={onClickPrevHandler}
              >
                <ArrowLeftSVG />
              </div>
              <div
                className={clsx(style.news__manage, style.news__manage_next)}
                onClick={onClickNextHandler}
              >
                <ArrowRightSVG />
              </div>

              <Swiper
                slidesPerView={1}
                spaceBetween={0}
                onSwiper={onSwiperHandler}
                breakpoints={{
                  769: {
                    slidesPerView: 3,
                    spaceBetween: 24,
                  },
                }}
                loop
              >
                {news?.map(
                  (
                    { title, time_add, content, image, short_content },
                    index
                  ) => (
                    <SwiperSlide
                      key={index}
                      onClick={() =>
                        onClickNewsHandler(title, content, time_add)
                      }
                    >
                      <div className={style.news__slide}>
                        <div className={style["news__img-wrapper"]}>
                          <img
                            className={style.news__img}
                            src={image || NewsImg}
                            alt=""
                          />
                        </div>
                        <div className={style.news__bottom}>
                          <Typography
                            type="roboto-15"
                            className={style.news__date}
                          >
                            {time_add}
                          </Typography>
                          <Typography
                            type="montserrat-20"
                            className={style.news__subtitle}
                          >
                            {title}
                          </Typography>
                          <div className={style.news__line} />
                          <div
                            className={style.news__text}
                            dangerouslySetInnerHTML={{ __html: short_content }}
                          />
                        </div>
                      </div>
                    </SwiperSlide>
                  )
                )}
              </Swiper>
            </div>
          </Container>
        </div>
      )}
      <ModalShop
        isActive={modals.buy}
        setIsActive={(value: boolean) => changeModal("buy", value)}
        goal="buy"
        onClickHandler={() => exchangeGTECHHandler("BUY")}
      >
        <Input
          name="buyAmount"
          control={control}
          subscription={`1 GTECH = ${current_course} USD`}
          placeholder={t("modals.buy.placeholder")}
          icon={<InputGTECHSVG />}
          mask={createArrayMask("", inputMaxLength.GTECHAmount)}
        />
      </ModalShop>

      <ModalShop
        isActive={modals.sell}
        setIsActive={(value: boolean) => changeModal("sell", value)}
        goal="sell"
        onClickHandler={() => exchangeGTECHHandler("SELL")}
      >
        <Input
          name="sellAmount"
          control={control}
          subscription={`1 GTECH = ${current_course} USD`}
          placeholder={t("modals.sell.placeholder")}
          icon={<InputGTECHSVG />}
          mask={createArrayMask("", inputMaxLength.GTECHAmount)}
        />
      </ModalShop>

      <ModalShop
        isActive={modals.wallet}
        setIsActive={(value: boolean) => changeModal("wallet", value)}
        goal="wallet"
        onClickHandler={() => changeWalletGTECHHandler("WALLET")}
      >
        <Input
          name="changeWallet"
          control={control}
          subscription={`ETH кошелек`}
          placeholder={t("modals.wallet.placeholder")}
          hideGtechLogoInInput={true}
          icon={<InputGTECHSVG />}
          mask={createArrayMask("", inputMaxLength.wallet)}
        />
      </ModalShop>

      <ModalReady
        isActive={modals.finish}
        setIsActive={(value: boolean) => changeModal("finish", value)}
        email={email}
      />

      <ModalInfo
        isActive={modals.info}
        setIsActive={(value: boolean) => changeModal("info", value)}
        {...modalBigInfo}
      />
    </div>
  );
};

export default memo(LkPage);
