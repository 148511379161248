import React, { FC, ReactElement, memo } from "react";

import clsx from "clsx";

import Typography from "@ui/Typography";
import Window from "@ui/Window";

import style from "./EmptyContent.module.scss";

interface IProps {
  className?: string;
  icon: ReactElement;
  title: string;
  subtitle: string;
}

const EmptyContent: FC<IProps> = ({ className, icon, title, subtitle }) => {
  return (
    <Window
      className={clsx(style.window, {
        [className || ""]: className,
      })}
    >
      {icon}
      <Typography type="sf-display-22" className={style.window__title}>
        {title}
      </Typography>
      <Typography type="sf-text-15" className={style.window__subtitle}>
        {subtitle}
      </Typography>
    </Window>
  );
};

export default memo(EmptyContent);
