import { createAction } from "@reduxjs/toolkit";

import {
  ISagaChangeWalletAction,
  ISagaExchangeGTECHAction,
  ISagaGetInitDataAction,
  ISagaGetPayoutsAction,
  ISagaGetProfitPullAction,
} from "./types";

export const changeWalletAC =
  createAction<ISagaChangeWalletAction>("CHANGE_WALLET");
export const exchangeGTECHAC =
  createAction<ISagaExchangeGTECHAction>("BUY_GTECH");
export const getInitData =
  createAction<ISagaGetInitDataAction>("GET_INIT_DATA");
export const getProfitPullAC =
  createAction<ISagaGetProfitPullAction>("GET_PROFIT_PULL");
export const getPayoutsAC = createAction<ISagaGetPayoutsAction>("GET_PAYOUTS");
