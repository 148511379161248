import React, { FC, memo, useCallback, useEffect } from "react";

import clsx from "clsx";

import Typography from "@ui/Typography";

import style from "./Tabs.module.scss";

interface IProps {
  className?: string;
  tabs: string[];
  active: string;
  setActive: (tab: string, index: number) => void;
}

const Tabs: FC<IProps> = ({ className, tabs, active, setActive }) => {
  const onClickHandler = (tab: string, index: number) => setActive(tab, index);

  useEffect(() => void setActive(tabs[0], 0), []);

  return (
    <div className={clsx(style.tabs, className)}>
      {tabs.map((tab: string, index) => (
        <Typography
          className={clsx(style.tab, {
            [style.tab_active]: tab === active || (!tab && index === 0),
          })}
          component="button"
          type="sf-display-12"
          onClick={() => onClickHandler(tab, index)}
          key={index}
        >
          {tab}
        </Typography>
      ))}
    </div>
  );
};

export default memo(Tabs);
