import React, { FC } from "react";
import { ISvgProps } from "../types";

const InputMailSVG: FC<ISvgProps> = ({ className }) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className || ""}
    >
      <path
        d="M12.0166 12.978C12.3569 12.978 12.6724 12.8203 13.0376 12.4551L20.2593 5.30811C19.9521 5.00928 19.3794 4.86816 18.5576 4.86816H5.19336C4.49609 4.86816 4.00635 5.00098 3.73242 5.2749L10.9956 12.4551C11.3525 12.812 11.668 12.978 12.0166 12.978ZM3.04346 17.834L8.91211 12.0317L3.02686 6.22949C2.90234 6.46191 2.83594 6.86035 2.83594 7.43311V16.5972C2.83594 17.1865 2.90234 17.6016 3.04346 17.834ZM20.9648 17.8257C21.0977 17.585 21.1641 17.1782 21.1641 16.5972V7.43311C21.1641 6.87695 21.0977 6.47852 20.9814 6.25439L15.1128 12.0317L20.9648 17.8257ZM5.44238 19.1704H18.8066C19.5039 19.1704 19.9854 19.0376 20.2593 18.772L14.2744 12.8452L13.7266 13.4014C13.1538 13.9492 12.6143 14.1982 12.0166 14.1982C11.4106 14.1982 10.8711 13.9492 10.3066 13.4014L9.75049 12.8452L3.78223 18.7637C4.09766 19.0376 4.64551 19.1704 5.44238 19.1704Z"
        fill="#EBEBF5"
        fillOpacity="0.18"
      />
    </svg>
  );
};

export default InputMailSVG;
