import React, { memo } from "react";

import { TailSpin } from "react-loader-spinner";

import style from "./Spinner.module.scss";

const Spinner = () => {
  return (
    <div className={style.spinner}>
      <TailSpin arialLabel="loading-indicator" width={40} height={40} />
    </div>
  );
};

export default memo(Spinner);
