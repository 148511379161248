import React, { FC } from "react";
import { ISvgProps } from "./types";

const MessageSVG: FC<ISvgProps> = ({ className }) => {
  return (
    <svg
      width="128"
      height="128"
      viewBox="0 0 128 128"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M27.5625 105.875H99.1875C103.781 105.875 107.359 104.562 109.922 101.938C112.484 99.3438 113.766 95.5469 113.766 90.5469V54.4531C112.234 55.2031 110.625 55.7188 108.938 56C107.25 56.25 105.516 56.2812 103.734 56.0938V89.9375C103.734 91.9062 103.234 93.3594 102.234 94.2969C101.266 95.2344 99.8594 95.7031 98.0156 95.7031H27.8906C26.0469 95.7031 24.625 95.2344 23.625 94.2969C22.6562 93.3594 22.1719 91.8906 22.1719 89.8906V42.1719C22.1719 40.2031 22.6562 38.75 23.625 37.8125C24.625 36.875 26.0469 36.4062 27.8906 36.4062H83.8125C83.5312 34.7188 83.5156 33 83.7656 31.25C84.0156 29.4688 84.5 27.7812 85.2188 26.1875H26.7188C22.125 26.1875 18.5469 27.5 15.9844 30.125C13.4219 32.75 12.1406 36.5469 12.1406 41.5156V90.5469C12.1406 95.5469 13.4531 99.3438 16.0781 101.938C18.7344 104.562 22.5625 105.875 27.5625 105.875ZM63 78.4062C64.7812 78.4062 66.5 78.0312 68.1562 77.2812C69.8125 76.5312 71.4688 75.3438 73.125 73.7188L94.0312 53.0938C92.75 52.3438 91.5625 51.4531 90.4688 50.4219C89.4062 49.3594 88.4688 48.2188 87.6562 47L67.6875 66.7344C66.7812 67.6094 65.9531 68.25 65.2031 68.6562C64.4844 69.0312 63.75 69.2188 63 69.2188C62.25 69.2188 61.5 69.0312 60.75 68.6562C60.0312 68.25 59.2188 67.6094 58.3125 66.7344L23.9062 32.7031L17.8594 39.2188L52.875 73.7188C54.5312 75.3438 56.1875 76.5312 57.8438 77.2812C59.5 78.0312 61.2188 78.4062 63 78.4062ZM101.578 98.2812L107.953 92.0469L78.6094 62.8906L72.4219 69.3125L101.578 98.2812ZM24.7969 97.9062L53.1562 69.7344L46.8281 63.5L18.4688 91.6719L24.7969 97.9062Z"
        fill="#EBEBF5"
        fillOpacity="0.3"
      />
      <path
        d="M112.922 47.6562C110.641 49.0312 108.109 49.7188 105.328 49.7188C102.547 49.7188 100 49.0312 97.6875 47.6562C95.375 46.25 93.5312 44.4062 92.1562 42.125C90.7812 39.8125 90.0938 37.2656 90.0938 34.4844C90.0938 31.7031 90.7812 29.1719 92.1562 26.8906C93.5312 24.5781 95.375 22.7344 97.6875 21.3594C100 19.9531 102.547 19.25 105.328 19.25C108.109 19.25 110.641 19.9531 112.922 21.3594C115.234 22.7344 117.078 24.5781 118.453 26.8906C119.859 29.1719 120.562 31.7031 120.562 34.4844C120.562 37.2656 119.859 39.8125 118.453 42.125C117.078 44.4062 115.234 46.25 112.922 47.6562Z"
        fill="#33CC66"
      />
    </svg>
  );
};

export default MessageSVG;
