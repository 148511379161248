import { PayloadAction, createSlice } from "@reduxjs/toolkit";

import { IDataCenter, IGTECHPerSecond, IProfitGTECH } from "./types";

const initialState = {
  profit: [] as IProfitGTECH[],
  GTECHPerSecond: null as IGTECHPerSecond | null,
  dataCenterInfo: null as null | IDataCenter,
  forecast: 0 as number | string,
  coefficient: 0 as null | number,
};

const coinSlice = createSlice({
  name: "coin",
  initialState,
  reducers: {
    setProfit: (state, action: PayloadAction<IProfitGTECH[]>) => {
      const payload = action.payload.map((obj) => {
        obj.eth_value = (+obj.eth_value as number).toFixed(6);
        obj.usd_value = (+obj.usd_value as number).toFixed(2);
        obj.hashrate && (obj.hashrate.value = +obj.hashrate.value.toFixed(2));

        return obj;
      }).reverse();
      state.profit = payload;
    },
    setGTECHPerSecond: (state, action: PayloadAction<IGTECHPerSecond>) => {
      state.GTECHPerSecond = action.payload;
    },
    setDataCenterInfo: (state, action: PayloadAction<IDataCenter>) => {
      state.dataCenterInfo = action.payload;
    },
    setForecast: (state, action: PayloadAction<number>) => {
      state.forecast = String(action.payload.toFixed(6));
    },
    setCoefficient: (state, action: PayloadAction<number>) => {
      state.coefficient = action.payload;
    },
    clearCoinInfo: (state) => {
      state.profit = [];
      state.forecast = 0;
      state.dataCenterInfo = null;
      state.GTECHPerSecond = null;
      state.coefficient = 0;
    },
  },
});

export const {
  setProfit,
  clearCoinInfo,
  setCoefficient,
  setDataCenterInfo,
  setForecast,
  setGTECHPerSecond,
} = coinSlice.actions;

export default coinSlice.reducer;
