import React, { FC } from "react";
import { ISvgProps } from "./types";

const TelegramSVG: FC<ISvgProps> = ({ className }) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M17.0324 8.734C17.7071 8.06521 18.377 6.5047 15.5656 8.39961C11.5711 11.1317 7.63398 13.6966 7.63398 13.6966C7.22871 13.8783 6.79057 13.9768 6.3459 13.9864C5.90122 13.996 5.45918 13.9164 5.04633 13.7524C3.35952 13.2508 1.39036 12.582 1.39036 12.582C1.39036 12.582 0.0458051 11.7448 2.34744 10.8531C2.34744 10.8531 12.0783 6.89483 15.4532 5.50031C16.7464 4.94298 21.1333 3.15954 21.1333 3.15954C21.1333 3.15954 23.1587 2.37808 22.99 4.27419C22.9338 5.05445 22.484 7.78776 22.0342 10.7416C21.3582 14.9227 20.6273 19.4952 20.6273 19.4952C20.6273 19.4952 20.5148 20.7771 19.559 21C18.5357 20.9828 17.5471 20.6301 16.7476 19.9968C16.5227 19.8296 12.5294 17.3204 11.0663 16.0943C10.9174 15.9944 10.7965 15.8588 10.7148 15.7001C10.633 15.5414 10.5931 15.3648 10.5987 15.1867C10.6043 15.0086 10.6553 14.8348 10.7469 14.6814C10.8384 14.5281 10.9677 14.4002 11.1225 14.3097C13.1515 12.4705 15.5705 10.1843 17.0324 8.734Z"
        fill="#33CC66"
      />
    </svg>
  );
};

export default TelegramSVG;
