import React, { AllHTMLAttributes, Attributes, FC } from "react";

import clsx from "clsx";

import style from "./Typografy.module.scss";

interface IProps {
  className?: string;
  type:
    | "roboto-13"
    | "roboto-15"
    | "roboto-17"
    | "roboto-20"
    | "roboto-24"
    | "sf-text-17"
    | "sf-text-12"
    | "sf-text-13"
    | "sf-text-15"
    | "sf-text-16"
    | "montserrat-17"
    | "montserrat-44"
    | "montserrat-28"
    | "montserrat-36"
    | "montserrat-22"
    | "montserrat-20"
    | "sf-display-12"
    | "sf-display-22";
  component?: "button" | "div" | "span" | "h1" | "h2";
  onClick?: () => void;
  dangerouslySetInnerHTML?: any;
}

const Typography: FC<IProps> = ({
  children,
  type,
  component: Component = "div",
  className = "",
  dangerouslySetInnerHTML,
  ...otherProps
}) => {
  return (
    <Component
      {...otherProps}
      className={clsx(style.typography, className, {
        [style[type || ""]]: type,
      })}
    >
      {children}
    </Component>
  );
};

export default Typography;
