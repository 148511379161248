import { takeEvery, takeLatest } from "redux-saga/effects";

import {
  sagaBuyGTECH,
  sagaChangeWallet,
  sagaGetInitData,
  sagaGetPayouts,
  sagaGetProfitPull,
} from "./accountSaga";
import {
  changeWalletAC,
  exchangeGTECHAC,
  getInitData,
  getPayoutsAC,
  getProfitPullAC,
} from "./accountSaga/actions";
import {
  sagaChange2FA,
  sagaChangePassword,
  sagaCheckCode,
  sagaCreateAccount,
  sagaCreateUser,
  sagaGetCode,
  sagaLoginUser,
  sagaLogout,
  sagaForgotPassword,
} from "./authSaga";
import {
  change2FAAC,
  changePasswordAC,
  checkCodeAC,
  createAccountAC,
  createUserAC,
  getCodeAC,
  loginUserAC,
  logoutAC,
  forgotPasswordAC,
} from "./authSaga/actions";
import { everyMinute, everyMinuteAC } from "@saga/everyMinute";

function* watcher() {
  yield takeEvery(getCodeAC.type, sagaGetCode);
  yield takeEvery(createUserAC.type, sagaCreateUser);
  yield takeEvery(loginUserAC.type, sagaLoginUser);
  yield takeEvery(createAccountAC.type, sagaCreateAccount);
  yield takeEvery(checkCodeAC.type, sagaCheckCode);
  yield takeEvery(change2FAAC.type, sagaChange2FA);
  yield takeEvery(changePasswordAC.type, sagaChangePassword);
  yield takeEvery(changeWalletAC.type, sagaChangeWallet);
  yield takeEvery(exchangeGTECHAC.type, sagaBuyGTECH);
  yield takeEvery(logoutAC.type, sagaLogout);
  yield takeEvery(getInitData.type, sagaGetInitData);
  yield takeEvery(getProfitPullAC.type, sagaGetProfitPull);
  yield takeEvery(getPayoutsAC.type, sagaGetPayouts);
  yield takeEvery(forgotPasswordAC.type, sagaForgotPassword);

  yield takeLatest(everyMinuteAC.type, everyMinute);
}

export default watcher;
