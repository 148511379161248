export const countries = {
  en: [
    "Afghanistan",
    "Albania",
    "Algeria",
    "American Samoa",
    "Andorra",
    "Angola",
    "Anguilla",
    "Antigua and Barbuda",
    "Argentina",
    "Armenia",
    "Aruba",
    "Australia",
    "Austria",
    "Azerbaijan",
    "Bangladesh",
    "Barbados",
    "Bahamas",
    "Bahrain",
    "Belarus",
    "Belgium",
    "Belize",
    "Benin",
    "Bermuda",
    "Bhutan",
    "Bolivia",
    "Bosnia and Herzegovina",
    "Botswana",
    "Brazil",
    "British Indian Ocean Territory",
    "British Virgin Islands",
    "Brunei Darussalam",
    "Bulgaria",
    "Burkina Faso",
    "Burma",
    "Burundi",
    "Cambodia",
    "Cameroon",
    "Canada",
    "Cape Verde",
    "Cayman Islands",
    "Central African Republic",
    "Chad",
    "Chile",
    "China",
    "Christmas Island",
    "Cocos (Keeling) Islands",
    "Colombia",
    "Comoros",
    "Congo-Brazzaville",
    "Congo-Kinshasa",
    "Cook Islands",
    "Costa Rica",
    "Croatia",
    "Cura?ao",
    "Cyprus",
    "Czech Republic",
    "Denmark",
    "Djibouti",
    "Dominica",
    "Dominican Republic",
    "East Timor",
    "Ecuador",
    "El Salvador",
    "Egypt",
    "Equatorial Guinea",
    "Eritrea",
    "Estonia",
    "Ethiopia",
    "Falkland Islands",
    "Faroe Islands",
    "Federated States of Micronesia",
    "Fiji",
    "Finland",
    "France",
    "French Guiana",
    "French Polynesia",
    "French Southern Lands",
    "Gabon",
    "Gambia",
    "Georgia",
    "Germany",
    "Ghana",
    "Gibraltar",
    "Greece",
    "Greenland",
    "Grenada",
    "Guadeloupe",
    "Guam",
    "Guatemala",
    "Guernsey",
    "Guinea",
    "Guinea-Bissau",
    "Guyana",
    "Haiti",
    "Heard and McDonald Islands",
    "Honduras",
    "Hong Kong",
    "Hungary",
    "Iceland",
    "India",
    "Indonesia",
    "Iraq",
    "Ireland",
    "Isle of Man",
    "Israel",
    "Italy",
    "Jamaica",
    "Japan",
    "Jersey",
    "Jordan",
    "Kazakhstan",
    "Kenya",
    "Kiribati",
    "Kuwait",
    "Kyrgyzstan",
    "Laos",
    "Latvia",
    "Lebanon",
    "Lesotho",
    "Liberia",
    "Libya",
    "Liechtenstein",
    "Lithuania",
    "Luxembourg",
    "Macau",
    "Macedonia",
    "Madagascar",
    "Malawi",
    "Malaysia",
    "Maldives",
    "Mali",
    "Malta",
    "Marshall Islands",
    "Martinique",
    "Mauritania",
    "Mauritius",
    "Mayotte",
    "Mexico",
    "Moldova",
    "Monaco",
    "Mongolia",
    "Montenegro",
    "Montserrat",
    "Morocco",
    "Mozambique",
    "Namibia",
    "Nauru",
    "Nepal",
    "Netherlands",
    "New Caledonia",
    "New Zealand",
    "Nicaragua",
    "Niger",
    "Nigeria",
    "Niue",
    "Norfolk Island",
    "Northern Mariana Islands",
    "Norway",
    "Oman",
    "Pakistan",
    "Palau",
    "Panama",
    "Papua New Guinea",
    "Paraguay",
    "Peru",
    "Philippines",
    "Pitcairn Islands",
    "Poland",
    "Portugal",
    "Puerto Rico",
    "Qatar",
    "R?union",
    "Romania",
    "Russia",
    "Rwanda",
    "Saint Barth?lemy",
    "Saint Helena",
    "Saint Kitts and Nevis",
    "Saint Lucia",
    "Saint Martin",
    "Saint Pierre and Miquelon",
    "Saint Vincent",
    "Samoa",
    "San Marino",
    "S?o Tom? and Pr?ncipe",
    "Saudi Arabia",
    "Senegal",
    "Serbia",
    "Seychelles",
    "Sierra Leone",
    "Singapore",
    "Sint Maarten",
    "Slovakia",
    "Slovenia",
    "Solomon Islands",
    "Somalia",
    "South Africa",
    "South Georgia",
    "South Korea",
    "Spain",
    "Sri Lanka",
    "Sudan",
    "Suriname",
    "Svalbard and Jan Mayen",
    "Sweden",
    "Swaziland",
    "Switzerland",
    "Syria",
    "Taiwan",
    "Tajikistan",
    "Tanzania",
    "Thailand",
    "Togo",
    "Tokelau",
    "Tonga",
    "Trinidad and Tobago",
    "Tunisia",
    "Turkey",
    "Turkmenistan",
    "Turks and Caicos Islands",
    "Tuvalu",
    "Uganda",
    "Ukraine",
    "United Arab Emirates",
    "United Kingdom",
    "United States",
    "Uruguay",
    "Uzbekistan",
    "Vanuatu",
    "Vatican City",
    "Vietnam",
    "Venezuela",
    "Wallis and Futuna",
    "Western Sahara",
    "Yemen",
    "Zambia",
    "Zimbabwe",
  ],
  ru: [
    "Абхазия",
    "Австралия",
    "Австрия",
    "Азербайджан",
    "Азорские острова",
    "Аландские острова",
    "Албания",
    "Алжир",
    "Американское Самоа",
    "Ангилья",
    "Ангола",
    "Андорра",
    "Антарктика",
    "Антигуа и Барбуда",
    "Антильские Острова",
    "Аомынь",
    "Аргентина",
    "Армения",
    "Аруба",
    "Афганистан",
    "Багамские Острова",
    "Бангладеш",
    "Барбадос",
    "Бахрейн",
    "Беларусь",
    "Белиз",
    "Бельгия",
    "Бенин",
    "Бермудские Острова",
    "Болгария",
    "Боливия",
    "Босния и Герцеговина",
    "Ботсвана",
    "Бразилия",
    "Британская территория в Индийском океане",
    "Бруней",
    "Буве",
    "Буркина-Фасо",
    "Бурунди",
    "Бутан",
    "Вануату",
    "Ватикан",
    "Великобритания",
    "Венгрия",
    "Венесуэла",
    "Виргинские Острова (Британские)",
    "Виргинские Острова (США)",
    "Внешние малые острова (США)",
    "Восточный Тимор",
    "Вьетнам",
    "Габон",
    "Гаити",
    "Гайана",
    "Гамбия",
    "Гана",
    "Гваделупа",
    "Гватемала",
    "Гвиана",
    "Гвинея",
    "Гвинея-Бисау",
    "Германия",
    "Гернси",
    "Гибралтар",
    "Гондурас",
    "Гонконг",
    "Гренада",
    "Гренландия",
    "Греция",
    "Грузия",
    "Гуам",
    "Дания",
    "Джерси",
    "Джибути",
    "Доминика",
    "Доминиканская Республика",
    "Египет",
    "Замбия",
    "Западная Сахара",
    "Зимбабве",
    "Израиль",
    "Индия",
    "Индонезия",
    "Иордания",
    "Ирак",
    "Иран",
    "Ирландия",
    "Исландия",
    "Испания",
    "Италия",
    "Йемен",
    "Кабо-Верде",
    "Казахстан",
    "Каймановы Острова",
    "Камбоджа",
    "Камерун",
    "Канада",
    "Катар",
    "Кения",
    "Кипр",
    "Кирибати",
    "Китай",
    "Кокосовые Острова",
    "Колумбия",
    "Коморские Острова",
    "Конго, Демократическая Республика",
    "Корея (Северная)",
    "Корея (Южная)",
    "Косово",
    "Коста-Рика",
    "Кот-д'Ивуар",
    "Куба",
    "Кувейт",
    "Кука острова",
    "Кыргызстан",
    "Лаос",
    "Латвия",
    "Лесото",
    "Либерия",
    "Ливан",
    "Ливия",
    "Литва",
    "Лихтенштейн",
    "Люксембург",
    "Маврикий",
    "Мавритания",
    "Мадагаскар",
    "Майотта",
    "Македония",
    "Малави",
    "Малайзия",
    "Мали",
    "Мальдивы",
    "Мальта",
    "Мартиника",
    "Маршалловы Острова",
    "Мексика",
    "Микронезия",
    "Мозамбик",
    "Молдова",
    "Монако",
    "Монголия",
    "Монтсеррат",
    "Морокко",
    "Мьянма",
    "Нагорно-Карабахская Республика",
    "Намибия",
    "Науру",
    "Непал",
    "Нигер",
    "Нигерия",
    "Нидерланды",
    "Никарагуа",
    "Ниуэ",
    "Новая Зеландия",
    "Новая Каледония",
    "Норвегия",
    "Норфолк",
    "Объединенные Арабские Эмираты",
    "Оман",
    "Остров Мэн",
    "Остров Рождества",
    "Остров Святой Елены",
    "Острова Уоллис и Футуна",
    "Острова Херд и Макдональд",
    "Пакистан",
    "Палау",
    "Палестина",
    "Панама",
    "Папуа — Новая Гвинея",
    "Парагвай",
    "Перу",
    "Питкэрн",
    "Польша",
    "Португалия",
    "Приднестровье",
    "Пуэрто-Рико",
    "Республика Конго",
    "Реюньон",
    "Россия",
    "Руанда",
    "Румыния",
    "Сальвадор",
    "Самоа",
    "Сан-Марино",
    "Сан-Томе и Принсипи",
    "Саудовская Аравия",
    "Свазиленд",
    "Свальбард",
    "Северные Марианские острова",
    "Сейшельские острова",
    "Сен-Пьер и Микелон",
    "Сенегал",
    "Сент-Винсент и Гренадины",
    "Сент-Киттс и Невис",
    "Сент-Люсия",
    "Сербия",
    "Сингапур",
    "Сирия",
    "Словакия",
    "Словения",
    "Соединенные Штаты Америки",
    "Соломоновы Острова",
    "Сомали",
    "Сомалиленд",
    "Судан",
    "Суринам",
    "Сьерра-Леоне",
    "Таджикистан",
    "Таиланд",
    "Тайвань",
    "Тамил-Илам",
    "Танзания",
    "Тёркс и Кайкос",
    "Того",
    "Токелау",
    "Тонга",
    "Тринидад и Тобаго",
    "Тувалу",
    "Тунис",
    "Турецкая Республика Северного Кипра",
    "Туркменистан",
    "Турция",
    "Уганда",
    "Узбекистан",
    "Украина",
    "Уругвай",
    "Фарерские Острова",
    "Фиджи",
    "Филиппины",
    "Финляндия",
    "Фолклендские (Мальвинские) острова",
    "Франция",
    "Французская Полинезия",
    "Французские Южные и Антарктические Территории",
    "Хорватия",
    "Центральноафриканская Республика",
    "Чад",
    "Черногория",
    "Чехия",
    "Чили",
    "Швейцария",
    "Швеция",
    "Шри-Ланка",
    "Эквадор",
    "Экваториальная Гвинея",
    "Эритрея",
    "Эстония",
    "Эфиопия",
    "Южная Георгия и Южные Сандвичевы острова",
    "Южная Осетия",
    "Южно-Африканская Республика",
    "Ямайка",
    "Япония",
  ],
};
