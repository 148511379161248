import { IGetUserInfoResponse } from "@api/AuthAPI/types";

import { PayloadAction, createSlice } from "@reduxjs/toolkit";

import {
  ICoinInfo,
  IOperation,
  IPayout,
  IPayouts,
  ITransaction,
  ITransactions,
} from "./types";

const initialState = {
  phone: null as null | string,
  code: null as null | string,
  ETHWallet: null as null | string,
  GTECHInfo: null as ICoinInfo | null,
  ETHInfo: null as ICoinInfo | null,
  login: null as null | string,
  email: null as null | string,
  "2fa": null as null | boolean,
  operations: null as null | IOperation[],
  payouts: null as null | IPayouts,
  transactions: {
    pages_count: 0,
    results: [],
  } as ITransactions,
  transactionFile: null as null | string,
};

const UserSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setUserInfo: (state, action: PayloadAction<IGetUserInfoResponse>) => {
      state.login = action.payload.login;
      state.phone = action.payload.phone;
      state.email = action.payload.email;
    },
    setTransactionFile: (state, action: PayloadAction<string>) => {
      state.transactionFile = action.payload;
    },
    setTransactions: (state, action: PayloadAction<ITransactions>) => {
      const payload: any = action.payload;
      payload.results = payload.results.map((obj: any) => {
        obj["amount"] = Number(obj["amount"].toFixed(6));
        return obj;
      });
      state.transactions = payload;
    },
    set2FA: (state, action: PayloadAction<boolean>) => {
      state["2fa"] = action.payload;
    },
    setPayouts: (state, action: PayloadAction<IPayouts | IPayout[]>) => {
      if ((action.payload as IPayouts).count !== undefined) {
        const payload: any = action.payload;
        payload.results = payload.results
          .map((obj: any) => {
            obj["eth_value"] = Number(obj["eth_value"]).toFixed(6);
            obj["gtech_value"] = Number(obj["gtech_value"]).toFixed(6);
            obj["usd_value"] = Number(obj["usd_value"]).toFixed(2);
            return obj;
          })

        state.payouts = payload as IPayouts;
      } else {
        state.payouts = {
          count: 0,
          next: null,
          previous: null,
          results: action.payload as IPayout[],
        };
      }
    },
    setGTECHInfo: (state, action: PayloadAction<ICoinInfo>) => {
      const payload: any = action.payload;
      payload.balance = Number(action.payload.balance.toFixed(6));
      payload.balance_in_usd = Number(action.payload.balance_in_usd.toFixed(2));
      payload.current_course = Number(action.payload.current_course.toFixed(2));
      state.GTECHInfo = payload;
    },
    setETHInfo: (state, action: PayloadAction<ICoinInfo>) => {
      const payload: any = action.payload;
      payload.balance = Number(action.payload.balance.toFixed(6));
      payload.balance_in_usd = Number(action.payload.balance_in_usd.toFixed(2));
      payload.current_course = Number(action.payload.current_course.toFixed(2));
      state.ETHInfo = payload;
    },
    setETHWallet: (state, action: PayloadAction<string>) => {
      state.ETHWallet = action.payload;
    },
    setOperations: (state, action: PayloadAction<IOperation[]>) => {
      state.operations = action.payload;
    },
    setPhone: (state, action: PayloadAction<string>) => {
      state.phone = action.payload;
    },
    setCode: (state, action: PayloadAction<string>) => {
      state.code = action.payload;
    },
    clearUserInfo: (state) => {
      state.phone = null;
      state.code = null;
      state.ETHInfo = null;
      state.ETHWallet = null;
      state.GTECHInfo = null;
      state.login = null;
      state.email = null;
      state["2fa"] = false;
      state.operations = null;
      state.payouts = null;
    },
  },
});

export const {
  setOperations,
  setCode,
  setPhone,
  clearUserInfo,
  setETHWallet,
  setGTECHInfo,
  setETHInfo,
  setPayouts,
  set2FA,
  setTransactions,
  setUserInfo,
  setTransactionFile,
} = UserSlice.actions;

export default UserSlice.reducer;
