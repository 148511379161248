import React, { FC, useEffect, useMemo, useRef, useState } from "react";

import {
  Area,
  CartesianGrid,
  ComposedChart,
  Line,
  Rectangle,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";

import { IProfitGTECH } from "@slices/CoinSlice/types";
import { IPayout } from "@slices/UserSlice/types";

import Typography from "@ui/Typography";

import style from "./Chart.module.scss";

interface IProps {
  data: IProfitGTECH[] | IPayout[];
  type: "profit" | "payout";
}

const Chart: FC<IProps> = ({ data, type }) => {
  const [activeDotPosition, setActiveDotPosition] = useState<{
    cx: number;
    cy: number;
  }>({ cx: 0, cy: 0 });
  const xAxisData = useRef<string[]>([]);

  const chartRef = useRef<any>(null);
  const tooltipRef = useRef<any>(null);

  const tickFormatter = (value: string, index: number) => {
    if (type === "profit") return value;
    const isExist =
      xAxisData.current.findIndex((data: string) => data === value) !== -1;

    !isExist && (xAxisData.current = [...xAxisData.current, value]);

    if (index + 1 === data.length) xAxisData.current = [];

    return isExist ? "" : value;
  };

  useEffect(() => {}, [data]);

  const getCustomDot = (props: any) => {
    const { cx, cy, value } = props;

    return (
      value && (
        <svg
          key={cx}
          x={(cx || 0) - 7}
          y={(cy || 0) - 7}
          width="14"
          height="14"
          viewBox="0 0 14 14"
          fill="none"
          className={style.dot}
        >
          <circle
            cx="7"
            cy="7"
            r="6"
            fill="#26273B"
            stroke="#33CC66"
            strokeWidth="2"
          />
        </svg>
      )
    );
  };

  const getCustomActiveDot = (props: any) => {
    const { cx, cy, value } = props;
    activeDotPosition.cx !== cx &&
      activeDotPosition.cy !== cy &&
      setActiveDotPosition({ cx, cy });

    return (
      value && (
        <svg
          x={(cx || 0) - 35}
          y={(cy || 0) - 35}
          width="70"
          height="70"
          viewBox="0 0 70 70"
          fill="none"
          className={style.dot_active}
        >
          <g filter="url(#filter0_d_1085_4878)">
            <circle cx="35" cy="35" r="11" fill="white" />
            <circle cx="35" cy="35" r="8.5" stroke="#33CC66" strokeWidth="5" />
          </g>
          <defs>
            <filter
              id="filter0_d_1085_4878"
              x="0"
              y="0"
              width="70"
              height="70"
              filterUnits="userSpaceOnUse"
              colorInterpolationFilters="sRGB"
            >
              <feFlood floodOpacity="0" result="BackgroundImageFix" />
              <feColorMatrix
                in="SourceAlpha"
                type="matrix"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                result="hardAlpha"
              />
              <feOffset />
              <feGaussianBlur stdDeviation="12" />
              <feComposite in2="hardAlpha" operator="out" />
              <feColorMatrix
                type="matrix"
                values="0 0 0 0 0.2 0 0 0 0 0.8 0 0 0 0 0.4 0 0 0 1 0"
              />
              <feBlend
                mode="normal"
                in2="BackgroundImageFix"
                result="effect1_dropShadow_1085_4878"
              />
              <feBlend
                mode="normal"
                in="SourceGraphic"
                in2="effect1_dropShadow_1085_4878"
                result="shape"
              />
            </filter>
          </defs>
        </svg>
      )
    );
  };

  const CustomCursor = (props: any) => {
    return (
      <svg fill="url(#gradient)">
        <Rectangle
          x={activeDotPosition.cx}
          y={activeDotPosition.cy}
          width={1}
          height={
            chartRef?.current?.props?.height - activeDotPosition.cy - 30 || 0
          }
        />
        <defs key={"gradient"}>
          <linearGradient
            id={"gradient"}
            x1={"0%"}
            y={"0%"}
            x2={"0%"}
            y2={"100%"}
          >
            <stop offset={"0%"} stopColor={"rgb(255, 255, 255)"} />
            <stop offset={"100%"} stopColor={"transparent"} />
          </linearGradient>
        </defs>
      </svg>
    );
  };

  const CustomTooltip = (props: any) => {
    if (!props.payload?.[0]) return <></>;

    const { payload } = props;

    const width = tooltipRef?.current?.offsetWidth || 2;

    let date, coin, usd;

    if (type === "profit") {
      const { time_tx, hashrate, eth_value, usd_value } = payload?.[0]
        .payload as IProfitGTECH;

      const { rate_currency: rate = "" } = hashrate?.rate_currency || {};
      const { value = 0 } = hashrate || {};

      date = time_tx;
      coin = `${eth_value} ETH / ${value} ${rate}`;
      usd = `${usd_value} USD`;
    }

    if (type === "payout") {
      const { date_period, eth_value, usd_value } = payload?.[0]
        .payload as IPayout;
      date = date_period;
      coin = `${eth_value} ETH`;
      usd = `${usd_value} USD`;
    }

    let correction = 0;
    const windowWidthAndPaddings = window.innerWidth - 50;
    if (windowWidthAndPaddings - props.coordinate.x - width / 2 < 0) {
      correction = width / 2;
    }

    return (
      <div
        className={style.tooltip}
        style={{ transform: `translateX(-${width / 2 + 200 + correction}px)` }}
        ref={tooltipRef}
      >
        <Typography type="sf-display-12" className={style.tooltip__date}>
          {date}
        </Typography>
        <Typography type="montserrat-20" className={style.tooltip__coin}>
          {coin}
        </Typography>
        <Typography type="montserrat-17" className={style.tooltip__usd}>
          {usd}
        </Typography>
      </div>
    );
  };

  const getMaxValue = useMemo(() => {
    const property = type === "profit" ? "usd_value" : "eth_value";

    const sortedData = [...(data as IProfitGTECH[])].sort(
      (a, b) => +b[property] - +a[property]
    ) || [{ [property]: 0 }];

    return type === "profit"
      ? Math.ceil(+sortedData[0][property])
      : +sortedData[0][property];
  }, [data, type]);

  return (
    <ResponsiveContainer height="100%">
      <ComposedChart
        width={730}
        data={data}
        margin={{ top: 10, right: 20, left: 0, bottom: 0 }}
        ref={chartRef}
      >
        <defs>
          <linearGradient id="colorPv" x1="0" y1="0" x2="0" y2="1">
            <stop offset="0%" stopColor="#33CC61" stopOpacity={0.4} />
            <stop offset="90%" stopColor="#33cc61" stopOpacity={0} />
          </linearGradient>
        </defs>

        <XAxis
          dataKey={type === "profit" ? "short_time_tx" : "short_date_period"}
          axisLine={false}
          allowDecimals={false}
          tickLine={false}
          tick={{
            width: "20000px",
            fontSize: "12px",
            fill: "rgba(235, 235, 245, 0.6)",
            fontFamily: "SFText, sans-serif",
          }}
          domain={[0, "dataMax"]}
          minTickGap={100}
        />

        <YAxis
          width={100}
          dataKey={type === "profit" ? "usd_value" : "eth_value"}
          axisLine={false}
          allowDecimals={false}
          tickLine={false}
          className={style.chart__y}
          dx={-10}
          tick={{
            fontSize: "12px",
            fill: "rgba(235, 235, 245, 0.6)",
            fontFamily: "SFText, sans-serif",
          }}
          tickFormatter={(value) =>
            type === "profit" ? `${value} USD` : `${value} ETH`
          }
          domain={[0, getMaxValue]}
          type="number"
          minTickGap={getMaxValue / 5}
        />

        <CartesianGrid
          strokeDasharray="3"
          className={style.chart__dots}
          vertical={false}
        />

        <Tooltip
          active
          cursor={<CustomCursor />}
          content={CustomTooltip}
          position={{
            y: activeDotPosition.cy + 13,
            x: activeDotPosition.cx,
          }}
        />

        <Area
          type="linear"
          dataKey={type === "profit" ? "usd_value" : "eth_value"}
          stroke="#82ca9d"
          fillOpacity={1}
          fill="url(#colorPv)"
          baseLine={8}
          dot={false}
          activeDot={false}
        />

        <Line
          type="linear"
          dataKey={type === "profit" ? "usd_value" : "eth_value"}
          stroke="#33cc61"
          strokeWidth={2}
          width={500}
          height={3000}
          dot={getCustomDot}
          activeDot={getCustomActiveDot}
          style={{ margin: "30px" }}
        />

        {/*<Brush startIndex={230000} endIndex={2300000} dataKey={"id"} />*/}
      </ComposedChart>
    </ResponsiveContainer>
  );
};

export default Chart;
