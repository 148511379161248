import React, { FC, memo, useCallback, useEffect, useState } from "react";

import clsx from "clsx";

import Input from "@components/Input";
import { IInputProps } from "@components/Input";

import Typography from "@ui/Typography";

import MiniArrowSVG from "@svg/miniArrow";
import { createArrayMask } from "@utils/inputMasks";
import { inputMaxLength } from "@utils/inputMasks/constants";

import style from "./Select.module.scss";

interface IProps extends IInputProps {
  inputClassName?: string;
  listOptions: string[];
  setValue?(value: string): void;
  initialValue: string;
}

const Select: FC<IProps> = ({
  listOptions,
  inputClassName,
  setValue,
  initialValue,
  ...inputProps
}) => {
  const [currentValue, setCurrentValue] = useState<string>(initialValue);
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const onClickHandler = (option: string) => {
    setIsOpen(false);
    setCurrentValue(option);
  };

  const onWrapperClickHandler = useCallback(
    () => setIsOpen(!isOpen),
    [setIsOpen, isOpen]
  );

  const onWrapperBlurHandler = useCallback(() => setIsOpen(false), [setIsOpen]);

  useEffect(() => {
    setValue?.(currentValue);
  }, [currentValue]);

  return (
    <div
      className={clsx(style.wrapper, {
        [style.wrapper_open]: isOpen,
      })}
      onBlur={onWrapperBlurHandler}
      tabIndex={1}
    >
      <div className={style.wrapper__current} onClick={onWrapperClickHandler}>
        <Input
          {...inputProps}
          placeholderTop
          className={clsx(inputClassName, style.wrapper__label)}
          disabled
          value={currentValue}
          rightElem={<MiniArrowSVG className={style.wrapper__arrow} />}
          mask={createArrayMask("", inputMaxLength.country)}
        />
      </div>
      <ul
        className={clsx(style.wrapper__list, {
          [style.wrapper__list_open]: isOpen,
        })}
      >
        {listOptions.map((option, index) => (
          <li
            className={style.wrapper__elem}
            key={index}
            onClick={() => onClickHandler(option)}
          >
            <Typography type="sf-text-15">{option}</Typography>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default memo(Select);
