import React, { FC } from "react";
import { ISvgProps } from "../types";

const InputPasswordSVG: FC<ISvgProps> = ({ className }) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className || ""}
    >
      <path
        d="M8.04883 19.8677H15.9512C17.022 19.8677 17.5449 19.3364 17.5449 18.1743V12.0815C17.5449 11.0356 17.1133 10.4961 16.2251 10.4048V8.31299C16.2251 5.18359 14.1748 3.67285 12 3.67285C9.8252 3.67285 7.7749 5.18359 7.7749 8.31299V10.4463C6.96143 10.5708 6.45508 11.1021 6.45508 12.0815V18.1743C6.45508 19.3364 6.97803 19.8677 8.04883 19.8677ZM9.11133 8.13867C9.11133 6.05518 10.4478 4.95117 12 4.95117C13.5522 4.95117 14.8887 6.05518 14.8887 8.13867V10.3965L9.11133 10.4048V8.13867Z"
        fill="#EBEBF5"
        fillOpacity="0.18"
      />
    </svg>
  );
};

export default InputPasswordSVG;
