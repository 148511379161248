import { TypeOptions } from "react-toastify";

import { PayloadAction, createSlice } from "@reduxjs/toolkit";

const initialState = {
  isAuth: null as null | boolean,
  accessToken: "",
  isLoading: false,
  error: null as null | string,
  appMessage: null as null | { type: TypeOptions; message: string },
};

const AppSlice = createSlice({
  name: "app",
  initialState,
  reducers: {
    setIsAuth: (state, action: PayloadAction<boolean>) => {
      state.isAuth = action.payload;
    },
    setAccessToken: (state, action: PayloadAction<string>) => {
      state.accessToken = action.payload;
    },
    clearAccessToken: (state) => {
      state.accessToken = "";
    },
    setIsLoading: (state) => {
      state.isLoading = true;
    },
    clearIsLoading: (state) => {
      state.isLoading = false;
    },
    setError: (state, action: PayloadAction<string>) => {
      state.error = action.payload;
    },
    clearError: (state) => {
      state.error = null;
    },
    logout: (state) => {
      state.accessToken = "";
      state.isAuth = false;
    },
    setAppMessage: (
      state,
      action: PayloadAction<{ type: TypeOptions; message: string }>
    ) => {
      state.appMessage = action.payload;
    },
    clearAppMessage: (state) => {
      state.appMessage = null;
    },
  },
});

export const {
  setIsAuth,
  clearAccessToken,
  setAccessToken,
  clearIsLoading,
  setIsLoading,
  setError,
  clearError,
  logout,
  clearAppMessage,
  setAppMessage,
} = AppSlice.actions;

export default AppSlice.reducer;
