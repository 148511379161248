import { call, delay, put } from "redux-saga/effects";
import { createAction } from "@reduxjs/toolkit";
import { ISagaGetPayoutsAction } from "@saga/accountSaga/types";
import { AxiosResponse } from "axios";
import { IGetDataCenterPowerResponse, IGetEthBalanceResponse, IGetGTECHRateResponse } from "@api/MiningAPI/types";
import MiningAPI from "@api/MiningAPI";
import { setETHInfo } from "@slices/UserSlice";
import { setDataCenterInfo, setGTECHPerSecond } from "@slices/CoinSlice";

export const everyMinuteAC = createAction("EVERY_MINUTE");

export function* everyMinute() {
  yield delay(60_000);

  const ETHBalance: AxiosResponse<IGetEthBalanceResponse> = yield call(
    MiningAPI.getEthBalance,
  );
  yield put(setETHInfo(ETHBalance.data));

  const dataCenterPower: AxiosResponse<IGetDataCenterPowerResponse> = yield call(
    MiningAPI.getDataCenterPower,
  );
  yield put(setDataCenterInfo(dataCenterPower.data));

  const GTECHRate: AxiosResponse<IGetGTECHRateResponse> = yield call(
    MiningAPI.getGTECHRate,
  );
  yield put(setGTECHPerSecond(GTECHRate.data));

  yield put({ type: everyMinuteAC.type });
}


