import React, { FC } from "react";
import { ISvgProps } from "../types";

const InputAccountSVG: FC<ISvgProps> = ({ className }) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className || ""}
    >
      <g opacity="0.24" clipPath="url(#clip0_881_349)">
        <g clipPath="url(#clip1_881_349)">
          <path
            opacity="0.8"
            d="M11.7254 1L11.5903 1.48554V15.5747L11.7254 15.7173L17.9111 11.8515L11.7254 1Z"
            fill="white"
          />
          <path
            d="M11.7257 1L5.54004 11.8515L11.7257 15.7173V8.87888V1Z"
            fill="white"
          />
          <path
            opacity="0.8"
            d="M11.7251 16.9556L11.6489 17.0538V22.0726L11.7251 22.3078L17.9144 13.0918L11.7251 16.9556Z"
            fill="white"
          />
          <path
            d="M11.7252 22.3078V16.9556L5.53955 13.0918L11.7252 22.3078Z"
            fill="white"
          />
          <path
            opacity="0.5"
            d="M11.7256 15.7172L17.9112 11.8515L11.7256 8.87891V15.7172Z"
            fill="#7E7E7E"
          />
          <path
            d="M5.53955 11.8515L11.7251 15.7172V8.87891L5.53955 11.8515Z"
            fill="#DDDDDD"
          />
        </g>
      </g>
      <defs>
        <clipPath id="clip0_881_349">
          <rect
            width="22"
            height="22"
            fill="white"
            transform="translate(0.727539 1)"
          />
        </clipPath>
        <clipPath id="clip1_881_349">
          <rect
            width="12.375"
            height="21.3125"
            fill="white"
            transform="translate(5.54004 1)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default InputAccountSVG;
