import React from "react";

import { Route, Routes } from "react-router-dom";

import { routes } from "./routes";

const Index = () => {
  return (
    <Routes>
      {routes.map(({ element: Element, path, withHeader }) => (
        <Route key={path} path={path} element={<Element />} />
      ))}
    </Routes>
  );
};

export default Index;
