import React, {
  FC,
  FocusEvent,
  InputHTMLAttributes,
  ReactElement,
  memo,
  useState,
} from "react";

import clsx from "clsx";
import { Control, Controller } from "react-hook-form";
import InputMask from "react-input-mask";
import { v4 as uuidv4 } from "uuid";

import Typography from "@ui/Typography";

import style from "./Input.module.scss";

export interface IInputProps extends InputHTMLAttributes<HTMLInputElement> {
  className?: string;
  labelClassName?: string;
  icon?: ReactElement;
  placeholder: string;
  name: string;
  control: Control<any, object> | undefined;
  subscription?: string;
  rightElem?: ReactElement;
  disabled?: boolean;
  type?: string;
  mask?: string | (string | RegExp)[];
  alwaysShowMask?: boolean;
  isHold?: boolean;
  placeholderTop?: boolean;
  formatChars?: Record<string, string>;
  hideGtechLogoInInput?: boolean;
  maskPlaceholder?: string;
}

const Input: FC<IInputProps> = ({
  className,
  icon,
  control,
  name,
  placeholder,
  subscription,
  labelClassName,
  disabled,
  rightElem,
  type = "text",
  mask,
  maskPlaceholder = null,
  alwaysShowMask,
  isHold,
  onChange,
  placeholderTop,
  formatChars,
  hideGtechLogoInInput,
  onFocus,
  onBlur,
  ...otherProps
}) => {
  const [isFocused, setIsFocused] = useState<boolean>(false);

  const handleOnFocus = (e: FocusEvent<HTMLInputElement>) => {
    setIsFocused(true);
    onFocus?.(e);
  };

  const handleOnBlur = (e: FocusEvent<HTMLInputElement>) => {
    setIsFocused(false);
    onBlur?.(e);
  };

  const id = uuidv4();

  const getInputTag = (inputProps: InputHTMLAttributes<HTMLInputElement>) =>
    mask ? (
      <InputMask
        mask={mask}
        maskPlaceholder={maskPlaceholder}
        {...(alwaysShowMask && { alwaysShowMask })}
        {...inputProps}
        {...(formatChars && { formatChars })}
      />
    ) : (
      <input {...inputProps} />
    );

  return (
    <Controller
      render={({ field, fieldState }) => (
        <div
          className={clsx(style.block, {
            [className || ""]: className,
          })}
        >
          <label
            className={clsx(style.wrapper, {
              [labelClassName || ""]: labelClassName,
              [style.wrapper_focused]: isFocused,
              [style.wrapper_error]: fieldState.error,
              [style.wrapper_hold]: isHold,
            })}
            htmlFor={id}
          >
            {hideGtechLogoInInput === true ? null : (
              <div className={style.wrapper__icon}>{icon}</div>
            )}
            <label
              htmlFor=""
              className={clsx(style.wrapper__right, {
                [style.wrapper__right_focused]: isFocused || !!field.value,
              })}
            >
              <span
                className={clsx(style.wrapper__placeholder, {
                  [style.wrapper__placeholder_focused]:
                    isFocused || !!field.value || placeholderTop,
                })}
              >
                {placeholder}
              </span>
              {getInputTag({
                className: clsx(style.wrapper__input, {
                  [style.wrapper__input_hold]: isHold,
                }),
                id: id,
                ...field,
                onFocus: handleOnFocus,
                onBlur: (e) => (handleOnBlur(e), field.onBlur()),
                onChange: (e) => (onChange?.(e), field.onChange(e)),
                disabled: disabled || isHold,
                type,
                ...otherProps,
              })}
            </label>
            {rightElem}
          </label>
          {fieldState?.error?.message && (
            <Typography type="sf-text-12" className={style.error}>
              {fieldState.error?.message}
            </Typography>
          )}
          <Typography type="sf-text-12" className={style.subscription}>
            {subscription}
          </Typography>
        </div>
      )}
      name={name}
      control={control}
      defaultValue=""
    />
  );
};

export default memo(Input);
