import React, { FC } from "react";
import { ISvgProps } from "./types";

const NewsSVG: FC<ISvgProps> = ({ className }) => {
  return (
    <svg
      width="36"
      height="36"
      viewBox="0 0 36 36"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        opacity="0.4"
        d="M23.5741 24.3359H12.7441"
        stroke="#33CC66"
        strokeWidth="2.25"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        opacity="0.4"
        d="M23.5741 18.0547H12.7441"
        stroke="#33CC66"
        strokeWidth="2.25"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        opacity="0.4"
        d="M16.8766 11.791H12.7441"
        stroke="#33CC66"
        strokeWidth="2.25"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M23.863 4.125C23.863 4.125 12.3475 4.131 12.3295 4.131C8.18948 4.1565 5.62598 6.8805 5.62598 11.0355V24.8295C5.62598 29.0055 8.20898 31.74 12.385 31.74C12.385 31.74 23.899 31.7355 23.9185 31.7355C28.0585 31.71 30.6235 28.9845 30.6235 24.8295V11.0355C30.6235 6.8595 28.039 4.125 23.863 4.125Z"
        stroke="#33CC66"
        strokeWidth="2.25"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default NewsSVG;
