import React, { FC } from "react";

import style from "./Container.module.scss";

interface IProps {
  className?: string;
}

const Container: FC<IProps> = ({ children, ...otherProps }) => {
  return (
    <div className={style.container} {...otherProps}>
      {children}
    </div>
  );
};

export default Container;
