import React, { FC } from "react";
import { ISvgProps } from "./types";

const ArrowLeftSVG: FC<ISvgProps> = ({ className }) => {
  return (
    <svg
      width="7"
      height="11"
      viewBox="0 0 7 11"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M0.232422 5.76953C0.232422 5.97461 0.308594 6.15039 0.472656 6.30273L5.03125 10.7676C5.16016 10.8965 5.32422 10.9668 5.51758 10.9668C5.9043 10.9668 6.21484 10.6621 6.21484 10.2695C6.21484 10.0762 6.13281 9.90625 6.00391 9.77148L1.89648 5.76953L6.00391 1.76758C6.13281 1.63281 6.21484 1.45703 6.21484 1.26953C6.21484 0.876953 5.9043 0.572266 5.51758 0.572266C5.32422 0.572266 5.16016 0.642578 5.03125 0.771484L0.472656 5.23047C0.308594 5.38867 0.232422 5.56445 0.232422 5.76953Z"
        fill="#EBEBF5"
        fillOpacity="0.3"
      />
    </svg>
  );
};

export default ArrowLeftSVG;
