import CreatePage from "@pages/Create";
import LkPage from "@pages/Lk";
import LoginPage from "@pages/Login";
import ProfilePage from "@pages/Profile";
import RegistrPage from "@pages/Registr";
import VerifyPage from "@pages/Verify";
import ForgotPage from "@pages/Forgot";

export const paths = {
  forgot: '/forgot',
  verify: "/verify",
  create: "/create",
  login: "/login",
  registr: "/registr",
  profile: "/profile",
  lk: "/",
};

export const routes = [
  {
    path: paths.forgot,
    withHeader: false,
    withBG: true,
    element: ForgotPage,
  },
  {
    path: paths.verify,
    withHeader: false,
    withBG: true,
    element: VerifyPage,
  },
  {
    path: paths.create,
    withHeader: false,
    withBG: true,
    element: CreatePage,
  },
  {
    path: paths.login,
    withHeader: false,
    withBG: true,
    element: LoginPage,
  },
  {
    path: paths.registr,
    withHeader: false,
    withBG: true,
    element: RegistrPage,
  },
  {
    path: paths.profile,
    withHeader: true,
    withBG: false,
    element: ProfilePage,
  },
  {
    path: paths.lk,
    withHeader: true,
    withBG: true,
    element: LkPage,
  },
];
