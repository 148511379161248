import { AxiosResponse } from "axios";

import { instance } from "@api/settings";

import { getQueryParamsString } from "@utils/utils";

import {
  GetEarningsResponseType,
  IGetCoefficientGTECH,
  IGetDataCenterPowerResponse,
  IGetEarningsParams,
  IGetEthBalanceResponse,
  IGetForecastResponse,
  IGetGTECHBalanceResponse,
  IGetGTECHRateResponse,
  IGetPoolProfitParams,
  IGetPullProfitResponse,
} from "./types";

const MiningAPI = {
  getGTECHBalance: (): Promise<AxiosResponse<IGetGTECHBalanceResponse>> => {
    return new Promise(async (resolve, reject) => {
      try {
        const response: AxiosResponse<IGetGTECHBalanceResponse> =
          await instance.get(`mining/get-gtech-balance/`);
        resolve(response);
      } catch (e) {
        reject(e);
      }
    });
  },

  getEthBalance: (): Promise<AxiosResponse<IGetEthBalanceResponse>> => {
    return new Promise(async (resolve, reject) => {
      try {
        const response: AxiosResponse<IGetEthBalanceResponse> =
          await instance.get(`mining/get-ethereum-balance/`);
        resolve(response);
      } catch (e) {
        reject(e);
      }
    });
  },

  getEarnings: (
    data: IGetEarningsParams
  ): Promise<AxiosResponse<GetEarningsResponseType>> => {
    return new Promise(async (resolve, reject) => {
      const { page, time_add_after, time_add_before } = data;
      const params = [
        { key: "page", value: page },
        { key: "time_add_after", value: time_add_after },
        { key: "time_add_before", value: time_add_before },
      ];
      try {
        const response: AxiosResponse<GetEarningsResponseType> =
          await instance.get(
            `mining/get-earnings/?${getQueryParamsString(params)}`
          );
        resolve(response);
      } catch (e) {
        reject(e);
      }
    });
  },

  getPoolProfit: (
    data: IGetPoolProfitParams
  ): Promise<AxiosResponse<IGetPullProfitResponse[]>> => {
    return new Promise(async (resolve, reject) => {
      const { time_add_after, time_add_before } = data;
      const params = [
        { key: "time_add_after", value: time_add_after },
        { key: "time_add_before", value: time_add_before },
      ];
      try {
        const response: AxiosResponse<IGetPullProfitResponse[]> =
          await instance.get(
            `mining/get-pool-profit/?${getQueryParamsString(params)}`
          );
        resolve(response);
      } catch (e) {
        reject(e);
      }
    });
  },

  getGTECHRate: (): Promise<AxiosResponse<IGetGTECHRateResponse>> => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await instance.get(`mining/get-gtech-rate-logs/?page_size=7`);
        resolve(response);
      } catch (e) {
        reject(e);
      }
    });
  },

  getDataCenterPower(): Promise<AxiosResponse<IGetDataCenterPowerResponse>> {
    return new Promise(async (resolve, reject) => {
      try {
        const response: AxiosResponse<IGetDataCenterPowerResponse> =
          await instance.get(`mining/get-current-data-center-rates/`);
        resolve(response);
      } catch (e) {
        reject(e);
      }
    });
  },

  getForecast(): Promise<AxiosResponse<IGetForecastResponse>> {
    return new Promise(async (resolve, reject) => {
      try {
        const response: AxiosResponse<IGetForecastResponse> =
          await instance.get(`mining/get-profitability-forecast/`);
        resolve(response);
      } catch (e) {
        reject(e);
      }
    });
  },

  getCoefficientGTECH(): Promise<AxiosResponse<IGetCoefficientGTECH>> {
    return new Promise(async (resolve, reject) => {
      try {
        const response: AxiosResponse<IGetCoefficientGTECH> =
          await instance.get(`mining/get-gtech-profit-coeficent/`);
        resolve(response);
      } catch (e) {
        reject(e);
      }
    });
  },
};

export default MiningAPI;
