import React, { ButtonHTMLAttributes, FC } from "react";

import clsx from "clsx";

import style from "./Button.module.scss";

interface IProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  className?: string;
  onClick?: () => void;
  disabled?: boolean;
}

const Button: FC<IProps> = ({ children, className, ...otherProps }) => {
  return (
    <button
      className={clsx(style.button, {
        [className || ""]: className,
      })}
      {...otherProps}
    >
      {children}
    </button>
  );
};

export default Button;
