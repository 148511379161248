import React, { FC } from "react";

import clsx from "clsx";

import style from "./Window.module.scss";

interface IProps {
  className?: string;
}

const Window: FC<IProps> = ({ children, className }) => {
  return (
    <div
      className={clsx(style.window, {
        [className || ""]: className,
      })}
    >
      {children}
    </div>
  );
};

export default Window;
