import React, { FC, useEffect, useState } from "react";

import clsx from "clsx";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import { paths } from "@navigation/routes";

import { isAuthSelector } from "@slices/AppSlice/selectors";
import { setCode, setPhone } from "@slices/UserSlice";

import Input from "@components/Input";
import LinkComponent from "@components/LinkComponent";
import Modal from "@components/Modal";

import Button from "@ui/Button";
import Typography from "@ui/Typography";

import { yupResolver } from "@hookform/resolvers/yup";
import { AppStateType } from "@redux/store";
import { createUserAC } from "@saga/authSaga/actions";
import InputEyeSVG from "@svg/input/eye";
import InputPasswordSVG from "@svg/input/password";
import InputPersonSVG from "@svg/input/person";
import { createArrayMask } from "@utils/inputMasks";
import { inputMaxLength } from "@utils/inputMasks/constants";
import { schemaCreateAccount } from "@validation";

import style from "./Create.module.scss";

interface IProps {
  className?: string;
}

const sectionCreate = "create";

interface IInputs {
  password: boolean;
  repeat: boolean;
}

export interface IFormValues {
  login: string;
  password: string;
  repeat: string;
}

const CreatePage: FC<IProps> = ({ className }) => {
  const { t } = useTranslation();

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { phone, code } = useSelector((state: AppStateType) => state.user);
  const isAuth = useSelector(isAuthSelector);

  const {
    control,
    handleSubmit,
    setError,
    formState: { errors },
    watch,
  } = useForm({
    resolver: yupResolver(schemaCreateAccount),
    shouldFocusError: false,
  });

  const watchPassword = watch("password");
  const watchRepeat = watch("repeat");

  const [isModalActive, setIsModalActive] = useState<boolean>(false);
  const [isInputsSecret, setIsInputsSecret] = useState<IInputs>({
    password: true,
    repeat: true,
  });

  const changeSecret = (type: "password" | "repeat") => {
    setIsInputsSecret((prev) => ({ ...prev, [type]: !prev[type] }));
  };

  const linkHandler = () => {
    dispatch(setPhone(""));
    dispatch(setCode(""));
    navigate(paths.login);
  };

  const sagaCreateUserSuccess = () => navigate(paths.registr);

  const onSubmitHandler = (data: IFormValues) => {
    const { login, password, repeat } = data;
    if (password !== repeat) {
      return setError("repeat", {
        type: "manual",
        message: "Пароль и повторный пароль не совпадают",
      });
    }
    if (code && phone) {
      dispatch(
        createUserAC({
          login,
          password,
          code,
          phone,
          setCreateUserSuccess: sagaCreateUserSuccess,
        })
      );
    }
  };

  const areThereErrors = () =>
    !!Object.keys(errors).length || watchPassword !== watchRepeat;

  useEffect(() => {
    if (!code || !phone || isAuth) navigate(paths.verify);
    setIsModalActive(true);
  }, []);

  return (
    <Modal
      className={clsx(style.modal, {
        [className || ""]: className,
      })}
      isActive={isModalActive}
      setIsActive={setIsModalActive}
      title={t(`${sectionCreate}.title`)}
      type="medium"
    >
      <form onSubmit={handleSubmit(onSubmitHandler)}>
        <Input
          className={clsx(style.modal__input, style.modal__login)}
          control={control}
          placeholder={t(`${sectionCreate}.loginPlaceholder`)}
          icon={<InputPersonSVG />}
          name="login"
          mask={createArrayMask("login", inputMaxLength.login)}
        />
        <Input
          className={clsx(style.modal__input, style.modal__password)}
          control={control}
          placeholder={t(`${sectionCreate}.passwordPlaceholder`)}
          icon={<InputPasswordSVG />}
          name="password"
          type={isInputsSecret.password ? "password" : "text"}
          mask={createArrayMask("password", inputMaxLength.password)}
          rightElem={
            <Button onClick={() => changeSecret("password")} type="button">
              <InputEyeSVG />
            </Button>
          }
        />
        <Input
          className={clsx(style.modal__input, style.modal__password)}
          control={control}
          placeholder={t(`${sectionCreate}.passwordRepeatPlaceholder`)}
          icon={<InputPasswordSVG />}
          name="repeat"
          type={isInputsSecret.repeat ? "password" : "text"}
          mask={createArrayMask("password", inputMaxLength.password)}
          rightElem={
            <Button onClick={() => changeSecret("repeat")} type="button">
              <InputEyeSVG />
            </Button>
          }
        />
        <Button className={style.modal__btn} disabled={areThereErrors()}>
          <Typography type="sf-text-17">{t(`${sectionCreate}.btn`)}</Typography>
        </Button>
        <LinkComponent
          component="div"
          to="#"
          className={style.modal__link}
          onClick={linkHandler}
        >
          <Typography type="sf-text-15">
            {t(`${sectionCreate}.link`)}
          </Typography>
        </LinkComponent>
      </form>
    </Modal>
  );
};

export default CreatePage;
