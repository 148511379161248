import { default as axios } from "axios";
import { t } from "i18next";

import { setAppMessage } from "@slices/AppSlice";

import { store } from "@redux/store";
import { logoutAC } from "@saga/authSaga/actions";

export const instance = axios.create({
  baseURL: process.env.REACT_APP_SERVER_URL,
  headers: {
    "Content-Type": "application/json",
    "Accept-Language": "ru",
  },
});

instance.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response.status === 401) {
      store.dispatch(logoutAC({}));
    }
    if (error.response.status === 500) {
      store.dispatch(
        setAppMessage({ message: t("errors.server"), type: "error" })
      );
    }
    return Promise.reject(error);
  }
);
