import { AxiosResponse } from "axios";

import { instance } from "@api/settings";

import { ITransactions } from "@slices/UserSlice/types";

import {
  IChangeWalletParams,
  IChangeWalletResponse,
  IExchangeGTECHParams,
  IExchangeGTECHResponse,
  IGet2FAStatus,
  IGetTransactionsParams,
  IGetWalletResponse,
} from "./types";

const AccountAPI = {
  getWallet: (): Promise<AxiosResponse<IGetWalletResponse>> => {
    return new Promise(async (resolve, reject) => {
      try {
        const response: AxiosResponse<IGetWalletResponse> = await instance.get(
          `user/wallet/`
        );
        resolve(response);
      } catch (e) {
        reject(e);
      }
    });
  },

  changeWallet: (
    data: IChangeWalletParams
  ): Promise<AxiosResponse<IChangeWalletResponse>> => {
    return new Promise(async (resolve, reject) => {
      try {
        const response: AxiosResponse<IChangeWalletResponse> =
          await instance.post(`user-requests/change-wallet/`, data);
        resolve(response);
      } catch (e) {
        reject(e);
      }
    });
  },

  exchangeGTECH: (
    data: IExchangeGTECHParams
  ): Promise<AxiosResponse<IExchangeGTECHResponse>> => {
    return new Promise(async (resolve, reject) => {
      try {
        const response: AxiosResponse<IExchangeGTECHResponse> =
          await instance.post(`user-requests/exchange-gtech/`, data);
        resolve(response);
      } catch (e) {
        reject(e);
      }
    });
  },

  get2FAStatus: (): Promise<AxiosResponse<IGet2FAStatus>> => {
    return new Promise(async (resolve, reject) => {
      try {
        const response: AxiosResponse<IGet2FAStatus> = await instance.get(
          `2fa/is_on/`
        );
        resolve(response);
      } catch (e) {
        reject(e);
      }
    });
  },

  getTransactions: ({
    page,
  }: IGetTransactionsParams): Promise<AxiosResponse<ITransactions>> => {
    return new Promise(async (resolve, reject) => {
      try {
        const response: AxiosResponse<ITransactions> = await instance.get(
          `mining/get-my-transactions/?page=${page}`
        );
        resolve(response);
      } catch (e) {
        reject(e);
      }
    });
  },

  getTransitionFile: (): Promise<AxiosResponse<any>> => {
    return new Promise(async (resolve, reject) => {
      try {
        const response: AxiosResponse<any> = await instance.get(
          `mining/get-my-transactions-file/`
        );
        resolve(response);
      } catch (e) {
        reject(e);
      }
    });
  },
};

export default AccountAPI;
