import React, { FC } from "react";
import { ISvgProps } from "./types";

const CalendarBigSVG: FC<ISvgProps> = ({ className }) => {
  return (
    <svg
      width="86"
      height="86"
      viewBox="0 0 86 86"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <g opacity="0.3">
        <path
          opacity="0.4"
          d="M11.0811 33.6982H74.9502"
          stroke="white"
          strokeWidth="5.375"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          opacity="0.4"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M24.3955 47.6934C24.3955 46.2091 25.5987 45.0059 27.083 45.0059H27.1162C28.6005 45.0059 29.8037 46.2091 29.8037 47.6934C29.8037 49.1776 28.6005 50.3809 27.1162 50.3809H27.083C25.5987 50.3809 24.3955 49.1776 24.3955 47.6934ZM43.0144 45.0059C41.5301 45.0059 40.3269 46.2091 40.3269 47.6934C40.3269 49.1776 41.5301 50.3809 43.0144 50.3809H43.0476C44.5318 50.3809 45.7351 49.1776 45.7351 47.6934C45.7351 46.2091 44.5318 45.0059 43.0476 45.0059H43.0144ZM58.9178 45.0059C57.4335 45.0059 56.2303 46.2091 56.2303 47.6934C56.2303 49.1776 57.4335 50.3809 58.9178 50.3809H58.9509C60.4352 50.3809 61.6384 49.1776 61.6384 47.6934C61.6384 46.2091 60.4352 45.0059 58.9509 45.0059H58.9178ZM58.9178 58.9309C57.4335 58.9309 56.2303 60.1342 56.2303 61.6184C56.2303 63.1027 57.4335 64.3059 58.9178 64.3059H58.9509C60.4352 64.3059 61.6384 63.1027 61.6384 61.6184C61.6384 60.1342 60.4352 58.9309 58.9509 58.9309H58.9178ZM40.3269 61.6184C40.3269 60.1342 41.5301 58.9309 43.0144 58.9309H43.0476C44.5318 58.9309 45.7351 60.1342 45.7351 61.6184C45.7351 63.1027 44.5318 64.3059 43.0476 64.3059H43.0144C41.5301 64.3059 40.3269 63.1027 40.3269 61.6184ZM27.083 58.9309C25.5987 58.9309 24.3955 60.1342 24.3955 61.6184C24.3955 63.1027 25.5987 64.3059 27.083 64.3059H27.1162C28.6005 64.3059 29.8037 63.1027 29.8037 61.6184C29.8037 60.1342 28.6005 58.9309 27.1162 58.9309H27.083Z"
          fill="white"
        />
        <path
          d="M57.4909 7.16602V18.958"
          stroke="white"
          strokeWidth="5.375"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M28.5416 7.16602V18.958"
          stroke="white"
          strokeWidth="5.375"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M58.1871 12.8262H27.846C17.3228 12.8262 10.75 18.6883 10.75 29.4637V61.8916C10.75 72.8364 17.3228 78.834 27.846 78.834H58.1539C68.7103 78.834 75.2499 72.9381 75.2499 62.1627V29.4637C75.2831 18.6883 68.7435 12.8262 58.1871 12.8262Z"
          stroke="white"
          strokeWidth="5.375"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </svg>
  );
};

export default CalendarBigSVG;
