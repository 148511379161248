import React, { FC } from "react";
import { ISvgProps } from "./types";

const ETHLogoSVG: FC<ISvgProps> = ({ className }) => {
  return (
    <svg
      width="60"
      height="60"
      viewBox="0 0 60 60"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <circle
        opacity="0.3"
        cx="30"
        cy="30"
        r="29"
        stroke="white"
        strokeWidth="2"
      />
      <g clipPath="url(#clip0_898_106)">
        <g clipPath="url(#clip1_898_106)">
          <path
            opacity="0.8"
            d="M29.9967 8.18164L29.7288 9.14469V37.0901L29.9967 37.3729L42.2658 29.7053L29.9967 8.18164Z"
            fill="white"
          />
          <path
            d="M29.9966 8.18164L17.7275 29.7052L29.9966 37.373V23.8092V8.18164Z"
            fill="white"
          />
          <path
            opacity="0.8"
            d="M29.9965 39.8299L29.8455 40.0245V49.9793L29.9965 50.4457L42.2728 32.166L29.9965 39.8299Z"
            fill="white"
          />
          <path
            d="M29.9966 50.4457V39.8298L17.7275 32.166L29.9966 50.4457Z"
            fill="white"
          />
          <path
            opacity="0.5"
            d="M29.9966 37.3722L42.2655 29.7047L29.9966 23.8086V37.3722Z"
            fill="#7E7E7E"
          />
          <path
            d="M17.7275 29.7047L29.9964 37.3722V23.8086L17.7275 29.7047Z"
            fill="#DDDDDD"
          />
        </g>
      </g>
      <defs>
        <clipPath id="clip0_898_106">
          <rect
            width="43.6364"
            height="43.6364"
            fill="white"
            transform="translate(8.18188 8.18164)"
          />
        </clipPath>
        <clipPath id="clip1_898_106">
          <rect
            width="24.5455"
            height="42.2727"
            fill="white"
            transform="translate(17.7275 8.18164)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default ETHLogoSVG;
