import React, { useCallback, useEffect, useMemo, useState } from "react";

import clsx from "clsx";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import { paths } from "@navigation/routes";

import { isAuthSelector } from "@slices/AppSlice/selectors";
import {
  codeUserSelector,
  phoneUserSelector,
} from "@slices/UserSlice/selectors";

import { countries } from "@pages/Registr/Counties";

import DatePickerInput from "@components/DatePickerInput";
import Input from "@components/Input";
import LinkComponent from "@components/LinkComponent";
import Modal from "@components/Modal";
import Select from "@components/Select";

import Button from "@ui/Button";
import Typography from "@ui/Typography";

import { yupResolver } from "@hookform/resolvers/yup";
import { createAccountAC } from "@saga/authSaga/actions";
import InputAccountSVG from "@svg/input/account";
import InputAddressSVG from "@svg/input/address";
import InputCountrySVG from "@svg/input/country";
import InputDateSVG from "@svg/input/date";
import InputMailSVG from "@svg/input/mail";
import InputPersonSVG from "@svg/input/person";
import InputPhoneSVG from "@svg/input/phone";
import MessageSVG from "@svg/message";
import { createArrayMask } from "@utils/inputMasks";
import { inputMaxLength } from "@utils/inputMasks/constants";
import { getBirthdayNeed, getNormalPhone } from "@utils/utils";
import { schemaRegistr } from "@validation";

import style from "./Registr.module.scss";

const sectionRegistr = "registr";

interface IFromValues {
  name: string;
  date_birth_day: string;
  email: string;
  phone: string;
  country: string;
  address: string;
  wallet: string;
}

const RegistrPage = () => {
  const { t } = useTranslation();

  const dispatch = useDispatch();

  const navigate = useNavigate();

  const phone = useSelector(phoneUserSelector);
  const code = useSelector(codeUserSelector);
  const isAuth = useSelector(isAuthSelector);

  const {
    control,
    handleSubmit,
    formState: { errors },
    setValue,
    setError,
  } = useForm<IFromValues>({
    mode: "onChange",
    resolver: yupResolver(schemaRegistr),
    shouldFocusError: false,
    defaultValues: {
      phone: phone || "",
    },
  });

  const [isModalRegistrActive, setIsModalRegisterActive] =
    useState<boolean>(false);
  const [isModalReadyActive, setIsModalReadyActive] = useState<boolean>(false);

  const onSubmitHandler = (data: IFromValues) => {
    const { phone, date_birth_day, name, ...otherData } = data;

    const [last_name, first_name, middle_name] = name.split(" ");

    const sagaCreateAccountSuccess = () => {
      setIsModalRegisterActive(false);
      setIsModalReadyActive(true);
    };

    const sagaCreateAccountError = (errors: Record<string, string[]>) => {
      for (let error in errors) {
        const inputName =
          error === "last_name" ||
          error === "middle_name" ||
          error === "first_name"
            ? "name"
            : error;
        let message = errors[error][0];
        if (error === "wallet") {
          message = t(`${sectionRegistr}.uniqueWallet`);
        }
        setError(inputName as any, {
          type: "manual",
          message,
        });
      }
    };

    dispatch(
      createAccountAC({
        phone: getNormalPhone(phone),
        date_birth_day: getBirthdayNeed(date_birth_day),
        last_name,
        first_name,
        middle_name,
        ...otherData,
        setCreateAccountSuccess: sagaCreateAccountSuccess,
        setCreateAccountError: sagaCreateAccountError,
      })
    );
  };
  const getSortedCountries = useMemo(() => {
    return countries.ru.sort((a, b) => {
      if (a === "Россия" || a === "Russia") return -1;

      return a > b ? 1 : -1;
    });
  }, [countries]);

  const setCountryValue = useCallback(
    (value: string) => setValue("country", value),
    []
  );

  const onChangeDateHandler = useCallback(
    (value: string) => {
      setValue("date_birth_day", value);
    },
    [setValue]
  );

  useEffect(() => {
    isAuth && navigate(paths.profile);
    setIsModalRegisterActive(true);
  }, []);

  return (
    <>
      <Modal
        isActive={isModalRegistrActive}
        setIsActive={setIsModalRegisterActive}
        title={t(`${sectionRegistr}.title`)}
        type="big"
        className={style.modal}
        relativeMode
      >
        <form onSubmit={handleSubmit(onSubmitHandler)}>
          <Input
            className={style.modal__input}
            icon={<InputPersonSVG />}
            control={control}
            placeholder={t(`${sectionRegistr}.namePlaceholder`)}
            name="name"
            subscription={t(`${sectionRegistr}.nameSubscription`)}
            mask={createArrayMask("", inputMaxLength.name)}
          />
          <DatePickerInput
            onChange={onChangeDateHandler}
            input={{
              icon: <InputDateSVG />,
              control,
              placeholder: t(`${sectionRegistr}.birthdayPlaceholder`),
              name: "date_birth_day",
              mask: "99.99.9999",
            }}
          />
          <div className={style.modal__inputs}>
            <Input
              className={clsx(
                style.modal__input,
                style.modal__input_mini,
                style.modal__input_email
              )}
              icon={<InputMailSVG />}
              control={control}
              placeholder={t(`${sectionRegistr}.emailPlaceholder`)}
              name="email"
              mask={createArrayMask("", inputMaxLength.email)}
            />
            <Input
              className={clsx(style.modal__input, style.modal__input_mini)}
              icon={<InputPhoneSVG />}
              control={control}
              placeholder={t(`${sectionRegistr}.phonePlaceholder`)}
              mask="+7 999 999-99-99"
              name="phone"
              disabled
            />
          </div>
          <Select
            inputClassName={style.modal__input}
            icon={<InputCountrySVG />}
            control={control}
            placeholder={t(`${sectionRegistr}.countryPlaceholder`)}
            name="country"
            listOptions={getSortedCountries}
            setValue={setCountryValue}
            initialValue="Россия"
          />
          <Input
            className={style.modal__input}
            icon={<InputAddressSVG />}
            control={control}
            placeholder={t(`${sectionRegistr}.addressPlaceholder`)}
            name="address"
            mask={createArrayMask("", inputMaxLength.address)}
          />
          <Input
            className={style.modal__input}
            icon={<InputAccountSVG />}
            control={control}
            placeholder={t(`${sectionRegistr}.walletPlaceholder`)}
            name="wallet"
            subscription={t(`${sectionRegistr}.walletSubscription`)}
            mask={createArrayMask("", inputMaxLength.wallet)}
          />
          <Button
            className={style.modal__btn}
            disabled={!!Object.keys(errors).length}
          >
            <Typography type="sf-text-17">
              {t(`${sectionRegistr}.btn`)}
            </Typography>
          </Button>
        </form>
        <LinkComponent to={paths.login} className={style.modal__link}>
          <Typography type="sf-text-15">
            {t(`${sectionRegistr}.link`)}
          </Typography>
        </LinkComponent>
      </Modal>

      <Modal
        isActive={isModalReadyActive}
        setIsActive={setIsModalReadyActive}
        withLogo
        className={style.ready}
        type="medium"
        title={t(`${sectionRegistr}.readyTitle`)}
        beforeTitle={<MessageSVG className={style.ready__message} />}
      >
        <Typography className={style.ready__subtitle} type="sf-text-17">
          {t(`${sectionRegistr}.readySubtitle`)}
        </Typography>
        <Typography className={style.ready__email} type="sf-text-17">
          asds@mail.ru
        </Typography>
        <LinkComponent className={style.ready__link} to={paths.login}>
          <Typography type="sf-text-15">
            {t(`${sectionRegistr}.readyLink`)}
          </Typography>
        </LinkComponent>
        <Typography className={style.ready__support} type="sf-text-15">
          support@gtech.com
        </Typography>
      </Modal>
    </>
  );
};

export default RegistrPage;
