import i18n from "i18next";
import { initReactI18next } from "react-i18next";

const resources = {
  ru: {
    translation: {
      modals: {
        buy: {
          title: "Купить GTECH",
          text: "Укажите в окне ниже количество GTECH, которое вы хотите купить",
          input: "Количество GTECH",
          btn: "Отправить заявку",
          placeholder: "Количество GTECH",
        },
        sell: {
          title: "Продать GTECH",
          text: "Укажите в окне ниже количество GTECH, которое вы хотите продать",
          input: "Количество GTECH",
          btn: "Отправить заявку",
          or: "или",
          link: "перейти на DEX",
          placeholder: "Количество GTECH",
        },
        wallet: {
          title: "Изменить ETH кошелек",
          text: "Введите в окне ниже номер вашего нового ETH кошелька",
          input: "Новый номер ETH кошелька",
          btn: "Отправить заявку",
          placeholder: "Новый номер ETH кошелька",
        },
        changePassword: {
          title: "Изменить пароль",
          text: "",
          btn: "Изменить",
          placeholder_old_password: "Старый пароль",
          placeholder_new_password: "Новый пароль",
          placeholder_new_password_replay: "Повторите новый пароль",
        },
        ready: {
          title: "Готово!",
          text: "Спасибо, ваша заявка принята. Ожидайте письмо-инструкцию на",
          btn: "Ок",
        },
        info: {
          title: "Рынки больше не боятся Omicron",
          text:
            "Промышленный индекс Dow добавил более 300 пунктов, показав в понедельник свой лучший результат начиная с марта. Фьючерсы всех трех основных американских индексов показывают на рост при открытии более чем на 1%. Бурный рост накануне продемонстрировали акции сектора перевозок, поскольку страхи по поводу будущих локдаунов стали менее вероятными.\n" +
            " \n" +
            " После прочной сессии в Азии мировые фондовые рынки были выше в Европе. Шанхайский Компонентный индекс вырос на 0,2% до 3,595. Nikkei 225 в Токио вырос на 1,9% до 28455. Гонконгский hang Seng увеличился на 2,6% до 23983.",
        },
        turnOff2FA: {
          title: "Внимание!",
          text: "2FA (Двухфакторная аутентификация) обеспечивает дополнительный способ защиты при входе в личный кабинет. Если 2FA выключена, то злоумышленник, получив доступ к вашему основному паролю, сможет несанкционированно войти в ваш личный кабинет.",
          btn: "Выключить 2FA",
        },
        turnOn2FA: {
          title: "Защити свою учетную запись",
          text:
            "1. 2FA (Двухфакторная аутентификация) обеспечивает дополнительный способ защиты при входе в личный кабинет.\n\n" +
            "2. Если 2FA включена, то при входе личный кабинет необходимо будет ввести дополнительный одноразовый код, который будет направлен на телефонный номер, указанный при регистрации.\n\n" +
            "3. Включение 2FA означает, что даже если злоумышленник получит доступ к вашему основному паролю, он не сможет войти в личный кабинет, если у него не будет доступа к вашему телефону.",
          placeholder: "Проверочный код",
          btn: "Включить 2FA",
        },
      },
      lk: {
        main: {
          title: "Кабинет",
          name: "ETH кошелек",
        },
        rate: {
          btnBuy: "Купить GTECH",
          btnSell: "Продать GTECH",
          titleCoinETH: "(ETH)",
          coinETH: "ETH",
          CoinGTECH: "GTECH",
        },
        info: {
          promptTitle: "Прогноз доходности на конец периода",
          powerTitle: "Общая мощность проекта",
        },
        yield: {
          title: "Доходность пула",
          tabs: {
            week: "Неделя",
            month: "Месяц",
            all: "Все время",
          },
        },
        payout: {
          title: "Выплаты",
          empty: {
            title: "Выплат пока не было",
            subtitle: "Данные появятся после текущего периода",
          },
          tabs: {
            halfYear: "Полгода",
            year: "год",
            all: "Все время",
          },
          table: {
            wallet: "ТХ",
            season: "Период",
            date: "Время",
            payment: "Выплата в ETH",
            usd: "Сумма в USD",
            volume: "На объем",
            paymentBody: "Выплата:",
          },
        },
        profit: {
          title: "Калькулятор доходности",
          subtitle: "Вы заработаете ETH на конец периода",
          buyBtn: "Купить GTECH",
          sellBtn: "Продать GTECH",
          mouseTitle: "Перемещайте слайдер покупки GTECH",
          moneyTitle: "Доходность 43% годовых, в USD",
        },
        news: {
          title: "Новости",
        },
      },
      profile: {
        info: {
          verifySuccess: "KYC пройдена",
          exitBtn: "Выйти из профиля",
          changePswrd: "Изменить пароль",
          buyBtn: "Купить GTECH",
          sellBtn: "Продать GTECH",
        },
        account: {
          title: "Кошелек",
          inputPlaceholder: "ETH кошелек",
          emptyInputPlaceholder: "Добавьте ETH кошелек",
          btn: "Изменить адрес",
          emptyBtn: "Добавить кошелек",
          text: "Адрес Ethereum ETH кошелька (ERC 20) для начисления дохода и хранения GTECH",
        },
        history: {
          title: "История операций",
          exportBtn: "Экспортировать",
          table: {
            wallet: "ТХ",
            date: "Время",
            sum: "Сумма",
          },
          empty: {
            title: "Операций пока не было",
            subtitle: "Данные появятся после текущего периода",
          },
        },
        phone: {
          title: "2FA-авторизация",
          subtitle: "По номеру телефона",
          onBtn: "Включена",
          offBtn: "Отключена",
        },
      },
      verify: {
        title: "Регистрация",
        placeholderPhone: "Номер телефона",
        placeholderCode: "Код из SMS",
        btn: "Получить код в SMS",
        quest: "Уже есть аккаунт?",
        link: "Войти",
        promptText: "Повторный код можно запросить через",
        promptSec: "сек",
        politic: {
          agree: "Я принимаю",
          condition: "Условия соглашения",
          and: "и",
          text: "Политику конфиденциальности",
        },
        sendAgain: "Отправить повторный код",
      },
      create: {
        title: "Новый аккаунт",
        loginPlaceholder: "Придумайте логин",
        passwordPlaceholder: "Придумайте пароль",
        passwordRepeatPlaceholder: "Повторите пароль",
        btn: "Создать аккаунт",
        link: "Войти в другой аккаунт",
      },
      login: {
        title: "Вход",
        loginPlaceholder: "Введите логин",
        passwordPlaceholder: "Пароль",
        btn: "Войти",
        remember: "Я не помню пароль",
        quest: "Впервые здесь?",
        link: "Зарегистрироваться",
        verifyTitle: "2FA-проверка",
        verifySubtitle:
          "Введите код 2FA аутентификации, направленный на ваш номер телефона",
        verifyPlaceholder: "Проверочный код",
        verifyBtn: "Подтвердить",
      },
      forgot: {
        title: "Востановления пароля",
        phonePlaceholder: "Номер телефона",
        btn: "Отправить новый пароль",
        toLogin: "Войти",
        toRegister: "Зарегистрироваться",

        success: {
          title:
            "Новый временный пароль направлен на указанный номер телефона.\nНе забудьте изменить временный пароль в своем профиле",
          btn: "Ок!",
        },
      },
      registr: {
        title: "Регистрационная форма",
        namePlaceholder: "Фамилия, имя и отчество *",
        nameSubscription: "Укажите фамилию, имя и отчество через пробел",
        birthdayPlaceholder: "Дата рождения *",
        emailPlaceholder: "E-mail *",
        phonePlaceholder: "Номер телефона *",
        countryPlaceholder: "Страна *",
        addressPlaceholder: "Адрес проживания *",
        walletPlaceholder: "ETH кошелек",
        walletSubscription:
          "Адрес Ethereum ETH кошелька (ERC 20) для начисления дохода и хранения GTECH. Если кошелька пока нет, то его можно указать позже в профиле.",
        btn: "Активировать аккаунт",
        link: "Войти в другой аккаунт",
        readyTitle: "Готово!",
        readySubtitle:
          "Спасибо, ваша заявка на активацию аккаунта принята к рассмотрению, ожидайте письмо-подтверждение на",
        readyLink: "Войти в другой аккаунт",
        uniqueWallet:
          "Пользователь с таким кошельком уже существует, введите другой номер кошелька",
      },
      errors: {
        requireGeneral: "Поле обязательно для заполнения",
        phone: "Поле должно соотвествовать +7 999 999-99-99",
        min: "Поле должно содержать минимум {{count}} символов",
        max: "Поле может содержать максимум {{count}} символов",
        login:
          'Поле может содержать буквы латинского алфавита и символы "-", "_"',
        name: 'Поле может содержать буквы латинского и русского алфавитов и "-", пример Иванов Иван Иванович',
        birthday: "Поле должно быть вида: дд.мм.гггг",
        email: "Неверный формат email",
        country: 'Поле может содержать буквы русского алфавита, "-", пробел',
        address:
          'Поле может содержать буквы латинского, русского алфавитов, "-", пробел, цифры ',
        wallet: "Неверный формат кошелька",
        server: "Произошла ошибка, попробуйе позднее",
        codeIsSent: "Повторно запросить код можно через 1 минуту",
        codeInvalid: "Неверный код",
        newPasswordInvalid:
          "Поля новый пароль и повторите новый пароль не совпадают",
        amount: "Поле может содержать целое или дробное число",
      },
      messages: {
        gtechBitAccepted: "Ваша заявка принята",
      },
    },
  },
  en: {},
};

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    lng: "ru",
    interpolation: {
      escapeValue: false, // react already safes from xss
    },
  });

export default i18n;
