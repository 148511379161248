import React, { FC } from "react";

import { useTranslation } from "react-i18next";

import Modal from "@components/Modal";

import Button from "@ui/Button";
import Typography from "@ui/Typography";

import MessageSVG from "@svg/message";

import style from "./ModalReady.module.scss";

interface IProps {
  isActive: boolean;
  setIsActive: (value: boolean) => void;
  email?: string | null;
}

const ModalReady: FC<IProps> = ({ isActive, setIsActive, email }) => {
  const { t } = useTranslation();

  const section = "modals.ready";

  const onClickHandler = () => setIsActive(false);

  return (
    <Modal
      isActive={isActive}
      setIsActive={setIsActive}
      withDarkBg
      isCenterByVertical
      withClose
      type="medium"
    >
      <MessageSVG className={style.modal__icon} />
      <Typography type="montserrat-28" className={style.modal__title}>
        {t(`${section}.title`)}
      </Typography>
      <Typography type="sf-text-17" className={style.modal__text}>
        {t(`${section}.text`)} <div className={style.modal__email}>{email}</div>
      </Typography>
      <Button className={style.modal__btn} onClick={onClickHandler}>
        <Typography type="sf-text-17">{t(`${section}.btn`)}</Typography>
      </Button>
    </Modal>
  );
};

export default ModalReady;
