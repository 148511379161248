import React, { FC } from "react";
import { ISvgProps } from "./types";

const LogoSVG: FC<ISvgProps> = ({ className }) => {
  return (
    <svg
      width="84"
      height="28"
      viewBox="0 0 84 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <g clipPath="url(#clip0_845_850)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M20.1113 15.8152V27.5998H15.4072V20.4463C17.3893 19.4053 19.0278 17.7905 20.1113 15.8152ZM21.3534 7.99446H27.4053V12.3853H21.3534C21.4856 11.678 21.5648 10.9439 21.5648 10.1832C21.5516 9.46252 21.4856 8.71514 21.3534 7.99446ZM33.4705 12.3319V15.535H43.9093V19.7523H33.4705V23.2623H44.6625V27.5998H28.7664V7.99446H44.6361V12.3319H33.4705ZM55.8546 7.60742C59.5941 7.60742 62.8579 9.66271 64.6021 12.7056L60.7569 14.9477C59.7791 13.2395 57.9424 12.0917 55.8546 12.0917C52.7229 12.0917 50.1991 14.6541 50.1991 17.8038C50.1991 20.9668 52.7361 23.5159 55.8546 23.5159C57.9556 23.5159 59.7791 22.3681 60.7569 20.6599L64.6021 22.902C62.8579 25.9449 59.5941 28.0002 55.8546 28.0002C50.2784 28.0002 45.7593 23.4358 45.7593 17.8038C45.7593 12.1718 50.2784 7.60742 55.8546 7.60742ZM79.2958 20.0993H70.6805V27.6131H65.9764V7.99446H70.6805V15.6951H79.2958V7.99446H84V27.5998H79.2958V20.0993Z"
          fill="white"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M10.0953 0C13.8348 0 17.0986 2.05529 18.8428 5.09819L14.9976 7.34032C14.0198 5.63203 12.1831 4.48427 10.0953 4.48427C6.96366 4.48427 4.43983 7.04671 4.43983 10.1964C4.43983 13.3594 6.97688 15.9085 10.0953 15.9085C12.4474 15.9085 14.4691 14.4538 15.328 12.3851H8.12647V7.99428H15.328H18.8428H19.9528C20.1114 8.70162 20.1907 9.43565 20.1907 10.1964C20.1907 15.8284 15.6715 20.3928 10.0953 20.3928C4.51911 20.3928 0 15.8284 0 10.1964C0 4.56435 4.51911 0 10.0953 0Z"
          fill="#33CC66"
        />
      </g>
      <defs>
        <clipPath id="clip0_845_850">
          <rect width="84" height="28" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default LogoSVG;
