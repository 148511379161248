import { AxiosResponse } from "axios";

import { instance } from "@api/settings";

import { IGetNewsResponse } from "./types";

const NewsAPI = {
  async getNews(): Promise<AxiosResponse<IGetNewsResponse[]>> {
    return new Promise(async (resolve, reject) => {
      try {
        const response: AxiosResponse<IGetNewsResponse[]> = await instance.get(
          `news/get-news/`
        );
        resolve(response);
      } catch (e) {
        reject(e);
      }
    });
  },
};

export default NewsAPI;
