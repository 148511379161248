export const inputMaxLength = {
  login: 15,
  password: 32,
  wallet: 64,
  email: 256,
  address: 255,
  country: 100,
  name: 50,
  GTECHAmount: 100,
  sms: 6,
};
