import React, { FC } from "react";
import { ISvgProps } from "../types";

const InputPhoneSVG: FC<ISvgProps> = ({ className }) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className || ""}
    >
      <path
        d="M8.92041 20.7896H15.0713C16.4243 20.7896 17.3291 19.9263 17.3291 18.6313V5.375C17.3291 4.08008 16.4243 3.2168 15.0713 3.2168H8.92041C7.56738 3.2168 6.6626 4.08008 6.6626 5.375V18.6313C6.6626 19.9263 7.56738 20.7896 8.92041 20.7896ZM9.09473 19.4531C8.38916 19.4531 7.99902 19.0796 7.99902 18.4155V5.59082C7.99902 4.92676 8.38916 4.55322 9.09473 4.55322H9.42676V4.93506C9.42676 5.28369 9.65088 5.51611 9.99951 5.51611H14.0088C14.3491 5.51611 14.5732 5.28369 14.5732 4.93506V4.55322H14.9053C15.6025 4.55322 15.9927 4.92676 15.9927 5.59082V18.4155C15.9927 19.0796 15.6025 19.4531 14.9053 19.4531H9.09473ZM9.81689 18.8472H14.1914C14.4155 18.8472 14.5732 18.6895 14.5732 18.457C14.5732 18.2246 14.4155 18.0752 14.1914 18.0752H9.81689C9.59277 18.0752 9.42676 18.2246 9.42676 18.457C9.42676 18.6895 9.59277 18.8472 9.81689 18.8472Z"
        fill="#EBEBF5"
        fillOpacity="0.18"
      />
    </svg>
  );
};

export default InputPhoneSVG;
