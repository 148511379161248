import React, { FC } from "react";
import { ISvgProps } from "../types";

const InputEditSVG: FC<ISvgProps> = ({ className }) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className || ""}
    >
      <path
        d="M17.8107 8.00617L18.7487 7.07648C19.2135 6.61164 19.2384 6.08869 18.8068 5.65705L18.4582 5.30012C18.0265 4.86848 17.5036 4.90998 17.0387 5.36652L16.0924 6.29621L17.8107 8.00617ZM7.42642 18.3738L16.964 8.83625L15.2624 7.13459L5.72476 16.6639L4.90299 18.6893C4.79508 18.9715 5.0856 19.262 5.35123 19.1624L7.42642 18.3738Z"
        fill="white"
      />
    </svg>
  );
};

export default InputEditSVG;
