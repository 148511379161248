import React, { FC } from "react";

import clsx from "clsx";
import { Link, LinkProps } from "react-router-dom";

import style from "./LinkComponent.module.scss";

interface IProps extends LinkProps {
  className?: string;
  component?: "div" | "span";
  onClick?(): void;
}

const LinkComponent: FC<IProps> = ({
  className,
  children,
  onClick,
  component: Component,
  ...otherProps
}) => {
  return Component ? (
    <Component
      {...(onClick && { onClick: onClick })}
      className={clsx(style.link, {
        [className || ""]: className,
      })}
    >
      {children}
    </Component>
  ) : (
    <Link
      {...(onClick && { onClick: onClick })}
      {...otherProps}
      className={clsx(style.link, {
        [className || ""]: className,
      })}
    >
      {children}
    </Link>
  );
};

export default LinkComponent;
