import { AxiosResponse } from "axios";

import { IGetConstantsResponse } from "@api/ConstantsAPI/types";
import { instance } from "@api/settings";

const ConstantsAPI = {
  getConstants: (): Promise<AxiosResponse<IGetConstantsResponse>> => {
    return new Promise(async (resolve, reject) => {
      try {
        const response: AxiosResponse<IGetConstantsResponse> =
          await instance.get(`get-constants/`);
        resolve(response);
      } catch (e) {
        reject(e);
      }
    });
  },
};

export default ConstantsAPI;
