import React, { FC } from "react";
import { ISvgProps } from "../types";

const InputPersonSVG: FC<ISvgProps> = ({ className }) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className || ""}
    >
      <path
        d="M12 11.9238C13.7764 11.9238 15.3203 10.3301 15.3203 8.25488C15.3203 6.20459 13.7764 4.68555 12 4.68555C10.2236 4.68555 8.67969 6.23779 8.67969 8.27148C8.67969 10.3301 10.2153 11.9238 12 11.9238ZM6.77881 19.6104H17.2046C18.5161 19.6104 18.981 19.2368 18.981 18.5063C18.981 16.3647 16.2998 13.4097 11.9917 13.4097C7.69189 13.4097 5.01074 16.3647 5.01074 18.5063C5.01074 19.2368 5.47559 19.6104 6.77881 19.6104Z"
        fill="#EBEBF5"
        fillOpacity="0.18"
      />
    </svg>
  );
};

export default InputPersonSVG;
