import React, { FC } from "react";
import { ISvgProps } from "./types";

const TimeBigSVG: FC<ISvgProps> = ({ className }) => {
  return (
    <svg
      width="80"
      height="80"
      viewBox="0 0 80 80"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <g opacity="0.3">
        <path
          opacity="0.4"
          d="M40.0004 77.7782C45.1609 77.7782 50.0128 76.7906 54.556 74.8153C59.1239 72.8399 63.1486 70.1115 66.6301 66.6301C70.1115 63.1486 72.8399 59.1362 74.8153 54.593C76.7906 50.0251 77.7782 45.1609 77.7782 40.0004C77.7782 34.8399 76.7906 29.9881 74.8153 25.4449C72.8399 20.877 70.1115 16.8523 66.6301 13.3708C63.1486 9.88932 59.1239 7.16093 54.556 5.18562C49.9881 3.21031 45.1239 2.22266 39.9634 2.22266C34.8029 2.22266 29.9387 3.21031 25.3708 5.18562C20.8276 7.16093 16.8152 9.88932 13.3338 13.3708C9.87698 16.8523 7.16093 20.877 5.18562 25.4449C3.21031 29.9881 2.22266 34.8399 2.22266 40.0004C2.22266 45.1609 3.21031 50.0251 5.18562 54.593C7.16093 59.1362 9.88932 63.1486 13.3708 66.6301C16.8523 70.1115 20.8646 72.8399 25.4078 74.8153C29.9757 76.7906 34.8399 77.7782 40.0004 77.7782ZM40.0004 71.4819C35.6301 71.4819 31.5436 70.6671 27.7412 69.0375C23.9387 67.4078 20.593 65.1609 17.7041 62.2967C14.8399 59.4078 12.593 56.0622 10.9634 52.2597C9.35846 48.4572 8.55599 44.3708 8.55599 40.0004C8.55599 35.6301 9.35846 31.5436 10.9634 27.7412C12.593 23.9387 14.8399 20.593 17.7041 17.7041C20.5683 14.8152 23.9017 12.5683 27.7041 10.9634C31.5066 9.33377 35.593 8.51895 39.9634 8.51895C44.3338 8.51895 48.4202 9.33377 52.2227 10.9634C56.0251 12.5683 59.3708 14.8152 62.2597 17.7041C65.1486 20.593 67.4078 23.9387 69.0375 27.7412C70.6671 31.5436 71.4819 35.6301 71.4819 40.0004C71.4819 44.3708 70.6671 48.4572 69.0375 52.2597C67.4325 56.0622 65.1856 59.4078 62.2967 62.2967C59.4325 65.1609 56.0869 67.4078 52.2597 69.0375C48.4572 70.6671 44.3708 71.4819 40.0004 71.4819Z"
          fill="white"
        />
        <path
          d="M39.963 43.9998H20.6297C19.889 43.9998 19.2717 43.7529 18.7778 43.259C18.284 42.7652 18.0371 42.1479 18.0371 41.4072C18.0371 40.6911 18.284 40.0862 18.7778 39.5924C19.2717 39.0985 19.889 38.8516 20.6297 38.8516H37.4075V16.4442C37.4075 15.7282 37.6544 15.1232 38.1482 14.6294C38.642 14.1356 39.247 13.8887 39.963 13.8887C40.7038 13.8887 41.3211 14.1356 41.8149 14.6294C42.3087 15.1232 42.5556 15.7282 42.5556 16.4442V41.4072C42.5556 42.1479 42.3087 42.7652 41.8149 43.259C41.3211 43.7529 40.7038 43.9998 39.963 43.9998Z"
          fill="white"
        />
      </g>
    </svg>
  );
};

export default TimeBigSVG;
