import React, { FC } from "react";

import clsx from "clsx";
import { Link } from "react-router-dom";

import { paths } from "@navigation/routes";

import Container from "@ui/Container";
import Typography from "@ui/Typography";

import TelegramSVG from "@svg/telegram";

import style from "./Footer.module.scss";

interface IProps {
  isHighContext?: boolean;
}

const Footer: FC<IProps> = ({ isHighContext }) => {
  return (
    <footer
      className={clsx(style.footer, {
        [style.footer_high]: isHighContext,
      })}
    >
      <Container>
        <div className={style.footer__content}>
          <div className={style.footer__left}>
            <Typography
              type="sf-text-12"
              className={style.footer__subscription}
            >
              2021 © GTECH
            </Typography>
            <Link to={paths.lk}>
              <Typography type="sf-text-12" className={style.footer__link}>
                Политика конфиденциальности
              </Typography>
            </Link>
          </div>

          <Typography type="sf-text-16" className={style.footer__email}>
            info@gtechcoin.com
          </Typography>

          <div className={style.footer__right}>
            <a href="#" className={style.telegram}>
              <TelegramSVG />
              <Typography type="sf-text-16" className={style.telegram__text}>
                Telegram-канал GTECH
              </Typography>
            </a>
          </div>
        </div>
      </Container>
    </footer>
  );
};

export default Footer;
