import React, { FC } from "react";
import { ISvgProps } from "./types";

const ETHLogoDotsSVG: FC<ISvgProps> = ({ className }) => {
  return (
    <svg
      width="80"
      height="80"
      viewBox="0 0 80 80"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <circle
        opacity="0.1"
        cx="40"
        cy="40"
        r="39"
        stroke="white"
        strokeWidth="2"
        strokeDasharray="4 4"
      />
      <g clipPath="url(#clip0_944_1606)">
        <g clipPath="url(#clip1_944_1606)">
          <path
            opacity="0.8"
            d="M39.995 10.9082L39.6377 12.1923V49.4528L39.995 49.8299L56.3538 39.6064L39.995 10.9082Z"
            fill="white"
          />
          <path
            d="M39.9955 10.9082L23.6367 39.6063L39.9955 49.8301V31.7449V10.9082Z"
            fill="white"
          />
          <path
            opacity="0.8"
            d="M39.9953 53.1052L39.7939 53.3647V66.6377L39.9953 67.2596L56.3638 42.8867L39.9953 53.1052Z"
            fill="white"
          />
          <path
            d="M39.9955 67.2596V53.1052L23.6367 42.8867L39.9955 67.2596Z"
            fill="white"
          />
          <path
            opacity="0.5"
            d="M39.9951 49.829L56.3536 39.6056L39.9951 31.7441V49.829Z"
            fill="#7E7E7E"
          />
          <path
            d="M23.6367 39.6056L39.9952 49.829V31.7441L23.6367 39.6056Z"
            fill="#DDDDDD"
          />
        </g>
      </g>
      <defs>
        <clipPath id="clip0_944_1606">
          <rect
            width="58.1818"
            height="58.1818"
            fill="white"
            transform="translate(10.9092 10.9082)"
          />
        </clipPath>
        <clipPath id="clip1_944_1606">
          <rect
            width="32.7273"
            height="56.3636"
            fill="white"
            transform="translate(23.6367 10.9082)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default ETHLogoDotsSVG;
