import React, { FC, memo, useCallback, useState } from "react";

import clsx from "clsx";
import { useDispatch } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";

import { paths } from "@navigation/routes";

import Container from "@ui/Container";
import Typography from "@ui/Typography";

import { logoutAC } from "@saga/authSaga/actions";
import EmptyPersonSVG from "@svg/emptyPerson";
import LangApexSVG from "@svg/langApex";
import LogoSVG from "@svg/logo";
import MiniArrowSVG from "@svg/miniArrow";

import style from "./Header.module.scss";

type LanguagesType = "RU" | "EN";
type LanguagesTextType = "Русский" | "English";

interface ILanguagesObj {
  name: LanguagesType;
  text: LanguagesTextType;
}

const languages: ILanguagesObj[] = [
  { name: "RU", text: "Русский" },
  { name: "EN", text: "English" },
];

interface IProps {
  login: string | null;
}

const Header: FC<IProps> = ({ login }) => {
  const navigate = useNavigate();

  const { pathname } = useLocation();

  const dispatch = useDispatch();

  const [isOpenLang, setIsOpenLang] = useState<boolean>(false);
  const [isOpenSelect, setIsOpenSelect] = useState<boolean>(false);
  const [langActive, setLangActive] = useState<LanguagesType>("RU");

  const changeIsOpen = (value = !isOpenLang) => setIsOpenLang(value);

  const changeIsOpenSelect = () => setIsOpenSelect(!isOpenSelect);

  const changeLang = (lang: LanguagesType) => {
    setLangActive(lang);
    changeIsOpen();
  };

  const onProfileClickHandler = useCallback(() => {
    navigate(paths.profile);
    setIsOpenSelect(false);
  }, [navigate, setIsOpenSelect]);

  const sagaLogoutSuccess = () => {
    navigate(paths.login);
    setIsOpenSelect(false);
  };

  const onLogoutClickHandler = useCallback(
    () => dispatch(logoutAC({ setLogoutSuccess: sagaLogoutSuccess })),
    [dispatch, sagaLogoutSuccess]
  );

  return (
    <header className={style.header}>
      <Container>
        <div className={style.header__content}>
          <Link to={paths.lk} className={style["header__logo-link"]}>
            <LogoSVG className={style.header__logo} />
          </Link>
          <div className={style.header__right}>
            {pathname !== paths.lk && (
              <Typography type="roboto-15" className={style.header__lk}>
                <Link to={paths.lk} className={style["header__lk-link"]}>
                  Кабинет
                </Link>
              </Typography>
            )}

            <div className={style.header__profile}>
              <div
                className={style["header__lang-value"]}
                onClick={changeIsOpenSelect}
              >
                <EmptyPersonSVG />
                <Typography
                  type="roboto-15"
                  className={clsx(
                    style["header__profile-name"],
                    style["header__lang-text"],
                    {
                      [style["header__lang-text_active"]]: isOpenSelect,
                    }
                  )}
                >
                  {login}
                </Typography>
                <MiniArrowSVG
                  className={clsx(
                    style["header__lang-arrow"],
                    style["select-arrow"],
                    {
                      [style["header__lang-arrow_active"]]: isOpenSelect,
                    }
                  )}
                />
              </div>
              <div
                className={clsx(style.lang, {
                  [style.lang_active]: isOpenSelect,
                  [style.select_active]: isOpenSelect,
                })}
              >
                <LangApexSVG className={style.lang__apex} />
                <Typography
                  type="roboto-15"
                  component="button"
                  className={clsx(style.lang__elem)}
                  onClick={onProfileClickHandler}
                >
                  Профиль
                </Typography>
                <Typography
                  type="roboto-15"
                  component="button"
                  className={clsx(style.lang__elem)}
                  onClick={onLogoutClickHandler}
                >
                  Выйти
                </Typography>
              </div>
            </div>
            <div className={style.header__lang}>
              <div
                className={style["header__lang-value"]}
                onClick={() => changeIsOpen()}
              >
                <Typography
                  type="roboto-15"
                  className={clsx(style["header__lang-text"], {
                    [style["header__lang-text_active"]]: isOpenLang,
                  })}
                >
                  {langActive}
                </Typography>
                <MiniArrowSVG
                  className={clsx(style["header__lang-arrow"], {
                    [style["header__lang-arrow_active"]]: isOpenLang,
                  })}
                />
              </div>
              <div
                className={clsx(style.lang, {
                  [style.lang_active]: isOpenLang,
                })}
              >
                <LangApexSVG className={style.lang__apex} />
                {languages.map(({ name, text }, index) => (
                  <Typography
                    key={index}
                    type="roboto-15"
                    component="button"
                    className={clsx(style.lang__elem, {
                      [style.lang__elem_active]: langActive === name,
                    })}
                    onClick={() => changeLang(name)}
                  >
                    {text}
                  </Typography>
                ))}
              </div>
            </div>
          </div>
        </div>
      </Container>
    </header>
  );
};

export default memo(Header);
