import React, { FC, ReactElement, memo } from "react";

import clsx from "clsx";

import Spinner from "@components/Spinner";

import Typography from "@ui/Typography";
import Value from "@ui/Value";

import style from "./CoinCard.module.scss";

interface IProps {
  className?: string;
  logo: ReactElement;
  titleCoin: string;
  balanceCoin?: string | number | null;
  coin?: string | null;
  balanceUSD?: string | number | null;
  course?: string | number | null;
  buttons?: {
    buy: ReactElement;
    sell: ReactElement;
  };
  imgBg?: ReactElement;
  isLoading?: boolean;
}

const CoinCard: FC<IProps> = ({
  className,
  logo,
  titleCoin,
  balanceCoin,
  coin,
  balanceUSD,
  buttons,
  course,
  imgBg,
  isLoading,
}) => {
  return (
    <div
      className={clsx(style.card, className, {
        ["empty_relative"]: isLoading,
      })}
    >
      <div className={style.card__bg} />
      {imgBg}
      {!isLoading ? (
        <>
          <div className={style.card__content}>
            <div className={style.card__left}>
              <div className={style.card__logo}>{logo}</div>
              <Typography type="roboto-17" className={style.card__title}>
                Текущий баланс {titleCoin}
              </Typography>
              <Value
                numb={balanceCoin}
                item={coin}
                className={style.card__account}
              />
              <Typography type="roboto-20" className={style.card__money}>
                {balanceUSD} USD
              </Typography>
            </div>
            <div className={style.card__right}>
              {buttons && (
                <div className={style.card__buttons}>
                  {buttons.buy}
                  {buttons.sell}
                </div>
              )}
            </div>
          </div>
          <Typography type="roboto-15" className={style.card__course}>
            1 {coin} = {course} USD
          </Typography>
        </>
      ) : (
        <Spinner />
      )}
    </div>
  );
};

export default memo(CoinCard);
