import { rejects } from "assert";
import { AxiosResponse } from "axios";

import { instance } from "@api/settings";

import {
  IChange2FAParams,
  IChange2FAResponse,
  IChangePasswordParams,
  IChangePasswordResponse,
  ICheckCodeParams,
  ICheckCodeResponse,
  ICreateAccountParams,
  ICreateAccountResponse,
  ICreateUserParams,
  ICreateUserResponse,
  IGetCodeParams,
  IGetCodeResponse,
  IGetUserInfoResponse,
  ILoginUserParams,
  ILoginUserResponse,
  IForgotPasswordParams,
} from "./types";

const AuthAPI = {
  getCode: (data: IGetCodeParams): Promise<AxiosResponse<IGetCodeResponse>> => {
    return new Promise(async (resolve, reject) => {
      try {
        const response: AxiosResponse<IGetCodeResponse> = await instance.post(
          `2fa/check/`,
          data
        );
        resolve(response);
      } catch (e) {
        reject(e);
      }
    });
  },

  createUser: (
    data: ICreateUserParams
  ): Promise<AxiosResponse<ICreateUserResponse>> => {
    return new Promise(async (resolve, reject) => {
      try {
        const response: AxiosResponse<ICreateUserResponse> =
          await instance.post(`user/create/`, data);
        resolve(response);
      } catch (e) {
        reject(e);
      }
    });
  },

  loginUser: (
    data: ILoginUserParams
  ): Promise<AxiosResponse<ILoginUserResponse>> => {
    return new Promise(async (resolve, reject) => {
      try {
        const response: AxiosResponse<ILoginUserResponse> = await instance.post(
          `user/auth/`,
          data
        );
        resolve(response);
      } catch (e) {
        reject(e);
      }
    });
  },

  createAccount: (
    data: ICreateAccountParams
  ): Promise<AxiosResponse<ICreateAccountResponse>> => {
    return new Promise(async (resolve, reject) => {
      try {
        const response: AxiosResponse<ICreateAccountResponse> =
          await instance.post(`user/create/kyc/`, data);
        resolve(response);
      } catch (e) {
        reject(e);
      }
    });
  },

  checkCode: (
    data: ICheckCodeParams
  ): Promise<AxiosResponse<ICheckCodeResponse>> => {
    return new Promise(async (resolve, reject) => {
      try {
        const response: AxiosResponse<ICheckCodeResponse> = await instance.post(
          `2fa/check/`,
          data
        );
        resolve(response);
      } catch (e) {
        reject(e);
      }
    });
  },

  change2FA: (
    data: IChange2FAParams
  ): Promise<AxiosResponse<IChange2FAResponse>> => {
    return new Promise(async (resolve, reject) => {
      try {
        const response: AxiosResponse<IChange2FAResponse> = await instance.put(
          `2fa/change/`,
          data
        );
        resolve(response);
      } catch (e) {
        reject(e);
      }
    });
  },

  changePassword: (
    data: IChangePasswordParams
  ): Promise<AxiosResponse<IChangePasswordResponse>> => {
    return new Promise(async (resolve, reject) => {
      try {
        const response: AxiosResponse<IChangePasswordResponse> =
          await instance.put(`user/password/`, data);
        resolve(response);
      } catch (e) {
        reject(e);
      }
    });
  },

  forgotPassword: (
    data: IForgotPasswordParams
  ): Promise<AxiosResponse<IChangePasswordResponse>> => {
    return new Promise(async (resolve, reject) => {
      try {
        const response: AxiosResponse<IChangePasswordResponse> =
          await instance.post(`user/forgot/password/`, data);
        resolve(response);
      } catch (e) {
        reject(e);
      }
    });
  },

  getUserInfo: (): Promise<AxiosResponse<IGetUserInfoResponse>> => {
    return new Promise(async (resolve, reject) => {
      try {
        const response: AxiosResponse<IGetUserInfoResponse> =
          await instance.get(`user/user/`);
        resolve(response);
      } catch (e) {
        reject(e);
      }
    });
  },

  //todo delete confirm method
  confirm: (): Promise<AxiosResponse<{ is_kyc_confirmed: boolean }>> => {
    return new Promise(async (resolve, reject) => {
      try {
        const response: AxiosResponse<{ is_kyc_confirmed: boolean }> =
          await instance.put("user/change-kyc-confirmed/", {
            is_kyc_confirmed: true,
          });
        resolve(response);
      } catch (e) {
        reject(e);
      }
    });
  },
};

export default AuthAPI;
