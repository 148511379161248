import React, { memo, useCallback, useEffect, useState } from "react";

import clsx from "clsx";
import { Control, FieldValues, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Switch from "react-switch";

import { paths } from "@navigation/routes";

import {
  ETHWalletSelector,
  profileUserSelector,
  transactionsSelector,
  twoFAAuthSelector,
} from "@slices/UserSlice/selectors";

import Input from "@components/Input";
import Modal from "@components/Modal";
import ModalReady from "@components/Modals/ModalReady";
import ModalShop from "@components/Modals/ModalShop";
import Spinner from "@components/Spinner";

import BlockWithIcon from "@ui/BlockWithIcon";
import Button from "@ui/Button";
import Container from "@ui/Container";
import EmptyContent from "@ui/EmptyContent";
import Title from "@ui/Title";
import Typography from "@ui/Typography";
import Window from "@ui/Window";

import { changeWalletAC, exchangeGTECHAC } from "@saga/accountSaga/actions";
import {
  change2FAAC,
  changePasswordAC,
  checkCodeAC,
  getCodeAC,
  logoutAC,
} from "@saga/authSaga/actions";
import EmptyPersonSVG from "@svg/emptyPerson";
import InputAccountSVG from "@svg/input/account";
import InputEditSVG from "@svg/input/edit";
import InputGTECHSVG from "@svg/input/gtech";
import InputPasswordSVG from "@svg/input/password";
import TimeBigSVG from "@svg/timeBig";
import TitleShieldSVG from "@svg/title/shield";
import TitleTimeSVG from "@svg/title/time";
import { createArrayMask } from "@utils/inputMasks";
import { inputMaxLength } from "@utils/inputMasks/constants";
import { getOnlyNumbersAndDots } from "@utils/utils";
import { schemaProfileETHWalletYup } from "@validation";

import style from "./Profile.module.scss";

const sectionProfile = "profile";
const sectionInfo = `${sectionProfile}.info`;
const sectionAccount = `${sectionProfile}.account`;
const sectionHistory = `${sectionProfile}.history`;
const sectionPhone = `${sectionProfile}.phone`;
const sectionModal = "modals";

interface IModalsState {
  buy: boolean;
  sell: boolean;
  finish: boolean;
  turnOn2FA: boolean;
  turnOff2FA: boolean;
  changePassword: boolean;
}

type ModalsType =
  | "buy"
  | "sell"
  | "finish"
  | "turnOn2FA"
  | "turnOff2FA"
  | "changePassword";

interface IFormData {
  account: string;
  buyAmount: string;
  sellAmount: string;
  old_password: string;
  new_password_replay: string;
  new_password: string;
  code: string;
}

const ProfilePage = () => {
  const { t } = useTranslation();

  const dispatch = useDispatch();

  const navigate = useNavigate();

  const ETHWallet = useSelector(ETHWalletSelector);
  const { phone, email, login, transactionFile, GTECHInfo } =
    useSelector(profileUserSelector);
  const { current_course = null } = GTECHInfo || {};
  const transactions = useSelector(transactionsSelector);
  const twoFAAuth = useSelector(twoFAAuthSelector);

  const { control, getValues, setValue, setError, clearErrors, watch } =
    useForm<IFormData>({
      shouldFocusError: false,
      defaultValues: {
        account: ETHWallet || "",
        sellAmount: "",
        buyAmount: "",
      },
    });

  const accountWatch = watch("account");
  const buyWatch = watch("buyAmount");
  const sellWatch = watch("sellAmount");

  const [modals, setModals] = useState<IModalsState>({
    buy: false,
    finish: false,
    sell: false,
    turnOff2FA: false,
    turnOn2FA: false,
    changePassword: false,
  });
  const [isPhoneAuth, setIsPhoneAuth] = useState<boolean>(!!twoFAAuth);
  const [isEditedWalletInput, setIsEditedWalletInput] = useState(false);

  const changeModal = (modalType: ModalsType, value: boolean) => {
    setModals((prev) => ({ ...prev, [modalType]: value }));
  };

  const callBuyModalHandler = useCallback(
    () => changeModal("buy", true),
    [changeModal]
  );

  const callSellModalHandler = useCallback(
    () => changeModal("sell", true),
    [changeModal]
  );

  const callChangePasswordModalHandler = useCallback(
    () => changeModal("changePassword", true),
    [changeModal]
  );

  const changeAccountHandler = useCallback(async () => {
    const { account } = getValues();
    clearErrors();
    try {
      await schemaProfileETHWalletYup.validate({ wallet: account });
      dispatch(
        changeWalletAC({
          new_wallet: account,
          showSuccessModal: () => {
            changeModal("finish", true);
            setIsEditedWalletInput(false);
          },
        })
      );
    } catch (e: any) {
      setError("account", { type: "manual", message: e.errors[0] as string });
    }
  }, []);

  const sagaLogoutSuccess = () => navigate(paths.login);

  const logoutHandler = useCallback(
    () => dispatch(logoutAC({ setLogoutSuccess: sagaLogoutSuccess })),
    [logoutAC, dispatch, sagaLogoutSuccess]
  );

  const sagaExchangeGTECHSuccess = (type: "BUY" | "SELL") => {
    const inputName = type === "BUY" ? "buyAmount" : "sellAmount";

    setValue(inputName, "");
    changeModal(type === "BUY" ? "buy" : "sell", false);
    changeModal("finish", true);
  };

  const sagaChangePasswordSuccess = (type: "changePassword") => {
    setValue("old_password", "");
    setValue("new_password_replay", "");
    setValue("new_password", "");

    changeModal(type, false);
  };

  const exchangeGTECHHandler = (type: "BUY" | "SELL") => {
    const inputName = type === "BUY" ? "buyAmount" : "sellAmount";
    const { buyAmount, sellAmount } = getValues();

    const currentAmount = type === "BUY" ? buyAmount : sellAmount;

    if (!currentAmount)
      return setError(inputName, {
        type: "manual",
        message: t("errors.requireGeneral"),
      });

    clearErrors();

    dispatch(
      exchangeGTECHAC({
        amount: +currentAmount,
        exchange_type: type,
        setExchangeGTECHSuccess: () => sagaExchangeGTECHSuccess(type),
      })
    );
  };

  const changePasswordModalHandler = (type: "changePassword") => {
    const { old_password, new_password_replay, new_password } = getValues();

    clearErrors();

    if (new_password != new_password_replay) {
      setError("new_password_replay", {
        type: "manual",
        message: t("errors.newPasswordInvalid"),
      });

      return;
    }

    dispatch(
      changePasswordAC({
        old_password,
        new_password_replay,
        new_password,
        setChangePasswordSuccess: () => sagaChangePasswordSuccess(type),
      })
    );
  };

  const change2FA = () => {
    const modal = isPhoneAuth ? "turnOff2FA" : "turnOn2FA";

    changeModal(modal, true);

    //todo после нажатия на кнопку откл/вкл 2fa нужно ввести код для бэка, но для отключения нельзя

    if (modal === "turnOn2FA") {
      dispatch(
        getCodeAC({
          phone: phone as string,
        })
      );
    }
  };

  const sagaOff2FASuccess = () => {
    setIsPhoneAuth(false);
    changeModal("turnOff2FA", false);
  };

  const turnOff2FAHandler = () => {
    dispatch(
      change2FAAC({
        is_2fa_activate: false,
        setChange2FASuccess: sagaOff2FASuccess,
      })
    );
  };

  const sagaOn2FASuccess = () => {
    setIsPhoneAuth(true);
    changeModal("turnOn2FA", false);
    setValue("code", "");
  };

  const sagaOn2FAError = () => {
    setError("code", {
      type: "manual",
      message: t("errors.codeInvalid"),
    });
  };

  const checkCodeHandler = () => {
    const { code } = getValues();
    if (!code)
      return setError("code", {
        type: "manual",
        message: t("errors.requireGeneral"),
      });
    dispatch(
      change2FAAC({
        is_2fa_activate: true,
        code: +code,
        setChange2FASuccess: sagaOn2FASuccess,
        setChange2FAError: sagaOn2FAError,
      })
    );
  };

  const setEditWallet = useCallback(
    () => setIsEditedWalletInput(true),
    [isEditedWalletInput]
  );

  useEffect(() => {
    setValue("buyAmount", getOnlyNumbersAndDots(buyWatch));
  }, [buyWatch, sellWatch]);

  useEffect(() => {
    setValue("sellAmount", getOnlyNumbersAndDots(sellWatch));
  }, [sellWatch]);

  useEffect(() => void setIsPhoneAuth(!!twoFAAuth), [twoFAAuth]);

  useEffect(
    () => void (ETHWallet && setValue("account", ETHWallet)),
    [ETHWallet]
  );

  return (
    <div className={style.profile}>
      <div className={style.info}>
        <Container>
          <Window className={style.info__content}>
            <div className={style.info__left}>
              <EmptyPersonSVG className={style.info__avatar} />
              <div
                className={clsx(style.info__person, {
                  ["empty_relative"]: !(login && email && phone),
                })}
              >
                {login && email && phone ? (
                  <>
                    <Typography
                      type="montserrat-44"
                      className={style.info__nick}
                    >
                      {login}
                    </Typography>
                    <Typography type="sf-text-17" className={style.info__email}>
                      {email}
                    </Typography>
                    <Typography type="sf-text-17" className={style.info__phone}>
                      {phone}
                    </Typography>
                    <div className={style.info__verify}>
                      <Typography
                        type="sf-text-17"
                        className={style["info__verify-text"]}
                      >
                        􀇻 {t(`${sectionInfo}.verifySuccess`)}
                      </Typography>
                    </div>
                  </>
                ) : (
                  <Spinner />
                )}
              </div>
            </div>
            <div className={style.info__right}>
              <Typography
                type="sf-text-17"
                component="button"
                onClick={callChangePasswordModalHandler}
                className={style.info__changePswrd}
              >
                {t(`${sectionInfo}.changePswrd`)}
              </Typography>
              <div className={style.info__buttons}>
                <Button
                  className={clsx(style.info__button, style.info__button_buy)}
                  onClick={callBuyModalHandler}
                >
                  <Typography
                    type="roboto-15"
                    component="span"
                    className={style["info__buy-text"]}
                  >
                    {t(`${sectionInfo}.buyBtn`)}
                  </Typography>
                </Button>
                <Button
                  className={clsx(style.info__button, style.info__button_sell)}
                  onClick={callSellModalHandler}
                >
                  <Typography type="roboto-15" component="span">
                    {t(`${sectionInfo}.sellBtn`)}
                  </Typography>
                </Button>
              </div>
            </div>
          </Window>
        </Container>
      </div>

      <div className={style.account}>
        <Container>
          <Window className={style.account__content}>
            <Typography
              type="montserrat-28"
              component="h2"
              className={style.account__title}
            >
              {t(`${sectionAccount}.title`)}
            </Typography>
            <div
              className={clsx(style.account__bottom, {
                ["empty_relative"]: !ETHWallet,
              })}
            >
              {
                ETHWallet || true ? (
                  <Input
                    placeholder={
                      ETHWallet
                        ? t(`${sectionAccount}.inputPlaceholder`)
                        : t(`${sectionAccount}.emptyInputPlaceholder`)
                    }
                    name="account"
                    control={control as unknown as Control<FieldValues, object>}
                    labelClassName={style.account__input}
                    className={style.account__block}
                    icon={<InputAccountSVG />}
                    subscription={t(`${sectionAccount}.text`)}
                    mask={createArrayMask("", inputMaxLength.wallet)}
                    disabled={!isEditedWalletInput}
                  />
                ) : null /*(
                <Spinner />
              )*/
              }

              {isEditedWalletInput || (
                <Button
                  className={style["account__edit-btn"]}
                  onClick={setEditWallet}
                >
                  <InputEditSVG />
                </Button>
              )}

              {ETHWallet !== accountWatch &&
                (ETHWallet || accountWatch) &&
                isEditedWalletInput && (
                  <Button
                    className={style.account__btn}
                    onClick={changeAccountHandler}
                  >
                    <Typography type="sf-text-17">
                      {ETHWallet
                        ? t(`${sectionAccount}.btn`)
                        : t(`${sectionAccount}.emptyBtn`)}
                    </Typography>
                  </Button>
                )}
            </div>
          </Window>
        </Container>
      </div>

      <div
        className={clsx(style.history, {
          ["empty_relative"]: twoFAAuth === null,
        })}
      >
        <Container>
          <div className={style.history__top}>
            <Title icon={<TitleTimeSVG />}>
              {t(`${sectionHistory}.title`)}
            </Title>
            {transactionFile && (
              <Button
                className={clsx(
                  style.history__export,
                  style.history__export_top
                )}
              >
                <a href={transactionFile} download>
                  <Typography type="sf-text-17">
                    􀈂 {t(`${sectionHistory}.exportBtn`)}
                  </Typography>
                </a>
              </Button>
            )}
          </div>
          {!!transactions.pages_count ? (
            <div className={style.history__table}>
              <div className={style.history__head}>
                <Typography
                  type="sf-text-12"
                  component="div"
                  className={clsx(
                    style["history__head-cell"],
                    style.history__numb
                  )}
                >
                  {t(`${sectionHistory}.table.wallet`)}
                </Typography>
                <Typography
                  type="sf-text-12"
                  component="div"
                  className={clsx(
                    style["history__head-cell"],
                    style.history__time
                  )}
                >
                  {t(`${sectionHistory}.table.date`)}
                </Typography>
                <Typography
                  type="sf-text-12"
                  component="div"
                  className={clsx(
                    style["history__head-cell"],
                    style.history__sum
                  )}
                >
                  {t(`${sectionHistory}.table.sum`)}
                </Typography>
              </div>
              <div className={style.history__list}>
                {transactions?.results.map(
                  ({ amount, is_earning, time_tx, tx, currency }, index) => (
                    <div className={style.history__row} key={index}>
                      <Typography
                        type="sf-text-15"
                        component="div"
                        className={clsx(
                          style.history__cell,
                          style.history__numb
                        )}
                      >
                        {tx}
                      </Typography>
                      <Typography
                        type="sf-text-15"
                        component="div"
                        className={clsx(
                          style.history__cell,
                          style.history__cell_time,
                          style.history__time
                        )}
                      >
                        {time_tx}
                      </Typography>
                      <Typography
                        type="sf-text-15"
                        component="div"
                        className={clsx(
                          style.history__cell,
                          style.history__cell_sum,
                          style.history__sum,
                          {
                            [style.history__cell_minus]: !is_earning,
                          }
                        )}
                      >
                        {is_earning ? "+" : "-"}
                        {amount} {currency}
                      </Typography>
                    </div>
                  )
                )}
              </div>
              {transactionFile && (
                <Button
                  className={clsx(
                    style.history__export,
                    style.history__export_bottom
                  )}
                >
                  <a href={transactionFile} download>
                    <Typography type="sf-text-17">
                      􀈂 {t(`${sectionHistory}.exportBtn`)}
                    </Typography>
                  </a>
                </Button>
              )}
            </div>
          ) : twoFAAuth !== null ? (
            <EmptyContent
              icon={<TimeBigSVG className={style.history__icon} />}
              title={t(`${sectionHistory}.empty.title`)}
              subtitle={t(`${sectionHistory}.empty.subtitle`)}
            />
          ) : (
            <Spinner />
          )}
        </Container>
      </div>

      <div className={style.phone}>
        <Container>
          <div className={style.phone__content}>
            <div className={style.phone__left}>
              <BlockWithIcon className={style.phone__block}>
                <TitleShieldSVG className={style.phone__icon} />
              </BlockWithIcon>
              <div className={style.phone__text}>
                <Typography type="montserrat-28" className={style.phone__title}>
                  {t(`${sectionPhone}.title`)}
                </Typography>
                <Typography type="sf-text-15" className={style.phone__subtitle}>
                  {t(`${sectionPhone}.subtitle`)}
                </Typography>
              </div>
            </div>
            <div
              className={clsx(style.phone__right, {
                ["empty_relative"]: twoFAAuth === null,
              })}
            >
              {twoFAAuth !== null ? (
                <>
                  <Typography
                    type="montserrat-17"
                    className={clsx(style.phone__status, {
                      [style.phone__status_active]: isPhoneAuth,
                    })}
                  >
                    {isPhoneAuth
                      ? t(`${sectionPhone}.onBtn`)
                      : t(`${sectionPhone}.offBtn`)}
                  </Typography>

                  <Switch
                    onChange={change2FA}
                    checked={isPhoneAuth}
                    className={style.phone__switch}
                    width={74}
                    height={40}
                    handleDiameter={32}
                    uncheckedIcon={false}
                    checkedIcon={false}
                    onColor="#33CC66"
                    offColor="#303149"
                  />
                </>
              ) : (
                <Spinner />
              )}
            </div>
          </div>
        </Container>
      </div>

      <ModalShop
        isActive={modals.buy}
        setIsActive={(value: boolean) => changeModal("buy", value)}
        goal="buy"
        onClickHandler={() => exchangeGTECHHandler("BUY")}
      >
        <Input
          name="buyAmount"
          control={control as unknown as Control<FieldValues, object>}
          subscription={`1 GTECH = ${current_course} USD`}
          placeholder={t("modals.buy.placeholder")}
          icon={<InputGTECHSVG />}
          mask={createArrayMask("", inputMaxLength.GTECHAmount)}
        />
      </ModalShop>

      <ModalShop
        isActive={modals.changePassword}
        setIsActive={(value: boolean) => changeModal("changePassword", value)}
        goal="changePassword"
        onClickHandler={() => changePasswordModalHandler("changePassword")}
      >
        <Input
          name="old_password"
          control={control as unknown as Control<FieldValues, object>}
          hideGtechLogoInInput={true}
          placeholder={t("modals.changePassword.placeholder_old_password")}
          icon={<InputGTECHSVG />}
          mask={createArrayMask("password", inputMaxLength.password)}
        />

        <Input
          name="new_password"
          control={control as unknown as Control<FieldValues, object>}
          hideGtechLogoInInput={true}
          placeholder={t("modals.changePassword.placeholder_new_password")}
          icon={<InputGTECHSVG />}
          mask={createArrayMask("password", inputMaxLength.password)}
        />

        <Input
          name="new_password_replay"
          control={control as unknown as Control<FieldValues, object>}
          hideGtechLogoInInput={true}
          placeholder={t(
            "modals.changePassword.placeholder_new_password_replay"
          )}
          icon={<InputGTECHSVG />}
          mask={createArrayMask("password", inputMaxLength.password)}
        />
      </ModalShop>

      <ModalShop
        isActive={modals.sell}
        setIsActive={(value: boolean) => changeModal("sell", value)}
        goal="sell"
        onClickHandler={() => exchangeGTECHHandler("SELL")}
      >
        <Input
          name="sellAmount"
          control={control as unknown as Control<FieldValues, object>}
          subscription={`1 GTECH = ${current_course} USD`}
          placeholder={t("modals.sell.placeholder")}
          icon={<InputGTECHSVG />}
          mask={createArrayMask("", inputMaxLength.GTECHAmount)}
        />
      </ModalShop>

      <ModalReady
        isActive={modals.finish}
        setIsActive={(value: boolean) => changeModal("finish", value)}
        email={email}
      />

      <Modal
        isActive={modals.turnOff2FA}
        setIsActive={() => changeModal("turnOff2FA", false)}
        title={t(`${sectionModal}.turnOff2FA.title`)}
        type="medium"
        className={style.off}
        classNameTitle={style.off__title}
        withClose
        withDarkBg
      >
        <Typography type="sf-text-15" className={style.off__text}>
          {t(`${sectionModal}.turnOff2FA.text`)}
        </Typography>
        <Button className={style.off__btn} onClick={turnOff2FAHandler}>
          <Typography type="sf-text-17">
            {t(`${sectionModal}.turnOff2FA.btn`)}
          </Typography>
        </Button>
      </Modal>

      <Modal
        isActive={modals.turnOn2FA}
        setIsActive={() => changeModal("turnOn2FA", false)}
        title={t(`${sectionModal}.turnOn2FA.title`)}
        type="medium"
        className={style.on}
        classNameTitle={style.on__title}
        withClose
        withDarkBg
        isCenterByVertical
      >
        <Typography type="sf-text-15" className={style.on__text}>
          {t(`${sectionModal}.turnOn2FA.text`)}
        </Typography>
        <Input
          placeholder={t(`${sectionModal}.turnOn2FA.placeholder`)}
          name="code"
          control={control as unknown as Control<FieldValues, object>}
          mask="999999"
          icon={<InputPasswordSVG />}
          className={style.on__input}
        />
        <Button className={style.off__btn} onClick={checkCodeHandler}>
          <Typography type="sf-text-17">
            {t(`${sectionModal}.turnOn2FA.btn`)}
          </Typography>
        </Button>
      </Modal>
    </div>
  );
};

export default memo(ProfilePage);
