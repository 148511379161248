type InputMaskType = "login" | "password" | "";

export const createArrayMask = (
  inputType: InputMaskType,
  maxLength: number = 15
) => {
  let regExp;

  switch (inputType) {
    case "login":
      regExp = loginMask;
      break;
    case "password":
      regExp = passwordMask;
      break;
    default:
      regExp = /.+/;
  }

  return new Array(maxLength).fill(regExp);
};

export const loginMask = /[a-zA-Z0-9\-_]/;
export const passwordMask = /[^а-яА-ЯёЁ\'\"]/;
