import React, { FC } from "react";
import { ISvgProps } from "../types";

const TitleTimeSVG: FC<ISvgProps> = ({ className }) => {
  return (
    <svg
      width="36"
      height="36"
      viewBox="0 0 36 36"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className || ""}
    >
      <path
        opacity="0.4"
        d="M18 35C20.3222 35 22.5056 34.5556 24.55 33.6667C26.6056 32.7778 28.4167 31.55 29.9833 29.9833C31.55 28.4167 32.7778 26.6111 33.6667 24.5667C34.5556 22.5111 35 20.3222 35 18C35 15.6778 34.5556 13.4944 33.6667 11.45C32.7778 9.39444 31.55 7.58333 29.9833 6.01667C28.4167 4.45 26.6056 3.22222 24.55 2.33333C22.4944 1.44444 20.3056 1 17.9833 1C15.6611 1 13.4722 1.44444 11.4167 2.33333C9.37222 3.22222 7.56667 4.45 6 6.01667C4.44444 7.58333 3.22222 9.39444 2.33333 11.45C1.44444 13.4944 1 15.6778 1 18C1 20.3222 1.44444 22.5111 2.33333 24.5667C3.22222 26.6111 4.45 28.4167 6.01667 29.9833C7.58333 31.55 9.38889 32.7778 11.4333 33.6667C13.4889 34.5556 15.6778 35 18 35ZM18 32.1667C16.0333 32.1667 14.1944 31.8 12.4833 31.0667C10.7722 30.3333 9.26667 29.3222 7.96667 28.0333C6.67778 26.7333 5.66667 25.2278 4.93333 23.5167C4.21111 21.8056 3.85 19.9667 3.85 18C3.85 16.0333 4.21111 14.1944 4.93333 12.4833C5.66667 10.7722 6.67778 9.26667 7.96667 7.96667C9.25556 6.66667 10.7556 5.65556 12.4667 4.93333C14.1778 4.2 16.0167 3.83333 17.9833 3.83333C19.95 3.83333 21.7889 4.2 23.5 4.93333C25.2111 5.65556 26.7167 6.66667 28.0167 7.96667C29.3167 9.26667 30.3333 10.7722 31.0667 12.4833C31.8 14.1944 32.1667 16.0333 32.1667 18C32.1667 19.9667 31.8 21.8056 31.0667 23.5167C30.3444 25.2278 29.3333 26.7333 28.0333 28.0333C26.7444 29.3222 25.2389 30.3333 23.5167 31.0667C21.8056 31.8 19.9667 32.1667 18 32.1667Z"
        fill="#33CC66"
      />
      <path
        d="M17.9834 19.8H9.28337C8.95003 19.8 8.67225 19.6889 8.45003 19.4667C8.22781 19.2444 8.1167 18.9667 8.1167 18.6333C8.1167 18.3111 8.22781 18.0389 8.45003 17.8167C8.67225 17.5944 8.95003 17.4833 9.28337 17.4833H16.8334V7.4C16.8334 7.07778 16.9445 6.80556 17.1667 6.58333C17.3889 6.36111 17.6611 6.25 17.9834 6.25C18.3167 6.25 18.5945 6.36111 18.8167 6.58333C19.0389 6.80556 19.15 7.07778 19.15 7.4V18.6333C19.15 18.9667 19.0389 19.2444 18.8167 19.4667C18.5945 19.6889 18.3167 19.8 17.9834 19.8Z"
        fill="#33CC66"
      />
    </svg>
  );
};

export default TitleTimeSVG;
