import React, { FC } from "react";
import { ISvgProps } from "../types";

const InputEyeSVG: FC<ISvgProps> = ({ className }) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className || ""}
    >
      <path
        d="M12 19.0708C18.6489 19.0708 23.2476 13.6919 23.2476 12.0151C23.2476 10.3301 18.6406 4.95947 12 4.95947C5.43408 4.95947 0.744141 10.3301 0.744141 12.0151C0.744141 13.6919 5.43408 19.0708 12 19.0708ZM12 16.6387C9.41016 16.6387 7.35986 14.5386 7.35156 12.0151C7.34326 9.42529 9.41016 7.3916 12 7.3916C14.5649 7.3916 16.6401 9.42529 16.6401 12.0151C16.6401 14.5386 14.5649 16.6387 12 16.6387ZM12 13.6836C12.9214 13.6836 13.6851 12.9282 13.6851 12.0151C13.6851 11.0938 12.9214 10.3384 12 10.3384C11.0703 10.3384 10.3066 11.0938 10.3066 12.0151C10.3066 12.9282 11.0703 13.6836 12 13.6836Z"
        fill="#EBEBF5"
        fillOpacity="0.18"
      />
    </svg>
  );
};

export default InputEyeSVG;
