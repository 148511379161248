import { AxiosResponse } from "axios";
import { format, subMonths, subWeeks, subYears } from "date-fns";
import { call, put } from "redux-saga/effects";

import accountAPI from "@api/AccountAPI";
import AccountAPI from "@api/AccountAPI";
import {
  IChangeWalletResponse,
  IExchangeGTECHResponse,
  IGet2FAStatus,
  IGetTransactionsResponse,
  IGetWalletResponse,
} from "@api/AccountAPI/types";
import AuthAPI from "@api/AuthAPI";
import { IGetUserInfoResponse } from "@api/AuthAPI/types";
import ConstantsAPI from "@api/ConstantsAPI";
import { IGetConstantsResponse } from "@api/ConstantsAPI/types";
import MiningAPI from "@api/MiningAPI";
import {
  GetEarningsResponseType,
  IGetCoefficientGTECH,
  IGetDataCenterPowerResponse,
  IGetEthBalanceResponse,
  IGetForecastResponse,
  IGetGTECHBalanceResponse,
  IGetGTECHRateResponse,
  IGetPullProfitResponse,
} from "@api/MiningAPI/types";
import NewsAPI from "@api/NewsAPI";
import { IGetNewsResponse } from "@api/NewsAPI/types";

import {
  clearIsLoading,
  setError,
  setIsAuth,
  setIsLoading,
} from "@slices/AppSlice";
import {
  setCoefficient,
  setDataCenterInfo,
  setForecast,
  setGTECHPerSecond,
  setProfit,
} from "@slices/CoinSlice";
import { setConstants, setNews } from "@slices/OtherSlice";
import {
  set2FA,
  setETHInfo,
  setETHWallet,
  setGTECHInfo,
  setPayouts,
  setTransactionFile,
  setTransactions,
  setUserInfo,
} from "@slices/UserSlice";

import {
  ISagaChangeWalletAction,
  ISagaGetInitDataAction,
  ISagaGetPayoutsAction,
  ISagaGetProfitPullAction,
} from "./types";
import { ISagaExchangeGTECHAction } from "./types";
import { everyMinute, everyMinuteAC } from "@saga/everyMinute";

function* sagaChangeWallet({
  payload,
}: {
  payload: ISagaChangeWalletAction;
  type: string;
}) {
  const { setExchangeGTECHSuccess, showSuccessModal, ...data } = payload;
  try {
    yield put(setIsLoading());
    const response: AxiosResponse<IChangeWalletResponse> = yield call(
      accountAPI.changeWallet,
      data
    );
    setExchangeGTECHSuccess?.();
    showSuccessModal?.();
    // yield put(setETHWallet(response.data.new_wallet));
  } catch (e) {
    yield put(setError("Error"));
  } finally {
    yield put(clearIsLoading());
  }
}

function* sagaBuyGTECH({
  payload,
}: {
  payload: ISagaExchangeGTECHAction;
  type: string;
}) {
  const { setExchangeGTECHSuccess, ...data } = payload;
  try {
    yield put(setIsLoading());
    const response: AxiosResponse<IExchangeGTECHResponse> = yield call(
      accountAPI.exchangeGTECH,
      data
    );
    setExchangeGTECHSuccess?.();
  } catch (e) {
    yield put(setError("Error"));
  } finally {
    yield put(clearIsLoading());
  }
}

function* sagaGetInitData({
  payload,
}: {
  payload: ISagaGetInitDataAction;
  type: string;
}) {
  const { setGetInitDataSuccess } = payload;
  try {
    const userInfo: AxiosResponse<IGetUserInfoResponse> = yield call(
      AuthAPI.getUserInfo
    );
    yield put(setUserInfo(userInfo.data));

    const wallet: AxiosResponse<IGetWalletResponse> = yield call(
      accountAPI.getWallet
    );
    yield put(setETHWallet(wallet.data.wallet));

    const constants: AxiosResponse<IGetConstantsResponse> = yield call(
      ConstantsAPI.getConstants
    );
    yield put(setConstants(constants.data));

    const GTECHBalance: AxiosResponse<IGetGTECHBalanceResponse> = yield call(
      MiningAPI.getGTECHBalance
    );
    yield put(setGTECHInfo(GTECHBalance.data));

    const ETHBalance: AxiosResponse<IGetEthBalanceResponse> = yield call(
      MiningAPI.getEthBalance
    );
    yield put(setETHInfo(ETHBalance.data));

    const earnings: AxiosResponse<GetEarningsResponseType> = yield call(
      MiningAPI.getEarnings,
      {
        page: 1,
        time_add_before: format(subMonths(new Date(), -1), "yyyy-MM-dd"),
        time_add_after: format(subMonths(new Date(), 6), "yyyy-MM-dd"),
      }
    );
    yield put(setPayouts(earnings.data));

    const pullProfit: AxiosResponse<IGetPullProfitResponse[]> = yield call(
      MiningAPI.getPoolProfit,
      {
        time_add_before: format(subWeeks(new Date(), -1), "yyyy-MM-dd"),
        time_add_after: format(subWeeks(new Date(), 1), "yyyy-MM-dd"),
      }
    );
    yield put(setProfit(pullProfit.data));

    const GTECHRate: AxiosResponse<IGetGTECHRateResponse> = yield call(
      MiningAPI.getGTECHRate,
    );
    yield put(setGTECHPerSecond(GTECHRate.data));

    const dataCenterPower: AxiosResponse<IGetDataCenterPowerResponse> = yield call(
      MiningAPI.getDataCenterPower,
    );
    yield put(setDataCenterInfo(dataCenterPower.data));

    const forecast: AxiosResponse<IGetForecastResponse> = yield call(
      MiningAPI.getForecast,
    );
    yield put(setForecast(forecast.data.forecast));

    const coefficientGTECH: AxiosResponse<IGetCoefficientGTECH> = yield call(
      MiningAPI.getCoefficientGTECH,
    );
    yield put(setCoefficient(coefficientGTECH.data.coefficient));

    if (wallet.data.wallet) {
      const transactions: AxiosResponse<IGetTransactionsResponse> = yield call(
        AccountAPI.getTransactions,
        { page: 1 }
      );
      yield put(setTransactions(transactions.data));
      const transactionFile: AxiosResponse<any> = yield call(
        AccountAPI.getTransitionFile
      );
      const file =
        "data:text/csv;charset=utf-8,%EF%BB%BF" +
        encodeURI(transactionFile.data);
      yield put(setTransactionFile(file));
    }

    const twoFAStatus: AxiosResponse<IGet2FAStatus> = yield call(
      AccountAPI.get2FAStatus
    );
    yield put(set2FA(twoFAStatus.data.is_2fa_activate));

    const news: AxiosResponse<IGetNewsResponse[]> = yield call(NewsAPI.getNews);
    yield put(setNews(news.data));

    yield put(setIsAuth(true));

    setGetInitDataSuccess?.();
    yield put(everyMinuteAC());
  } catch (e) {
    yield put(setError("Error"));
  }
}

function* sagaGetProfitPull({
  payload,
}: {
  payload: ISagaGetProfitPullAction;
  type: string;
}) {
  try {
    yield put(setIsLoading());
    const { start } = payload;

    const time_add_after =
      start === "week"
        ? format(subWeeks(new Date(), 1), "yyyy-MM-dd")
        : start === "months"
        ? format(subMonths(new Date(), 1), "yyyy-MM-dd")
        : format(subYears(new Date(), 10), "yyyy-MM-dd");

    const response: AxiosResponse<IGetPullProfitResponse[]> = yield call(
      MiningAPI.getPoolProfit,
      {
        time_add_before: format(subWeeks(new Date(), -1), "yyyy-MM-dd"),
        time_add_after,
      }
    );
    yield put(setProfit(response.data));
  } catch (e) {
    yield put(setError("Error"));
  } finally {
    yield put(clearIsLoading());
  }
}

function* sagaGetPayouts({
  payload,
}: {
  payload: ISagaGetPayoutsAction;
  type: string;
}) {
  try {
    yield put(setIsLoading());
    const { page, start } = payload;
    const time_add_after =
      start === "6months"
        ? format(subMonths(new Date(), 6), "yyyy-MM-dd")
        : start === "year"
        ? format(subYears(new Date(), 1), "yyyy-MM-dd")
        : format(subYears(new Date(), 10), "yyyy-MM-dd");

    const response: AxiosResponse<GetEarningsResponseType> = yield call(
      MiningAPI.getEarnings,
      {
        page,
        time_add_before: format(subMonths(new Date(), -1), "yyyy-MM-dd"),
        time_add_after,
      }
    );
    yield put(setPayouts(response.data));
  } catch (e) {
    yield put(setError("Error"));
  } finally {
    yield put(clearIsLoading());
  }
}

export {
  sagaChangeWallet,
  sagaBuyGTECH,
  sagaGetInitData,
  sagaGetProfitPull,
  sagaGetPayouts,
};
