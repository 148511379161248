import React, { FC } from "react";
import { ISvgProps } from "./types";

const EmptyPersonSVG: FC<ISvgProps> = ({ className }) => {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M16 32C24.7529 32 32 24.7373 32 16C32 7.24706 24.7373 0 15.9843 0C7.24706 0 0 7.24706 0 16C0 24.7373 7.26275 32 16 32ZM16 21.349C11.2941 21.349 7.6549 23.0275 6.07059 24.9098C3.95294 22.5569 2.68235 19.4353 2.68235 16C2.68235 8.59608 8.58039 2.66667 15.9843 2.66667C23.3882 2.66667 29.3176 8.59608 29.3333 16C29.349 19.4353 28.0627 22.5569 25.9451 24.9255C24.3608 23.0431 20.7216 21.349 16 21.349ZM16 18.6824C19.0118 18.7137 21.3804 16.1412 21.3804 12.7686C21.3804 9.6 19.0118 6.96471 16 6.96471C12.9882 6.96471 10.6039 9.6 10.6196 12.7686C10.6353 16.1412 12.9882 18.651 16 18.6824Z"
        fill="white"
        fillOpacity="0.6"
      />
    </svg>
  );
};

export default EmptyPersonSVG;
