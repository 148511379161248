import React, { FC } from "react";
import { ISvgProps } from "./types";

const PowerSVG: FC<ISvgProps> = ({ className }) => {
  return (
    <svg
      width="12"
      height="17"
      viewBox="0 0 12 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M1.43164 10.3452H5.56982L6.89551 7.54004H6.54395L8.68262 1.81982C8.78027 1.56104 8.77539 1.35352 8.66797 1.19727C8.56055 1.03613 8.40674 0.955566 8.20654 0.955566C8.01123 0.950684 7.82812 1.05322 7.65723 1.26318L1.0874 9.35645C0.955566 9.51758 0.889648 9.67627 0.889648 9.83252C0.889648 9.979 0.940918 10.1011 1.04346 10.1987C1.146 10.2964 1.27539 10.3452 1.43164 10.3452ZM4.39795 16.6221L10.9751 8.53613C11.1021 8.375 11.1655 8.21387 11.1655 8.05273C11.1655 7.90625 11.1143 7.78418 11.0117 7.68652C10.9092 7.58887 10.7798 7.54004 10.6235 7.54004H6.48535L5.15967 10.3452H5.51123L3.37256 16.0654C3.2749 16.3242 3.27979 16.5317 3.38721 16.688C3.49463 16.8491 3.646 16.9297 3.84131 16.9297C4.0415 16.9346 4.22705 16.832 4.39795 16.6221Z"
        fill="#33CC66"
      />
    </svg>
  );
};

export default PowerSVG;
