import { applyMiddleware, combineReducers, createStore } from "redux";
import { persistReducer, persistStore } from "redux-persist";
import storage from "redux-persist/lib/storage";
import createSagaMiddleware from "redux-saga";

import appReducer from "@slices/AppSlice";
import coinReducer from "@slices/CoinSlice";
import otherReducer from "@slices/OtherSlice";
import userReducer from "@slices/UserSlice";

import watcher from "./saga/watcher";

const persistConfig = {
  key: "root",
  storage,
  whitelist: [],
};

const appPersistConfig = {
  key: "app",
  storage,
  whitelist: ["accessToken"],
};

const rootReducer = combineReducers({
  app: persistReducer(appPersistConfig, appReducer),
  user: userReducer,
  coin: coinReducer,
  other: otherReducer,
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

type RootReducerType = typeof rootReducer;
export type AppStateType = ReturnType<RootReducerType>;

const sagaMiddleware = createSagaMiddleware();

const store = createStore(persistedReducer, applyMiddleware(sagaMiddleware));
const persistor = persistStore(store);

sagaMiddleware.run(watcher);

export { store, persistor };
