import React, { FC } from "react";

import { useTranslation } from "react-i18next";

import Modal from "@components/Modal";

import Button from "@ui/Button";
import Typography from "@ui/Typography";

import style from "./ModalShop.module.scss";

interface IProps {
  isActive: boolean;
  setIsActive: (value: boolean) => void;
  goal: "buy" | "sell" | "wallet" | 'changePassword';
  link?: string;
  onClickHandler(): void
}

const ModalShop: FC<IProps> = ({
  isActive,
  setIsActive,
  children,
  goal,
  link,
  onClickHandler,
}) => {
  const { t } = useTranslation();

  const section = goal === "buy" ? "modals.buy" : goal === "sell" ? "modals.sell" : goal === 'wallet' ? "modals.wallet" : 'modals.changePassword';

  return (
    <Modal
      isActive={isActive}
      setIsActive={setIsActive}
      withDarkBg
      isCenterByVertical
      withClose
      title={t(`${section}.title`)}
      type="medium"
    >
      <Typography type="sf-text-15" className={style.modal__text}>
        {t(`${section}.text`)}
      </Typography>
      <div className={style.modal__input}>{children}</div>
      <Button className={style.modal__btn} onClick={onClickHandler}>
        <Typography type="sf-text-17">{t(`${section}.btn`)}</Typography>
      </Button>
      {link && (
        <a href={link} className={style.modal__link}>
          {t(`${section}.or`)}
          <span className={style["modal__link-text"]}>
            {t(`${section}.link`)}
          </span>
        </a>
      )}
    </Modal>
  );
};

export default ModalShop;
