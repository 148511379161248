import React, { useEffect, useState } from "react";

interface IProps {
  initialSeconds: number;
}

interface IReturn {
  seconds: number;
  start(): void;
  stop(): void;
  isStopped: boolean;
  isStarted: boolean;
}

const useTimer = ({ initialSeconds }: IProps): IReturn => {
  const [timerId, setTimerId] = useState<NodeJS.Timeout | null>(null);
  const [seconds, setSeconds] = useState<number>(initialSeconds);
  const [isStopped, setIsStopped] = useState<boolean>(true);
  const [isStarted, setIsStarted] = useState<boolean>(false);

  const start = () => {
    isStopped && setIsStopped(false);
    !isStarted && setIsStarted(true);

    startHandler();
  };

  const startHandler = () => {
    const id = setTimeout(startHandler, 1000);
    setTimerId(id);
    setSeconds((prev) => prev - 1);
  };

  const stop = () => {
    timerId && clearTimeout(timerId);
    setTimerId(null);
    setSeconds(initialSeconds);
    setIsStopped(true);
    setIsStarted(false);
  };

  useEffect(() => {
    !seconds && stop();
  }, [seconds]);

  useEffect(() => stop, []);

  return {
    seconds,
    start,
    stop,
    isStopped,
    isStarted,
  };
};

export default useTimer;
